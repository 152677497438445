import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 16 16"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M14.52 11.524a1.096 1.096 0 0 1-.963 1.64H2.443c-.86 0-1.38-.911-.963-1.64L7.05 2.05c.416-.729 1.484-.729 1.926 0l5.544 9.475ZM7.388 5.043v3.332c0 .364.313.624.625.624.338 0 .625-.26.625-.624V5.043a.633.633 0 0 0-.625-.625.617.617 0 0 0-.625.625Zm.625 6.455a.811.811 0 0 0 .807-.807.811.811 0 0 0-.807-.807.817.817 0 0 0-.833.807c0 .443.364.807.833.807Z"
		/>
	</svg>
);
export default SvgWarning;
