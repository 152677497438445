import { useLocation } from 'react-router-dom';
import { LayoutType } from '@/config';

export const useLayout = (): LayoutType => {
	const { pathname } = useLocation();

	switch (true) {
		case pathname === '/':
			return 'landing';
		case pathname === '/pro':
			return 'landing-pro';
		case pathname.includes('/checkout'):
		case pathname.includes('/cashout'):
		case pathname.includes('/privacy-policy'):
		case pathname.includes('/faq'):
		case pathname.includes('/terms-and-conditions'):
			return 'non-application-context';
		case pathname.includes('/sign-up'):
			return 'sign-up';
		default:
			return 'application';
	}
};
