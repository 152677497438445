import { isNil, Maybe } from '@/shared';

export const filterNilProperties = <T>(obj: Record<string, Maybe<T>>): Record<string, T> => {
	const result: Record<string, T> = {};

	Object.keys(obj).forEach((key) => {
		const value = obj[key];
		if (!isNil(value)) {
			result[key] = value as T;
		}
	});

	return result;
};
