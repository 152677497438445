import {
	Discipline,
	DisciplineOrTrainerAutocompleteItem,
	disciplines,
	getDisciplineMetaData,
	isNil,
	Maybe,
	Trainer,
	useObservable,
	useTrainersRepository
} from '@/shared';
import { combineLatest, map, Observable, of } from 'rxjs';
import { useMemo } from 'react';
import { useTranslation } from '@/config';

export const useDisciplineOrTrainersAutocompleteOptions = (query: Maybe<string>) => {
	const { t } = useTranslation();
	const { getTrainers } = useTrainersRepository();
	const options$: Observable<DisciplineOrTrainerAutocompleteItem[]> = useMemo(() => {
		const trainersSuggestions$ = query
			? getTrainers({ name: query }).pipe(map((pagedTrainers) => pagedTrainers.content))
			: of([]);

		const disciplineSuggestions = isNil(query)
			? disciplines
			: disciplines.filter((d) => {
					const disciplineTranslation = t(getDisciplineMetaData(d).translationKey);
					return disciplineTranslation.toLowerCase().includes(query.toLowerCase());
			  });
		return combineLatest([trainersSuggestions$, of(disciplineSuggestions)]).pipe(
			map(([tr, di]: [Trainer[], Discipline[]]) => {
				return [...di, ...tr];
			})
		);
	}, [getTrainers, query, t]);
	const { value: options, loader } =
		useObservable<DisciplineOrTrainerAutocompleteItem[]>(options$);
	return {
		options,
		loader
	};
};
