import { primary } from '@/ui/theme/components-overrides/colors';
import { Flex, FlexProps } from '@/ui/flex/Flex';
import { FC, forwardRef, PropsWithChildren } from 'react';

export const FlexCard: FC<PropsWithChildren<FlexProps>> = forwardRef(
	({ children, ...flexProps }, ref) => {
		return (
			<Flex
				flexDirection={'column'}
				justifyContent={'center'}
				alignItems={'center'}
				flex={'1 0 0'}
				borderRadius={2}
				border={{ xs: 'none', md: `1px solid ${primary[100]}` }}
				width={{ xs: '100%', md: 'auto' }}
				bgcolor={{ md: primary.contrast }}
				sx={flexProps.sx}
				{...flexProps}
				ref={ref}>
				{children}
			</Flex>
		);
	}
);

FlexCard.displayName = 'FlexCard';
