import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { primary } from '@/ui/theme/components-overrides/colors';

declare module '@mui/material/InputBase' {
	interface InputBasePropsSizeOverrides {
		large: true;
	}
}

type MuiInputBaseFieldOverrides = {
	defaultProps?: ComponentsProps['MuiInputBase'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiInputBase'];
	variants?: ComponentsVariants['MuiInputBase'];
};

export const muiInputBase: MuiInputBaseFieldOverrides = {
	variants: [
		{
			props: { size: 'large' },
			style: {}
		}
	],
	styleOverrides: {
		root: {
			backgroundColor: primary.contrast,
			'& .Mui-disabled': {
				cursor: 'not-allowed'
			}
		}
	}
};
