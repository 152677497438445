import { Flex } from '@/ui/flex/Flex';
import { Container, Divider } from '@mui/material';
import { SubNavbarTabs } from '@/config/layout/navigation/sub-navbar/SubNavbarTabs';

export const SubNavbar = () => {
	return (
		<>
			<Divider />
			<Container maxWidth="lg">
				<Flex
					alignItems={'flex-start'}
					paddingY={2}
					gap={'240px'}
					height={'auto'}
					justifyContent={'flex-start'}>
					<SubNavbarTabs />
				</Flex>
			</Container>
		</>
	);
};
