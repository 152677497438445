import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { Theme } from '@mui/material';

declare module '@mui/material/IconButton' {
	interface IconButtonPropsSizeOverrides {
		tiny: true;
	}
}

type MuiIconButtonOverrides = {
	defaultProps?: ComponentsProps['MuiIconButton'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiIconButton'];
	variants?: ComponentsVariants['MuiIconButton'];
};

export const muiIconButton: MuiIconButtonOverrides = {
	variants: [
		{
			props: { size: 'tiny' },
			style: { width: '24px', height: '24px', padding: '6px', borderRadius: '100px' }
		}
	]
};
