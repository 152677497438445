import { AppState, Auth0Provider, CacheLocation } from '@auth0/auth0-react';
import { PropsWithChildren, useCallback } from 'react';
import { AppType, useAppType } from '@/config/app-type/appTypeContext';

export const Auth0ProviderWithConfig = ({ children }: PropsWithChildren<{}>): JSX.Element => {
	const { changeAppType } = useAppType();

	const onRedirectCallback = useCallback(
		(appState?: AppState) => {
			const appType: AppType = appState?.type || 'regular';
			changeAppType(appType);
		},
		[changeAppType]
	);

	return (
		<Auth0Provider
			domain={import.meta.env.VITE_AUTH0_DOMAIN}
			clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
			redirectUri={window.location.origin}
			onRedirectCallback={onRedirectCallback}
			useRefreshTokens={true}
			cacheLocation={import.meta.env.VITE_AUTH0_CACHE_LOCATION as CacheLocation}
			audience={import.meta.env.VITE_AUTH0_AUDIENCE}>
			{children}
		</Auth0Provider>
	);
};
