import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCalendarStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M8.938 4.5h5.624V2.937c0-.507.391-.937.938-.937a.95.95 0 0 1 .938.938V4.5H18c1.367 0 2.5 1.133 2.5 2.5v12.5c0 1.406-1.133 2.5-2.5 2.5H5.5A2.468 2.468 0 0 1 3 19.5V7c0-1.367 1.094-2.5 2.5-2.5h1.563V2.937C7.063 2.43 7.452 2 8 2a.95.95 0 0 1 .938.938V4.5Zm-4.063 15c0 .352.273.625.625.625H18a.642.642 0 0 0 .625-.625v-10H4.875v10Z"
		/>
	</svg>
);
export default SvgCalendarStroke;
