import { FC, forwardRef, ReactNode, useMemo, useState } from 'react';
import { Button, FormLabel, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Flex } from '@/ui/flex/Flex';
import { primary } from '@/ui/theme/components-overrides/colors';
import Typography from '@mui/material/Typography';
import SvgQuestionStroke from '@/assets/icons/generated/stroke/QuestionStroke';
import { red } from '@mui/material/colors';
import { IconType } from '@/shared';
import { useTranslation } from '@/config';

type InputProps = TextFieldProps & {
	label?: string | ReactNode;
	bottomText?: string;
	leftIcon?: IconType;
	rightIcon?: IconType;
	inlineEdit?: boolean;
};

export const Input: FC<InputProps> = forwardRef(
	({ inlineEdit = false, leftIcon, rightIcon, label, bottomText, ...textFieldProps }, ref) => {
		const { t } = useTranslation();
		const [isFocused, setIsFocused] = useState<boolean>(false);
		const [isEditable, setIsEditable] = useState<boolean>(!textFieldProps.disabled || false);

		const chevronIconColor = useMemo(() => {
			return isFocused || textFieldProps.error ? primary['900'] : primary['400'];
		}, [isFocused, textFieldProps.error]);

		return (
			<Flex flexDirection={'column'} gap={1} width={'100%'}>
				{label && (
					<FormLabel error={textFieldProps.error} required={textFieldProps.required}>
						{label}
					</FormLabel>
				)}
				<Flex gap={2}>
					<TextField
						{...textFieldProps}
						ref={ref}
						disabled={!isEditable}
						onClick={() => setIsFocused(true)}
						variant={'outlined'}
						sx={{ width: '100%' }}
						InputProps={{
							...textFieldProps.InputProps,
							onBlur: () => setIsFocused(false),
							startAdornment: leftIcon ? (
								<Flex paddingRight={1}>
									{leftIcon({
										width: textFieldProps.size === 'small' ? '16px' : '20px',
										height: textFieldProps.size === 'small' ? '16px' : '20px'
									})}
								</Flex>
							) : (
								textFieldProps.InputProps?.startAdornment || undefined
							),
							endAdornment: rightIcon ? (
								<Flex paddingLeft={1}>
									{rightIcon({
										width: textFieldProps.size === 'small' ? '16px' : '20px',
										height: textFieldProps.size === 'small' ? '16px' : '20px',
										color: chevronIconColor
									})}
								</Flex>
							) : (
								textFieldProps.InputProps?.endAdornment || undefined
							)
						}}
					/>
					{inlineEdit && (
						<Button
							onClick={() => setIsEditable((prev) => !prev)}
							variant={'secondary-outline'}
							size={'large'}
							sx={{ height: '40px' }}>
							<Typography variant={'h6'}>{t('shared.edit')}</Typography>
						</Button>
					)}
				</Flex>
				{bottomText && (
					<Flex alignItems={'center'} gap={0.5}>
						<SvgQuestionStroke
							width={'12px'}
							height={'12px'}
							color={textFieldProps.error ? red['600'] : primary['500']}
						/>
						<Typography
							color={textFieldProps.error ? red['600'] : primary['500']}
							variant={'p4'}>
							{bottomText}
						</Typography>
					</Flex>
				)}
			</Flex>
		);
	}
);

Input.displayName = 'Input';
