import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHockeyFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M2.5 8.75C2.5 6.68 6.953 5 12.5 5c5.508 0 10 1.68 10 3.75 0 2.07-4.492 3.75-10 3.75-5.547 0-10-1.68-10-3.75Zm9.96 5.625c-3.866 0-7.773-.781-9.96-2.383v4.258c0 2.07 4.453 3.75 10 3.75 5.508 0 10-1.68 10-3.75v-4.258c-2.227 1.602-6.133 2.383-10.04 2.383Z"
		/>
	</svg>
);
export default SvgHockeyFilled;
