import {
	createContext,
	FC,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import { useNavigate, useRouteConfig } from '@/config';
import { isNil, useTrainer } from '@/shared';

export type AppType = 'regular' | 'pro';
export const defaultAppType: AppType = 'regular';

const appTypeContext = createContext<{
	appType: AppType;
	changeAppType: (appType: AppType) => void;
}>({
	appType: defaultAppType,
	changeAppType: () => {}
});

export const useAppType = () => {
	return useContext(appTypeContext);
};

export const AppTypeProvider: FC<PropsWithChildren<{}>> = (props) => {
	const [appType, setAppType] = useState<AppType>(defaultAppType);
	const navigate = useNavigate();
	const { pathConfig } = useRouteConfig();
	const [isSwitchAvailable, setIsSwitchAvailable] = useState(false);
	const { me: meTrainer } = useTrainer({
		onError: () => setIsSwitchAvailable(false),
		onSuccess: () => setIsSwitchAvailable(true)
	});

	useEffect(() => setAppType(pathConfig?.isPro ? 'pro' : 'regular'), [pathConfig?.isPro]);

	const handleChangeAppType = useCallback(
		(selectedAppType: AppType) => {
			if (!isSwitchAvailable) return;

			if (!isNil(meTrainer)) {
				if (selectedAppType === 'pro') {
					navigate(
						meTrainer.status === 'REQUIRES_ATTENTION'
							? 'me/trainer/settings'
							: 'me/trainer/dashboard'
					);
				} else {
					navigate('');
				}
				setAppType(selectedAppType);
			}
		},
		[isSwitchAvailable, meTrainer, navigate]
	);

	const contextValue = {
		appType: appType,
		changeAppType: handleChangeAppType
	};

	return <appTypeContext.Provider value={contextValue}>{props.children}</appTypeContext.Provider>;
};
