import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSearchStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M21.707 20.398c.39.391.39.977 0 1.329-.157.195-.391.273-.626.273-.274 0-.508-.078-.704-.273l-5.24-5.235a7.998 7.998 0 0 1-5.043 1.758C5.636 18.25 2 14.617 2 10.125 2 5.672 5.597 2 10.094 2c4.457 0 8.133 3.672 8.133 8.125 0 1.914-.626 3.672-1.76 5.04l5.24 5.233ZM3.877 10.125a6.222 6.222 0 0 0 6.256 6.25 6.246 6.246 0 0 0 6.256-6.25c0-3.438-2.815-6.25-6.256-6.25a6.246 6.246 0 0 0-6.256 6.25Z"
		/>
	</svg>
);
export default SvgSearchStroke;
