import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChevronDownFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M12.022 17.405c-.401 0-.758-.134-1.025-.402L2.435 8.441a1.375 1.375 0 0 1 0-2.006 1.375 1.375 0 0 1 2.006 0l7.581 7.536 7.536-7.536a1.375 1.375 0 0 1 2.007 0c.58.535.58 1.471 0 2.006l-8.562 8.562a1.383 1.383 0 0 1-.98.402Z"
		/>
	</svg>
);
export default SvgChevronDownFilled;
