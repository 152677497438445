import { FallbackProps } from '@/config/hoc/with-fallback';
import { useContext } from 'react';
import { clientContext } from '@/config';
import { isNil, MeClient } from '@/shared';
import { useLoaderHandler } from '@/shared/utils/loader/use-loader-handler';

export const useClient = (fallback?: FallbackProps<MeClient>) => {
	const context = useContext(clientContext);

	if (isNil(context)) {
		throw new Error('useClient must be used within ClientProvider');
	}

	const loading = useLoaderHandler(
		[context.loader],
		[context.me],
		() => fallback?.onError(),
		(data) => fallback?.onSuccess(data[0]!)
	);

	return {
		me: context?.me,
		loading
	};
};
