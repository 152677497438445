import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChevronRightStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="m9.935 2.355 8.507 8.878c.186.232.325.511.325.79 0 .28-.14.558-.325.744l-8.507 8.878a1.11 1.11 0 0 1-1.58.047c-.465-.419-.465-1.116-.047-1.58l7.81-8.135-7.81-8.088a1.11 1.11 0 0 1 .047-1.58 1.11 1.11 0 0 1 1.58.046Z"
		/>
	</svg>
);
export default SvgChevronRightStroke;
