import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWorkoutly = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 20 20"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M2.5 5v10.143h2.013l5.548-8.07.18 8.07h2.033L19.167 5h-2.234l-5.014 7.288L11.758 5H9.054l-4.541 6.749V5H2.5Z"
		/>
	</svg>
);
export default SvgWorkoutly;
