import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { Theme } from '@mui/material';
import { primary } from '@/ui/theme/components-overrides/colors';

type MuiTableHeadOverrides = {
	defaultProps?: ComponentsProps['MuiTableHead'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiTableHead'];
	variants?: ComponentsVariants['MuiTableHead'];
};

export const muiTableHead: MuiTableHeadOverrides = {
	styleOverrides: {
		root: {
			backgroundColor: primary['25']
		}
	}
};
