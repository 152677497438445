import { FC, ReactNode } from 'react';
import { Flex } from '@/ui/flex/Flex';
import { primary } from '@/ui/theme/components-overrides/colors';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SvgCross from '@/assets/icons/generated/Cross';
import { isNil, ScreenType } from '@/shared';
import { Button } from '@mui/material';
import { StatusIcon, StatusIconType } from '@/ui/status-icon/StatusIcon';

export type ButtonsConfig = {
	okButton?: {
		label: string;
		onClick: () => void;
	};
	cancelButton?: {
		label: string;
	};
};

type NotificationProps = {
	type: StatusIconType;
	title: string | ReactNode;
	body?: string | ReactNode;
	variant?: ScreenType;
	onCloseClick?: () => void;
	buttonsConfig?: ButtonsConfig;
};

export const Notification: FC<NotificationProps> = ({
	onCloseClick,
	type,
	title,
	body,
	buttonsConfig,
	variant = 'desktop'
}) => {
	return (
		<Flex
			flexDirection={'column'}
			gap={2}
			sx={{ width: variant === 'desktop' ? '384px' : '100%' }}>
			<Grid container spacing={2} alignItems={'start'}>
				<Grid item xs={1}>
					<StatusIcon type={type} />
				</Grid>
				<Grid item xs={10}>
					<Flex flexDirection={'column'} gap={2}>
						<Flex flexDirection={'column'} gap={1}>
							{typeof title === 'string' ? (
								<Typography variant={variant === 'desktop' ? 'h7' : 'h6'}>
									{title}
								</Typography>
							) : (
								title
							)}
							{!isNil(body) && (
								<>
									{typeof body === 'string' ? (
										<Typography
											variant={variant === 'desktop' ? 'p4' : 'p3'}
											color={primary['600']}>
											{body}
										</Typography>
									) : (
										body
									)}
								</>
							)}
						</Flex>
						{buttonsConfig && (
							<Flex gap={1} alignItems={'center'}>
								{buttonsConfig.cancelButton && (
									<Button onClick={onCloseClick} variant={'tertiary'}>
										<Typography variant={'h8'}>
											{buttonsConfig.cancelButton.label}
										</Typography>
									</Button>
								)}
								{buttonsConfig.okButton && (
									<Button onClick={buttonsConfig.okButton.onClick}>
										<Typography variant={'h8'}>
											{buttonsConfig.okButton.label}
										</Typography>
									</Button>
								)}
							</Flex>
						)}
					</Flex>
				</Grid>
				<Grid item xs={1}>
					<Box
						onClick={onCloseClick}
						sx={{
							cursor: 'pointer'
						}}>
						<SvgCross width={'12px'} height={'12px'} color={primary.black} />
					</Box>
				</Grid>
			</Grid>
		</Flex>
	);
};
