import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWorkoutlyLogoWhite = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 312 64"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#fff"
			fillRule="evenodd"
			d="m137.146 33.777 11.95 16.405h9.404l-15.909-21.566 14.778-14.072h-9.97L132.691 27.98V.12h-7.778v50.063h7.778V37.949l4.455-4.172Zm-79.918-1.414c0 10.677 7.637 18.385 18.384 18.385 10.678 0 18.385-7.708 18.385-18.385 0-10.677-7.707-18.384-18.385-18.384-10.747 0-18.384 7.707-18.384 18.384Zm28.99 0c0 6.859-4.312 11.667-10.606 11.667-6.363 0-10.606-4.808-10.606-11.667s4.243-11.667 10.606-11.667c6.294 0 10.607 4.808 10.607 11.667ZM.704 14.544v35.638h7.071l19.492-28.355.637 28.355h7.141L59.26 14.544h-7.85L33.796 40.15l-.566-25.605h-9.498L7.774 38.256V14.544H.704Zm120.429 7.496c-1.273-.566-2.97-.849-4.596-.849-5.233 0-9.829 4.667-9.829 15.698v13.293H99V14.544h7.707v6.788c1.909-4.242 6.576-7.353 10.889-7.353 1.344 0 2.899.212 3.96.636l-.424 7.425Zm55.009 28.708c-10.748 0-18.384-7.708-18.384-18.385 0-10.677 7.636-18.384 18.384-18.384 10.677 0 18.385 7.707 18.385 18.384s-7.708 18.385-18.385 18.385Zm0-6.718c6.293 0 10.607-4.808 10.607-11.667s-4.314-11.667-10.607-11.667c-6.364 0-10.606 4.808-10.606 11.667s4.242 11.667 10.606 11.667Zm55.701 6.152V14.544h-7.707v15.627c0 8.556-3.606 13.647-9.546 13.647-4.525 0-7.353-3.111-7.353-7.566V14.544h-7.708V38.02c0 7.707 5.303 12.728 12.94 12.728 5.162 0 9.334-2.687 11.667-6.506v5.94h7.707Zm27.219-.283c-1.697.566-3.677.849-5.657.849-6.717 0-11.737-3.677-11.737-11.809V21.12h-6.223v-6.576h6.223V3.938h7.707v10.606h9.192v6.576h-9.192v17.395c0 3.535 1.98 5.374 4.879 5.374 1.343 0 2.97-.212 4.313-.849l.495 6.86ZM264.683.12v50.063h7.707V.12h-7.707Zm47.177 14.303h-8.075l-9.733 25.162-9.517-25.162h-8.507l14.131 35.256-5.48 14.203h8.075l19.106-49.459Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgWorkoutlyLogoWhite;
