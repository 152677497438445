import { Box, BoxProps } from '@mui/material';
import { FC, forwardRef, PropsWithChildren, useCallback } from 'react';

export type FlexProps = BoxProps & {
	onClick?: () => void;
};

export const Flex: FC<PropsWithChildren<FlexProps>> = forwardRef(
	({ children, onClick = () => {}, ...rest }, ref) => {
		const handleClick = useCallback(() => {
			onClick();
		}, [onClick]);

		return (
			<Box
				{...rest}
				onClick={handleClick}
				sx={{
					display: 'flex',
					...rest.sx
				}}
				ref={ref}>
				{children}
			</Box>
		);
	}
);

Flex.displayName = 'Flex';
