import * as React from 'react';
import type { SVGProps } from 'react';
const SvgKitesurfingFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M21 4c.531 0 1 .469 1 1v8.219c0 .469-.344.875-.781.969l-10.844 2.5-2.75 2.75A1.959 1.959 0 0 1 6.219 20c-.532 0-1.063-.219-1.5-.688-.344-.406-.469-.937-.469-1.437v-2.656l.75-.5.75.5V18c0 .281.219.5.469.5.125 0 .25-.063.343-.156l2.75-2.719 2.5-10.844c.094-.437.5-.781.97-.781H21Zm-1.656 6.656-1.657-1.625 1.657-1.656a.502.502 0 0 0 .156-.344c0-.281-.219-.5-.5-.5-.156 0-.281.032-.375.125L17 8.313l-1.656-1.657A.47.47 0 0 0 15 6.531c-.281 0-.5.188-.5.5 0 .125.031.25.125.344L16.281 9l-3.656 3.656A.47.47 0 0 0 12.5 13c0 .313.219.5.5.5a.47.47 0 0 0 .344-.125L17 9.719l1.625 1.656c.094.094.219.125.375.125.281 0 .5-.219.5-.5a.502.502 0 0 0-.156-.344ZM5 13.72c-.219 0-.406.062-.563.156L2.75 15a.355.355 0 0 1-.25.094.494.494 0 0 1-.5-.5v-2.156c0-.282.219-.5.5-.5.094 0 .188.03.25.093l1.5 1V11c0-.688-.563-1.25-1.25-1.25h-.5c-.281 0-.5-.25-.5-.5v-.5c0-.25.219-.5.5-.5H3c1.5 0 2.75 1.25 2.75 2.75v2l1.469-.969a.355.355 0 0 1 .25-.094c.281 0 .531.22.531.5v2.157c0 .281-.25.5-.531.5a.355.355 0 0 1-.25-.094L5.53 13.875A1.053 1.053 0 0 0 5 13.719Z"
		/>
	</svg>
);
export default SvgKitesurfingFilled;
