import * as React from 'react';
import type { SVGProps } from 'react';
const SvgQuestionFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M12 2c5.508 0 10 4.492 10 10 0 5.547-4.492 10-10 10A9.97 9.97 0 0 1 2 12C2 6.492 6.453 2 12 2Zm0 15.625c.664 0 1.25-.547 1.25-1.25s-.586-1.25-1.25-1.25c-.742 0-1.25.547-1.25 1.25s.547 1.25 1.25 1.25Zm2.695-5.547c.86-.508 1.367-1.406 1.367-2.344C16.063 8.211 14.852 7 13.25 7h-1.992C9.773 7 8.563 8.21 8.563 9.734c0 .508.43.938.937.938a.95.95 0 0 0 .938-.938c0-.468.351-.859.859-.859h1.992c.469 0 .899.39.899.86a.838.838 0 0 1-.43.742l-2.227 1.328a.964.964 0 0 0-.469.82v.625c0 .508.43.938.938.938a.95.95 0 0 0 .938-.938v-.078l1.757-1.094Z"
		/>
	</svg>
);
export default SvgQuestionFilled;
