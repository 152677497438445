import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { RadioButtonIcon } from '@/ui/radio/RadioButtonIcon';

type MuiRadioOverrides = {
	defaultProps?: ComponentsProps['MuiRadio'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiRadio'];
	variants?: ComponentsVariants['MuiRadio'];
};

export const muiRadio: MuiRadioOverrides = {
	defaultProps: {
		disableRipple: true,
		icon: <RadioButtonIcon state={'default'} size={'large'} />,
		checkedIcon: <RadioButtonIcon state={'selected'} size={'large'} />
	}
};
