import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSettingsStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M21.707 14.344c0 1.054-2.031 5.039-3.36 5.039a.75.75 0 0 1-.468-.156l-1.29-.743c-.429.313-.858.586-1.327.782v1.484c0 .43-.274.82-.703.938-.86.234-1.72.312-2.579.312-.859 0-1.718-.078-2.578-.352a1.01 1.01 0 0 1-.703-.937v-1.484c-.469-.196-.898-.47-1.328-.782l-1.289.742a.75.75 0 0 1-.469.157c-1.367 0-3.32-4.18-3.32-5 0-.313.195-.625.508-.82l1.289-.743c-.04-.234-.04-.508-.04-.781 0-.234 0-.508.04-.742l-1.29-.742c-.312-.196-.507-.508-.507-.82 0-1.055 2.031-5.04 3.32-5.04a.75.75 0 0 1 .469.157l1.29.742c.429-.313.858-.586 1.327-.782V3.29c0-.43.313-.82.703-.937a9.762 9.762 0 0 1 5.195 0c.391.117.704.507.704.937v1.484c.469.196.898.47 1.328.782l1.289-.742a.75.75 0 0 1 .469-.157c1.367 0 3.32 4.18 3.32 5.04 0 .312-.195.624-.508.82l-1.289.742c.04.234.04.508.04.742 0 .273 0 .547-.04.742l1.29.742c.312.196.507.508.507.86Zm-3.477 2.89a8.073 8.073 0 0 0 1.407-2.46l-1.68-.977c.078-.977.117-1.406.117-1.797 0-.39-.039-.781-.117-1.758l1.68-.976a8.073 8.073 0 0 0-1.407-2.461l-1.718.976c-1.64-1.21-1.25-.976-3.086-1.797V4.031a6.375 6.375 0 0 0-1.406-.156c-.43 0-.938.04-1.446.156v1.953c-1.836.82-1.445.586-3.086 1.797L5.77 6.766c-.586.742-1.094 1.601-1.407 2.5l1.68.976c-.078.977-.117 1.367-.117 1.758 0 .43.039.82.117 1.797l-1.68.937c.313.938.82 1.758 1.407 2.5l1.718-1.015c1.64 1.21 1.25.976 3.086 1.797v1.953c.313.078.82.156 1.406.156.43 0 .938-.04 1.446-.156v-1.953c1.836-.82 1.445-.586 3.086-1.797l1.718 1.015ZM12.02 8.25c2.03 0 3.75 1.68 3.75 3.75 0 2.031-1.72 3.75-3.75 3.75-2.07 0-3.75-1.719-3.75-3.75 0-2.07 1.68-3.75 3.75-3.75Zm0 5.625c1.015 0 1.875-.82 1.875-1.875 0-1.016-.86-1.875-1.875-1.875-1.055 0-1.875.86-1.875 1.875 0 1.055.82 1.875 1.875 1.875Z"
		/>
	</svg>
);
export default SvgSettingsStroke;
