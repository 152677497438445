import { useCulture, useGoogleMapScripts } from '@/config';
import { useEffect, useState } from 'react';
import { GoogleMapAutocompleteItem, isNil, Loader, Maybe } from '@/shared';
import { SearchType } from '@/ui/autocomplete/google-map/GoogleMapAutocomplete';

const SUPPORTED_COUNTRIES = ['pl'];

export const useGoogleMapSuggestions = (
	query: Maybe<string>,
	searchType: SearchType,
	includeQueryInOptions = false
): {
	loader: Loader;
	options: readonly GoogleMapAutocompleteItem[];
} => {
	const { autoCompleteService } = useGoogleMapScripts();
	const { culture } = useCulture();
	const [options, setOptions] = useState<readonly GoogleMapAutocompleteItem[]>([]);
	const [loader, setLoader] = useState<Loader>({ loading: false });

	useEffect(() => {
		if (!isNil(autoCompleteService) && !isNil(query) && query.length > 0) {
			setLoader({ loading: true });
			autoCompleteService
				.getPlacePredictions({
					componentRestrictions: {
						country: SUPPORTED_COUNTRIES
					},
					input: query,
					language: culture.split('-')[0],
					types: searchType === 'cities' ? ['(cities)'] : ['establishment', 'geocode']
				})
				.then((response) => response.predictions)
				.then((value) => {
					if (isNil(value) || value.length === 0) {
						setOptions(includeQueryInOptions ? [query] : []);
					} else {
						setOptions(value);
					}
					setLoader({ loading: false });
				})
				.catch(() => {
					setOptions([]);
					setLoader({ loading: false });
				});
		}
	}, [autoCompleteService, culture, includeQueryInOptions, query, searchType]);

	return {
		options,
		loader
	};
};
