import { Notification } from '@/shared/model/Notification';
import { NotificationResponse } from '@/api';

export const mapNotification = (response: NotificationResponse): Notification => {
	return {
		id: response.id,
		read: response.read,
		metadata: response.metadata,
		type: response.type,
		date: new Date(response.date)
	};
};
