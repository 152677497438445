import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { primary } from '@/ui/theme/components-overrides/colors';

type MuiSnackbarOverrides = {
	defaultProps?: ComponentsProps['MuiSnackbar'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiSnackbar'];
	variants?: ComponentsVariants['MuiSnackbar'];
};

export const muiSnackbar: MuiSnackbarOverrides = {
	styleOverrides: {
		root: {
			'& .MuiPaper-root': {
				backgroundColor: primary.contrast,
				padding: '16px'
			},
			'& .MuiSnackbarContent-message': {
				width: '100%'
			}
		}
	}
};
