import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMuayThaiFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 56 66"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			fillRule="evenodd"
			d="m53.555 11.38-4.573-6.288C46.862 2.176 43.362.5 39.7.5H15.562c-3.892 0-7.59 1.893-9.648 5.127L2.079 11.65a9.972 9.972 0 0 0-1.57 5.772l.997 24.69.023.248.012.078c1.16 7.381 3.908 13.23 8.48 17.217 4.58 3.992 10.675 5.844 17.984 5.844 7.31 0 13.405-1.851 17.985-5.844 4.573-3.987 7.32-9.836 8.48-17.217l.012-.078.023-.248.987-24.429c.092-2.259-.597-4.465-1.935-6.305h-.002Zm-34.532-.047h17.952a2.166 2.166 0 1 1 0 4.333H19.023a2.166 2.166 0 1 1 0-4.333Zm25.855 19.818-.56 4.626A1.96 1.96 0 0 1 42.37 37.5h-2.855c-2.377 0-4.683 1.813-4.683 4.5 0 .511.092 1.016.263 1.486l1.84 5.06c.4 1.097.154 2.335-.754 3.279-1.597 1.657-4.206 2.674-8.175 2.674-3.97 0-6.298-.906-7.922-2.425-1.091-1.02-1.347-2.441-.799-3.647l2.102-4.623c1.125-2.475-.255-5.105-2.562-5.985a4.857 4.857 0 0 0-1.728-.317H12.55a1.96 1.96 0 0 1-1.946-1.724l-.556-4.574a1.96 1.96 0 0 1 1.564-2.158c5.173-1.027 10.637-1.543 16.394-1.543 5.756 0 10.8.505 15.352 1.5a1.956 1.956 0 0 1 1.52 2.148l-.001-.001Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgMuayThaiFilled;
