import {
	PackageOfferResponse,
	PackageOfferVariantDetailsResponse,
	PackageOfferVariantResponse
} from '@/api';
import {
	CurrencyCode,
	PackageOffer,
	PackageOfferVariant,
	PackageOfferVariantDetails
} from '@/shared';

export const mapPackage = (packageMetaDataResponses: PackageOfferResponse[]): PackageOffer[] => {
	return packageMetaDataResponses.map((responseMetadata) => {
		const variants: PackageOfferVariant[] = responseMetadata.variants.map((variant) => ({
			id: variant.id,
			numberOfTrainingSessions: variant.numberOfTrainingSessions,
			price: {
				value: parseFloat(variant.price.value),
				currencyCode: variant.price.currencyCode as CurrencyCode
			}
		}));
		return {
			category: { name: responseMetadata.category },
			discipline: responseMetadata.discipline,
			variants
		};
	});
};

export const mapPackageVariant = (response: PackageOfferVariantResponse): PackageOfferVariant => {
	return {
		id: response.id,
		numberOfTrainingSessions: response.numberOfTrainingSessions,
		price: {
			value: parseFloat(response.price.value),
			currencyCode: response.price.currencyCode as CurrencyCode
		}
	};
};

export const mapPackageOffer = (response: PackageOfferResponse): PackageOffer => {
	return {
		discipline: response.discipline,
		category: { name: response.category },
		variants: response.variants.map((variant) => mapPackageVariant(variant))
	};
};

export const mapPackageOfferVariantDetails = (
	response: PackageOfferVariantDetailsResponse
): PackageOfferVariantDetails => {
	return {
		discipline: response.discipline,
		category: { name: response.category },
		variant: mapPackageVariant(response.variant)
	};
};
