import { client, createClient } from '@hey-api/client-fetch';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useNotification } from '@/ui/notification/notification-context';
import { useTranslation } from '@/config';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const is5xxResponse = (response: Response) => response.status.toString().startsWith('5');

export const HttpClientProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const { open } = useNotification();
	const { t } = useTranslation();

	createClient({
		baseUrl: API_BASE_URL,
		global: true
	});

	useEffect(() => {
		client.interceptors.response.use((response) => {
			if (is5xxResponse(response)) {
				open({
					title: t('shared.api-error-description'),
					type: 'error'
				});
			}
			return response;
		});
	}, [open, t]);

	return <>{children}</>;
};
