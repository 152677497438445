import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLogoutFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M8.253 18.007c.664 0 1.25.587 1.25 1.25a1.26 1.26 0 0 1-1.25 1.252H5.752C3.642 20.509 2 18.867 2 16.757V6.752A3.728 3.728 0 0 1 5.752 3h2.501c.664 0 1.25.586 1.25 1.25a1.26 1.26 0 0 1-1.25 1.251H5.752a1.26 1.26 0 0 0-1.25 1.25v10.006c0 .703.546 1.25 1.25 1.25h2.501Zm13.366-7.113c.508.47.508 1.29 0 1.76l-5.003 5.002a1.205 1.205 0 0 1-1.758 0 1.205 1.205 0 0 1 0-1.76l2.853-2.891H9.504c-.704 0-1.25-.547-1.25-1.25 0-.665.546-1.251 1.25-1.251h8.207L14.858 7.65a1.205 1.205 0 0 1 0-1.759 1.205 1.205 0 0 1 1.758 0l5.003 5.003Z"
		/>
	</svg>
);
export default SvgLogoutFilled;
