import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPilatesFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 18 18"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#221F20"
			d="M1.113 6.483a8.285 8.285 0 0 0 2.03 8.372c2.262 2.263 5.52 2.937 8.377 2.028a12.1 12.1 0 0 1-10.407-10.4ZM16.951 6.695a8.237 8.237 0 0 0-2.099-3.548 8.224 8.224 0 0 0-3.548-2.095 5.189 5.189 0 0 0 1.506 4.136 5.191 5.191 0 0 0 4.141 1.505v.002Z"
		/>
		<path
			fill="#221F20"
			d="M17.2 7.89a6.402 6.402 0 0 1-5.25-1.842 6.401 6.401 0 0 1-1.84-5.246A8.213 8.213 0 0 0 7.93.796a7.915 7.915 0 0 0 2.151 7.121 7.918 7.918 0 0 0 7.127 2.149 8.32 8.32 0 0 0-.007-2.178v.001Z"
		/>
		<path
			fill="#221F20"
			d="M9.222 8.775a9.142 9.142 0 0 1-2.569-7.714 8.244 8.244 0 0 0-1.648.687 9.86 9.86 0 0 0 1.76 7.324.606.606 0 1 1-.98.717A11.074 11.074 0 0 1 3.69 2.647a8.285 8.285 0 0 0-1.449 1.568 10.89 10.89 0 0 0 11.54 11.539 8.28 8.28 0 0 0 1.567-1.448 11.076 11.076 0 0 1-7.14-2.095.607.607 0 0 1 .716-.979 9.861 9.861 0 0 0 7.329 1.758 8.2 8.2 0 0 0 .687-1.648 9.137 9.137 0 0 1-7.719-2.567Zm-1.46 2.32a.594.594 0 0 1-.196.131.83.83 0 0 1-.114.036.672.672 0 0 1-.239 0 .62.62 0 0 1-.31-.167.609.609 0 0 1 .662-.99.56.56 0 0 1 .103.057.611.611 0 0 1 .27.503.612.612 0 0 1-.178.43h.002Z"
		/>
	</svg>
);
export default SvgPilatesFilled;
