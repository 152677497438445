import { PlaceDTO } from '@/api';
import { isNil, Place } from '@/shared';

export const mapPlace = (placeResponse: PlaceDTO): Place => ({
	externalId: placeResponse.id,
	cityExternalId: placeResponse.cityId,
	coordinates: {
		longitude: placeResponse.coordinates.longitude.toString(),
		latitude: placeResponse.coordinates.latitude.toString()
	},
	details: isNil(placeResponse.placeDetails)
		? undefined
		: {
				name: placeResponse.placeDetails?.name,
				city: placeResponse.placeDetails?.city,
				country: placeResponse.placeDetails?.country,
				formattedAddress: placeResponse.placeDetails?.formattedAddress
		  }
});
