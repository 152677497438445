import { isNil, Location, Place } from '@/shared';
import { LocationDTO, PlaceDTO } from '@/api';
import { mapPlace } from '@/shared/repository/place-mapper';

export const toPlaceDTO = (place: Place): PlaceDTO => ({
	id: place.externalId,
	cityId: place.cityExternalId,
	coordinates: {
		longitude: parseFloat(place.coordinates.longitude),
		latitude: parseFloat(place.coordinates.latitude)
	},
	placeDetails: isNil(place.details)
		? undefined
		: {
				name: place.details.name,
				city: place.details.city,
				country: place.details.country,
				formattedAddress: place.details.formattedAddress
		  }
});

export const toLocationDTO = (location: Location): LocationDTO => ({
	place: toPlaceDTO(location.place),
	name: location.name
});

export const toLocation = (locationDTO: LocationDTO): Location => ({
	place: mapPlace(locationDTO.place),
	name: locationDTO.name
});
