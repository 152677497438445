import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBasketballStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M12 2c5.508 0 10 4.492 10 10 0 5.547-4.492 10-10 10A9.97 9.97 0 0 1 2 12C2 6.492 6.453 2 12 2Zm8.125 10a7.833 7.833 0 0 0-1.797-5l-5.039 5 1.68 1.68c1.328-1.016 3.008-1.68 4.844-1.68.078 0 .195.04.273.04 0 0 .039 0 .039-.04Zm-6.25 7.813v.117c1.172-.313 2.227-.82 3.125-1.563l-1.992-1.992c-.703.977-1.133 2.148-1.133 3.438ZM17 5.671a7.833 7.833 0 0 0-5-1.797c-.04 0-.04.04-.04.04 0 .077.04.194.04.272 0 1.836-.664 3.516-1.68 4.844l1.68 1.68 5-5.04Zm-10.04 0 1.993 1.992c.703-.976 1.172-2.148 1.172-3.476 0 0-.04-.04-.04-.079a7.615 7.615 0 0 0-3.124 1.563ZM5.634 6.96c-.742.937-1.29 1.992-1.563 3.164h.117c1.29 0 2.461-.43 3.438-1.133L5.633 6.961ZM3.875 12c0 1.914.625 3.672 1.797 5.04l5-5.04-1.68-1.64A7.93 7.93 0 0 1 4.187 12h-.312Zm3.086 6.367A8.052 8.052 0 0 0 12 20.125v-.313a7.93 7.93 0 0 1 1.64-4.804L12 13.328l-5.04 5.04Zm11.367-1.328a7.615 7.615 0 0 0 1.563-3.125c-.04 0-.078-.039-.078-.039-1.329 0-2.5.43-3.477 1.133l1.992 2.031Z"
		/>
	</svg>
);
export default SvgBasketballStroke;
