import * as React from 'react';
import { FC, useMemo } from 'react';
import SvgEllipseStroke from '@/assets/icons/generated/stroke/EllipseStroke';
import { primary, secondary } from '@/ui/theme/components-overrides/colors';
import Box from '@mui/material/Box';
import { Flex } from '@/ui/flex/Flex';

export type RadioButtonIconState = 'default' | 'hover' | 'pressed' | 'selected' | 'disabled';
export type RadioButtonIconSize = 'medium' | 'large';

type RadioButtonIconProps = {
	state?: RadioButtonIconState;
	size?: RadioButtonIconSize;
};

export const RadioButtonIcon: FC<RadioButtonIconProps> = ({
	state = 'default',
	size = 'medium'
}) => {
	const mainCircleDimensions = size === 'medium' ? '16px' : '20px';
	const innerCircleDimensions = size === 'medium' ? '8px' : '12px';

	const mainCircleColor = useMemo(() => {
		switch (state) {
			case 'default':
			case 'hover':
			case 'pressed':
			default:
				return 'transparent';
			case 'selected':
				return secondary.main;
			case 'disabled':
				return primary['50'];
		}
	}, [state]);

	const mainCircleStroke = useMemo(() => {
		switch (state) {
			case 'default':
			case 'hover':
			case 'disabled':
			default:
				return primary['100'];
			case 'selected':
			case 'pressed':
				return secondary.main;
		}
	}, [state]);

	const innerCircleColor = useMemo(() => {
		switch (state) {
			case 'hover':
			default:
				return primary['100'];
			case 'selected':
				return primary.main;
			case 'pressed':
				return secondary.main;
		}
	}, [state]);

	if (state === 'default' || state === 'disabled') {
		return (
			<Flex alignItems={'center'}>
				<SvgEllipseStroke
					cursor={'pointer'}
					color={mainCircleColor}
					stroke={mainCircleStroke}
					width={mainCircleDimensions}
					height={mainCircleDimensions}
				/>
			</Flex>
		);
	}

	return (
		<Flex
			alignItems={'center'}
			sx={{
				cursor: 'pointer',
				position: 'relative'
			}}>
			<SvgEllipseStroke
				width={mainCircleDimensions}
				height={mainCircleDimensions}
				color={mainCircleColor}
				stroke={mainCircleStroke}
			/>
			<Box
				sx={{
					position: 'absolute',
					top: size === 'medium' ? -6.5 : -2.5,
					left: 4
				}}>
				<SvgEllipseStroke
					width={innerCircleDimensions}
					height={innerCircleDimensions}
					color={innerCircleColor}
					stroke={innerCircleColor}
				/>
			</Box>
		</Flex>
	);
};
