import { useAuth0 } from '@auth0/auth0-react';
import {
	createContext,
	FC,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { client } from '@hey-api/client-fetch';

type TokenContextProperties = {
	refreshToken: () => Promise<string>;
	tokenReady: boolean;
};

const tokenContext = createContext<TokenContextProperties>({
	refreshToken: async () => {
		return 'token';
	},
	tokenReady: false
});

export const useToken = () => {
	return useContext(tokenContext);
};

export const TokenProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
	const [isTokenReady, setIsTokenReady] = useState(false);

	const refreshToken = useCallback(async () => {
		const t = await getAccessTokenSilently({ ignoreCache: true });
		client.interceptors.request.use((request, _) => {
			request.headers.set('Authorization', `Bearer ${t}`);
			return request;
		});
		return t;
	}, [getAccessTokenSilently]);

	useEffect(() => {
		if (isAuthenticated && !isLoading) {
			getAccessTokenSilently()
				.then((t) => {
					setIsTokenReady(true);
					client.interceptors.request.use((request, _) => {
						request.headers.set('Authorization', `Bearer ${t}`);
						return request;
					});
				})
				.catch((err) => {
					throw err;
				});
		}
	}, [getAccessTokenSilently, isAuthenticated, isLoading]);

	const contextValue = useMemo<TokenContextProperties>(
		() => ({
			refreshToken,
			tokenReady: isTokenReady
		}),
		[isTokenReady, refreshToken]
	);

	return <tokenContext.Provider value={contextValue}>{children}</tokenContext.Provider>;
};
