import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { Theme } from '@mui/material';

type MuiTableContainerOverrides = {
	defaultProps?: ComponentsProps['MuiTableContainer'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiTableContainer'];
	variants?: ComponentsVariants['MuiTableContainer'];
};

export const muiTableContainer: MuiTableContainerOverrides = {
	styleOverrides: {
		root: {
			boxShadow: 'none',
			overflowX: 'unset'
		}
	}
};
