import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheckCircleDefault = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 20 20"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path fill="#fff" d="M4 4h12v12H4z" />
		<path
			fill="currentColor"
			d="M10 0c5.508 0 10 4.492 10 10 0 5.547-4.492 10-10 10A9.97 9.97 0 0 1 0 10C0 4.492 4.453 0 10 0Zm4.492 8.281c.43-.43.43-1.094 0-1.523a1.061 1.061 0 0 0-1.523 0L8.75 10.977l-1.758-1.72a1.061 1.061 0 0 0-1.523 0c-.43.43-.43 1.095 0 1.524l2.5 2.5c.195.235.468.313.781.313.273 0 .547-.078.742-.313l5-5Z"
		/>
	</svg>
);
export default SvgCheckCircleDefault;
