import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSurfingFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 20 20"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M3.973 10.47v.03c-.047.992.062 1.902.267 2.263a3.098 3.098 0 0 0 1.885 1.462c.803.22 1.644.114 2.368-.3a3.123 3.123 0 0 0 1.163-4.254 3.102 3.102 0 0 0-1.314-1.243.609.609 0 0 1 .24-1.15 7.483 7.483 0 0 1 6.918 3.774l.09.157C19.186 6.078 19.99.342 20 .273a.243.243 0 0 0-.271-.271c-.08.011-8.11 1.134-13.67 6.694-.78.78-1.473 1.607-2.088 2.454l.003 1.32h-.001Z"
		/>
		<path
			fill="#000"
			d="M14.444 11.652a6.254 6.254 0 0 0-4-2.998 4.338 4.338 0 0 1-1.347 6.328 4.303 4.303 0 0 1-3.29.416 4.313 4.313 0 0 1-2.622-2.033c-.356-.622-.433-1.632-.438-2.348C1.384 13.33.532 15.654.008 17.495a.239.239 0 0 0 .263.302l2.23-.3-.3 2.23a.238.238 0 0 0 .303.263c3.026-.86 7.354-2.607 10.798-6.052a20.1 20.1 0 0 0 1.49-1.67l-.35-.62.002.005Z"
		/>
	</svg>
);
export default SvgSurfingFilled;
