import { Flex } from '@/ui/flex/Flex';
import { Button, ListItemButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate, useTranslation } from '@/config';
import { useDrawer } from '@/ui/drawer/drawer-context';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppType } from '@/config/app-type/appTypeContext';
import { primary } from '@/ui/theme/components-overrides/colors';

export const MenuListNotAuthenticated = () => {
	const { appType } = useAppType();
	const { t } = useTranslation();
	const { closeAll: closeAllDrawers } = useDrawer();
	const navigate = useNavigate();
	const { loginWithRedirect } = useAuth0();

	return (
		<Flex flexDirection={'column'} gap={1}>
			{appType === 'pro' && (
				<Flex justifyContent={'center'}>
					<Typography variant={'h4'}>{t('shared.menu.pro-description')}</Typography>
				</Flex>
			)}

			<ListItemButton onClick={loginWithRedirect} disableGutters={true}>
				<Button size={'large'} sx={{ width: '100%' }}>
					<Typography variant={'h6'}>{t('shared.menu.login')}</Typography>
				</Button>
			</ListItemButton>
			{appType === 'pro' && (
				<ListItemButton
					onClick={() =>
						window.open('https://app.simplymeet.me/workoutly/intro', '_blank')
					}
					disableGutters={true}>
					<Button size={'large'} sx={{ width: '100%' }} variant={'secondary'}>
						<Typography color={primary.contrast} variant={'h6'}>
							{t('shared.menu.make-demo')}
						</Typography>
					</Button>
				</ListItemButton>
			)}
			{appType === 'regular' && (
				<ListItemButton
					onClick={() => {
						closeAllDrawers({ omitTimeout: true });
						navigate('pro');
					}}
					disableGutters={true}>
					<Button variant={'tertiary'} size={'large'} sx={{ width: '100%' }}>
						<Typography variant={'h6'}>
							{t('shared.component.NavbarContent.for-professionals')}
						</Typography>
					</Button>
				</ListItemButton>
			)}
			{appType === 'pro' && (
				<ListItemButton
					onClick={() => {
						closeAllDrawers({ omitTimeout: true });
						navigate('');
					}}
					disableGutters={true}>
					<Button variant={'tertiary'} size={'large'} sx={{ width: '100%' }}>
						<Typography variant={'h6'}>
							{t('shared.component.NavbarContent.for-athletes')}
						</Typography>
					</Button>
				</ListItemButton>
			)}
			<Flex justifyContent={'center'} gap={1}>
				<Typography variant={'h6'}>{t('shared.menu.no-account-yet')}</Typography>
				<Typography
					onClick={loginWithRedirect}
					variant={'h6'}
					sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
					{t('shared.menu.sign-up')}
				</Typography>
			</Flex>
		</Flex>
	);
};
