import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVolleyballStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M12.5 2c5.508 0 10 4.492 10 10 0 5.547-4.492 10-10 10a9.97 9.97 0 0 1-10-10c0-5.508 4.453-10 10-10Zm8.125 10c0-2.813-1.484-5.313-3.71-6.758 1.366 2.383 1.679 4.766 1.679 6.367 0 .391 0 .704-.04 1.016.665-.04 1.329-.117 2.032-.313 0-.078.039-.195.039-.312Zm-1.836 5.117a8.687 8.687 0 0 0 1.523-2.851 9.973 9.973 0 0 1-2.07.195c-2.734 0-4.805-.899-5.742-1.406a10.431 10.431 0 0 0-2.54 2.343 11.091 11.091 0 0 0 6.33 1.993c.78 0 1.6-.078 2.5-.274ZM13.75 3.992c-.39-.039-.82-.117-1.25-.117-.664 0-1.328.117-1.914.273 2.422 2.891 2.773 6.055 2.812 7.344.586.274 1.758.781 3.282 1.016.039-.274.039-.586.039-.938 0-1.836-.469-4.804-2.969-7.578Zm-5.078.86C6.25 6.14 4.532 8.64 4.375 11.57c1.719-2.93 4.14-4.453 5.586-5.117a9.658 9.658 0 0 0-1.29-1.601ZM4.882 14.93a8.142 8.142 0 0 0 1.72 2.695c1.328-3.555 3.828-5.469 4.921-6.133 0-.664-.156-1.914-.703-3.36-1.523.665-4.57 2.54-5.937 6.798Zm3.282 3.984c1.25.781 2.734 1.211 4.336 1.211 1.29 0 2.5-.313 3.594-.82-3.399-.078-5.899-1.407-7.188-2.305-.273.547-.508 1.21-.742 1.914Z"
		/>
	</svg>
);
export default SvgVolleyballStroke;
