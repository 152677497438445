import { OrderResponse } from '@/api';
import { Order } from '@/shared';
import { mapOffer } from '@/shared/repository/offer-mapper';
import { mapTransaction } from '@/shared/repository/transaction-mapper';
import { mapClient } from '@/shared/repository/client-mapper';
import { mapPackageOfferVariantDetails } from '@/shared/repository/package-mapper';

export const mapOrder = (response: OrderResponse): Order => {
	return {
		id: response.id,
		paymentMethod: response.paymentMethod,
		offer: response.offer ? mapOffer(response.offer) : undefined,
		transaction: mapTransaction(response.transaction),
		client: mapClient(response.client),
		packageOffer: response.packageOffer
			? mapPackageOfferVariantDetails(response.packageOffer)
			: undefined
	};
};
