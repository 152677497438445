import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWindsurfingStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M20.268 14.384c.141.141.177.388.07.564a.531.531 0 0 1-.492.317h-7.892a.556.556 0 0 1-.564-.564V3.555c0-.212.141-.423.352-.529.247-.07.494 0 .635.212l7.891 11.146Zm1.691 2.008c.353 0 .6.353.529.705l-.317 1.444c-.458 2.044-2.29 2.488-4.404 2.488H7.233c-2.114 0-3.946-.444-4.404-2.488l-.317-1.444c-.07-.352.176-.705.529-.705h18.918ZM4.45 18.084l.036.07c.282 1.303 1.41 1.184 2.748 1.184h10.534c1.339 0 2.466.12 2.748-1.184l.035-.07H4.45Z"
		/>
	</svg>
);
export default SvgWindsurfingStroke;
