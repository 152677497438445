import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSwimmingStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M23.5 17.937c0 .574-.46.919-.88.919-.344 0-1.607-.306-2.793-1.225-1.033.765-2.334 1.148-3.635 1.148-1.339 0-2.64-.383-3.673-1.186-1.071.803-2.372 1.263-3.673 1.263-1.416 0-2.87-.46-3.94-1.186-1.149 1.033-2.22 1.186-2.526 1.186-.536 0-.88-.46-.88-.919a.9.9 0 0 1 .727-.88c1.454-.267 1.798-1.568 2.64-1.568.23 0 .46.114.689.268.688.765 2.066 1.262 3.29 1.262 2.372 0 2.793-1.53 3.673-1.53.88 0 1.263 1.53 3.673 1.53 2.334 0 2.755-1.53 3.635-1.53.88 0 1.11 1.224 2.908 1.568.42.077.765.46.765.88Zm-18.365-7.04A2.441 2.441 0 0 1 2.686 8.45 2.441 2.441 0 0 1 5.135 6c1.339 0 2.448 1.11 2.448 2.449a2.441 2.441 0 0 1-2.448 2.448Zm2.793 4.286c-.536 0-.918-.383-.918-.919 0-1.607.573-3.137 1.683-4.323l.612-.727c.345-.383 2.793-3.1 7.002-3.1a8.51 8.51 0 0 1 2.18.269l1.225.267c.421.115.727.498.727.919 0 .535-.459.88-.918.88h-.23l-1.224-.306a8.858 8.858 0 0 0-1.76-.192c-1.913 0-3.214.727-3.367.804l4.744 4.247c.192.153.306.42.306.688 0 .498-.42.88-.918.88a.844.844 0 0 1-.574-.23l-5.127-4.514c-.23.191-.459.383-.65.612l-.689.727c-.765.88-1.186 1.952-1.186 3.1 0 .535-.42.918-.918.918Z"
		/>
	</svg>
);
export default SvgSwimmingStroke;
