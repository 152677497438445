import { FC } from 'react';
import { Flex } from '@/ui/flex/Flex';
import Typography from '@mui/material/Typography';
import { primary } from '@/ui/theme/components-overrides/colors';

type SliderThumbLabelProps = {
	value: string;
};

export const SliderThumbLabel: FC<SliderThumbLabelProps> = ({ value }) => {
	return (
		<Flex
			justifyContent={'center'}
			alignItems={'center'}
			gap={1}
			sx={{
				padding: '4px 7px',
				borderRadius: '100px',
				backgroundColor: primary.black
			}}>
			<Typography variant={'h8'} color={primary.contrast}>
				{value}
			</Typography>
		</Flex>
	);
};
