import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEllipseStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 16 16"
		stroke={props?.stroke || 'none'}
		{...props}>
		<circle cx={8} cy={8} r={7.5} fill="currentColor" stroke="props?.stroke || currentColor" />
	</svg>
);
export default SvgEllipseStroke;
