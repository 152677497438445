import { FC, useState } from 'react';
import { isLoading, Loader } from '@/shared';
import { Flex } from '@/ui/flex/Flex';
import { LayoutType, Navbar, Router } from '@/config';
import { Container } from '@mui/material';
import { Footer } from '@/ui/footer/Footer';

type NonApplicationContextLayoutProps = {
	variant: LayoutType;
};

export const NonApplicationContextLayout: FC<NonApplicationContextLayoutProps> = ({ variant }) => {
	const [routerLoader, setRouterLoader] = useState<Loader>({ loading: false });

	return (
		<Flex height="100%" flexDirection="column">
			<Navbar layoutType={variant} loading={isLoading([routerLoader])} />
			<Container maxWidth={variant === 'sign-up' ? 'xl' : 'md'} sx={{ my: 4 }}>
				<Router onRouteLoaderChange={setRouterLoader} />
			</Container>
			<Footer />
		</Flex>
	);
};
