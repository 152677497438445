import { useTranslation } from '@/config';
import { useCallback } from 'react';
import {
	DisciplineOrTrainerAutocompleteItem,
	getDisciplineMetaData,
	isItemDiscipline,
	isItemTrainer,
	isNil
} from '@/shared';

type DisplayValueFunctions = {
	translateItem: (item: DisciplineOrTrainerAutocompleteItem) => string;
};

export const useDisciplineOrTrainerAutocompleteItemDisplayValue = (): DisplayValueFunctions => {
	const { t } = useTranslation();
	const translateItem = useCallback(
		(item: DisciplineOrTrainerAutocompleteItem) => {
			if (isNil(item)) {
				return '';
			}
			if (isItemDiscipline(item)) {
				return t(getDisciplineMetaData(item).translationKey);
			}
			if (isItemTrainer(item)) {
				return `${item!.contactDetails.name} ${item!.contactDetails.surname}`;
			}
			return item;
		},
		[t]
	);
	return { translateItem };
};
