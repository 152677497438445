import { useNavigate as routerUseNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { NavigateOptions } from 'react-router/dist/lib/context';
import { Path } from '@/config';
import { isNil, Maybe } from '@/shared';

interface CustomNavigateFunction {
	(to: Path | Partial<ExtendedPath>, options?: NavigateOptions): void;

	(delta: number): void;
}

type ExtendedPath = {
	pathname: Path;
	search: string;
	hash: string;
};

export const useNavigate = (): CustomNavigateFunction => {
	const routerNavigate = routerUseNavigate();

	const normalizeSearchParams = (params: Maybe<string>): string => {
		if (isNil(params)) {
			return '';
		}
		return params.startsWith('?') ? params : `?${params}`;
	};

	const normalizeHash = (hash: Maybe<string>): string => {
		if (isNil(hash)) {
			return '';
		}
		return hash.startsWith('#') ? hash : `#${hash}`;
	};

	return useCallback(
		(to: Path | Partial<ExtendedPath> | number, opts?: NavigateOptions) => {
			if (typeof to === 'number') {
				routerNavigate(to);
			} else if (typeof to === 'object' && 'pathname' in to) {
				// If 'to' is an object with 'pathname' and 'search', construct the URL
				const { pathname, search, hash } = to as ExtendedPath;
				routerNavigate(
					`/${pathname}${normalizeSearchParams(search)}${normalizeHash(hash)}`,
					opts
				);
			} else {
				routerNavigate(`/${to}`, opts);
			}
		},
		[routerNavigate]
	);
};
