import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { CheckBoxIcon } from '@/ui/checkbox/CheckBoxIcon';
import { primary, secondary } from '@/ui/theme/components-overrides/colors';

type MuiCheckboxOverrides = {
	defaultProps?: ComponentsProps['MuiCheckbox'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiCheckbox'];
	variants?: ComponentsVariants['MuiCheckbox'];
};

export const muiCheckbox: MuiCheckboxOverrides = {
	defaultProps: {
		color: 'secondary',
		disableRipple: true,
		icon: <CheckBoxIcon rootClassName={'CheckboxIcon-root'} />,
		checkedIcon: (
			<CheckBoxIcon
				rootClassName={'CheckboxIcon-root CheckboxIcon-root-checked'}
				checkIconContainerClassName={'CheckboxIcon-check-icon-container'}
				checked
			/>
		)
	},
	variants: [
		{
			props: { size: 'small' },
			style: {
				'& .CheckboxIcon-root': {
					width: '16px',
					height: '16px'
				},
				'& .CheckboxIcon-check-icon-container': {
					width: '12px',
					height: '12px'
				}
			}
		},
		{
			props: { size: 'medium' },
			style: {
				'& .CheckboxIcon-root': {
					width: '20px',
					height: '20px'
				},
				'& .CheckboxIcon-check-icon-container': {
					width: '16px',
					height: '16px'
				}
			}
		},
		{
			props: { disabled: true },
			style: {
				'& .CheckboxIcon-root': {
					backgroundColor: primary['50'],
					border: `1px solid ${primary['100']}`
				},
				'& .CheckboxIcon-check-icon-container': {
					color: primary['400']
				}
			}
		}
	],
	styleOverrides: {
		root: {
			padding: 0,
			'& .CheckboxIcon-check-icon-container': {
				color: primary.black
			},
			'& .CheckboxIcon-root-checked': {
				backgroundColor: secondary.main,
				border: `1px solid ${secondary.main}`
			},
			'&:hover .CheckboxIcon-root': {
				border: `1px solid ${primary['200']}`
			},
			'&:hover .CheckboxIcon-root-checked': {
				backgroundColor: secondary['600'],
				border: `1px solid ${secondary['600']}`
			}
		}
	}
};
