import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBellStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 20 20"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M10.846 2.708v.586c2.344.39 4.167 2.442 4.167 4.883v1.107c0 1.464.488 2.897 1.4 4.069l.488.586c.195.26.228.586.098.846a.76.76 0 0 1-.684.423H3.295a.773.773 0 0 1-.717-.423.808.808 0 0 1 .098-.846l.488-.586c.912-1.172 1.432-2.604 1.432-4.07V8.178c0-2.441 1.79-4.492 4.167-4.883v-.586c0-.553.456-1.042 1.042-1.042.553 0 1.041.489 1.041 1.042ZM9.544 4.791A3.383 3.383 0 0 0 6.16 8.178v1.107c0 1.562-.456 3.06-1.302 4.362h9.863c-.846-1.302-1.27-2.8-1.27-4.362V8.177a3.404 3.404 0 0 0-3.385-3.385h-.52Zm2.344 11.459c0 .553-.228 1.107-.618 1.497-.391.39-.944.586-1.465.586-.554 0-1.107-.195-1.498-.586-.39-.39-.586-.944-.586-1.497h4.167Z"
		/>
	</svg>
);
export default SvgBellStroke;
