import { useTranslation as i18nUseTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { TranslationKey } from '@/config';

export const useTranslation = () => {
	const { t, i18n } = i18nUseTranslation();

	const translateFunctionWrapper = useCallback(
		(key: TranslationKey, variables?: object) => {
			return t(key, variables);
		},
		[t]
	);

	return {
		t: translateFunctionWrapper,
		i18n
	};
};
