import { Flex } from '@/ui/flex/Flex';
import { IconButton } from '@/ui/icon-button/IconButton';
import SvgSearchStroke from '@/assets/icons/generated/stroke/SearchStroke';
import { useLayout } from '@/config';
import SvgHamburgerStroke from '@/assets/icons/generated/stroke/HamburgerStroke';
import { accent, bgNavbarDark, primary } from '@/ui/theme/components-overrides/colors';
import { useDrawer } from '@/ui/drawer/drawer-context';
import { MenuDrawerContent } from '@/config/layout/navigation/helpers/mobile/MenuDrawerContent';
import SvgCrossStroke from '@/assets/icons/generated/stroke/CrossStroke';
import { useAuth0 } from '@auth0/auth0-react';
import { SearchDrawer } from '@/config/layout/navigation/helpers/mobile/SearchDrawer';
import { MobileWorkoutlyIcon } from '@/config/layout/navigation/helpers/mobile/MobileWorkoutlyIcon';
import { NotificationIcon } from '@/config/layout/navigation/helpers/NotificationIcon';

export const MobileNavbar = () => {
	const { open: openDrawer, close: closeDrawer, isOpen: isDrawerOpen } = useDrawer();
	const { isAuthenticated } = useAuth0();
	const layoutType = useLayout();

	const iconButtonBgColor = () => {
		switch (layoutType) {
			case 'landing-pro':
				return primary.main;
			case 'landing':
				return bgNavbarDark;
			default:
				return primary['50'];
		}
	};

	const iconMenuColor = () => {
		switch (layoutType) {
			case 'landing-pro':
				return accent;
			case 'landing':
				return primary['50'];
			default:
				return primary.main;
		}
	};

	return (
		<Flex padding={2} alignItems={'center'} justifyContent={'space-between'}>
			<MobileWorkoutlyIcon />
			<Flex gap={1} alignItems={'center'}>
				{layoutType === 'application' && (
					<IconButton
						id={'navbar-SearchIcon-id'}
						onClick={() =>
							openDrawer({
								drawerBodyComponent: <SearchDrawer />
							})
						}
						size={'small'}
						variant={'secondary'}
						sx={{
							padding: '12px',
							height: '100%',
							borderRadius: '4px',
							backgroundColor: primary['50']
						}}>
						<SvgSearchStroke width={'24px'} height={'24px'} color={primary.main} />
					</IconButton>
				)}
				{isAuthenticated && <NotificationIcon />}
				<IconButton
					onClick={() =>
						isDrawerOpen
							? closeDrawer()
							: openDrawer({
									drawerBodyComponent: <MenuDrawerContent />,
									closeXButton: true
							  })
					}
					size={'small'}
					variant={'secondary'}
					sx={{
						padding: '12px',
						height: '100%',
						borderRadius: '4px',
						backgroundColor: iconButtonBgColor()
					}}>
					{isDrawerOpen ? (
						<SvgCrossStroke
							width={'24px'}
							height={'24px'}
							color={layoutType === 'landing' ? primary['50'] : primary.main}
						/>
					) : (
						<SvgHamburgerStroke
							width={'24px'}
							height={'24px'}
							color={iconMenuColor()}
						/>
					)}
				</IconButton>
			</Flex>
		</Flex>
	);
};
