import { FC } from 'react';
import { AutocompleteRenderGroupParams } from '@mui/material/Autocomplete/Autocomplete';
import Box from '@mui/material/Box';
import { primary } from '@/ui/theme/components-overrides/colors';
import Typography from '@mui/material/Typography';

export const DisciplineOrTrainerAutocompleteGroup: FC<AutocompleteRenderGroupParams> = ({
	group,
	children
}) => {
	return (
		<>
			<Box
				sx={{
					position: 'sticky',
					top: '-8px',
					zIndex: '1',
					paddingY: 1,
					paddingX: 2,
					backgroundColor: primary.contrast
				}}>
				<Typography variant={'p4'}>{group}</Typography>
			</Box>
			{children}
		</>
	);
};
