import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { Theme } from '@mui/material';
import { primary } from '@/ui/theme/components-overrides/colors';

type MuiTabsOverrides = {
	defaultProps?: ComponentsProps['MuiTabs'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiTabs'];
	variants?: ComponentsVariants['MuiTabs'];
};

export const muiTabs: MuiTabsOverrides = {
	defaultProps: {
		allowScrollButtonsMobile: true,
		variant: 'scrollable',
		scrollButtons: 'auto',
		color: 'secondary',
		TabIndicatorProps: {
			style: {
				backgroundColor: 'inherit'
			}
		}
	},
	styleOverrides: {
		root: {
			borderRadius: '8px',
			backgroundColor: primary[50],
			padding: '2px',
			gap: 0.5,
			display: 'flex',
			width: 'auto',
			minHeight: 0,
			minWidth: 0,
			'& .MuiTabs-flexContainer': {
				gap: 0.5
			},
			'& .MuiTabs-scrollButtons.Mui-disabled': {
				opacity: 0.3
			}
		}
	}
};
