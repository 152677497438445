import { Loader, Maybe, TrainerBankAccount, useObservable } from '@/shared';
import { createContext, FC, PropsWithChildren, useMemo } from 'react';
import { useSelfTrainerRepository } from '@/shared/repository/trainer/use-self-trainer-repository';

type TrainerBankAccountContextProperties = {
	bankAccount: Maybe<TrainerBankAccount>;
	loader: Loader;
};

export const trainerBankAccountContext = createContext<TrainerBankAccountContextProperties>({
	bankAccount: null,
	loader: {
		loading: false
	}
});

export const TrainerBankAccountContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const { getBankAccount } = useSelfTrainerRepository();
	const bankAccount$ = useMemo(() => getBankAccount(), [getBankAccount]);
	const { value, loader } = useObservable(bankAccount$);

	const contextValue = useMemo(
		() => ({
			bankAccount: value,
			loader
		}),
		[loader, value]
	);

	return (
		<trainerBankAccountContext.Provider value={contextValue}>
			{children}
		</trainerBankAccountContext.Provider>
	);
};
