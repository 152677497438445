import { Flex } from '@/ui/flex/Flex';
import { Button, Divider, ListItem, ListItemButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { menu, MenuItem, useNavigate, useTranslation } from '@/config';
import { useDrawer } from '@/ui/drawer/drawer-context';
import { useAuth0 } from '@auth0/auth0-react';
import { isNil, useClient, useTrainer } from '@/shared';
import SvgChevronRightStroke from '@/assets/icons/generated/stroke/ChevronRightStroke';
import { primary } from '@/ui/theme/components-overrides/colors';
import { SubMenuDrawer } from '@/config/layout/navigation/helpers/mobile/SubMenuDrawer';
import SvgLogoutFilled from '@/assets/icons/generated/filled/LogoutFilled';
import { useAppType } from '@/config/app-type/appTypeContext';

export const MenuListAuthenticated = () => {
	const { t } = useTranslation();
	const { open: openDrawer, close: closeDrawer } = useDrawer();
	const navigate = useNavigate();
	const { logout } = useAuth0();
	const { appType } = useAppType();
	const { closeAll: closeAllDrawers } = useDrawer();
	const { me: meClient } = useClient();
	const { me: meTrainer } = useTrainer();

	const handleMenuItemClick = (item: MenuItem) => {
		closeDrawer();
		navigate(item.route.path);
	};

	return (
		<Flex flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
			<Flex flexDirection={'column'} gap={3} mb={3}>
				{menu.items.map((item, index) => {
					return (
						item.appType === appType && (
							<ListItem
								key={index}
								disableGutters={true}
								secondaryAction={
									!isNil(item) &&
									!isNil(item.children) &&
									item.children.items?.length > 0 && (
										<SvgChevronRightStroke
											width={'20px'}
											height={'20px'}
											color={primary.black}
											onClick={() =>
												!isNil(item.children) &&
												openDrawer({
													drawerBodyComponent: (
														<SubMenuDrawer subMenu={item.children} />
													)
												})
											}
										/>
									)
								}
								sx={{
									'&.MuiListItem-root': {
										paddingTop: 0,
										paddingBottom: 0
									}
								}}>
								<ListItemButton
									onClick={() => handleMenuItemClick(item)}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										'&.MuiListItemButton-root': {
											justifyContent: 'space-between',
											paddingTop: 0,
											paddingBottom: 0
										}
									}}
									alignItems={'flex-start'}
									disableGutters={true}>
									<Flex gap={2} alignItems={'center'} width={'100%'}>
										{!isNil(item.icon) &&
											item.icon({
												width: '20px',
												height: '20px',
												color: primary.black
											})}
										<Typography variant={'p2'}>{t(item.name)}</Typography>
									</Flex>
								</ListItemButton>
							</ListItem>
						)
					);
				})}
				<Divider />
				<ListItemButton
					onClick={() => logout({ returnTo: window.location.origin })}
					disableGutters={true}>
					<Flex gap={2} alignItems={'center'} width={'100%'}>
						<SvgLogoutFilled width={'20px'} height={'20px'} color={primary.black} />
						<Typography variant={'p2'}>{t('shared.menu.logout')}</Typography>
					</Flex>
				</ListItemButton>
			</Flex>

			{appType === 'regular' && !isNil(meClient) && isNil(meTrainer) && (
				<Flex alignItems={'flex-end'}>
					<ListItemButton
						onClick={() => {
							closeAllDrawers({ omitTimeout: true });
							navigate(`trainer/${meClient.id}/sign-up`);
						}}
						disableGutters={true}>
						<Button size={'large'} sx={{ width: '100%' }}>
							<Typography variant={'h6'}>
								{t('shared.menu.become-trainer')}
							</Typography>
						</Button>
					</ListItemButton>
				</Flex>
			)}
		</Flex>
	);
};
