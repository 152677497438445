import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCrossfitStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<g clipPath="url(#crossfit_stroke_svg__a)">
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M3.712 17.787a8.825 8.825 0 0 0-.024-.74 8.714 8.714 0 0 1 1.38-4.656c.262-.409.56-.8.892-1.168.412-.45.859-.855 1.337-1.207l-.52-2.61a2.838 2.838 0 0 0 0 0l.52 2.61a9.191 9.191 0 0 0-1.337 1.207c-.332.369-.63.76-.892 1.168a8.714 8.714 0 0 0-1.38 4.656 9.08 9.08 0 0 0 .024.74ZM7.174 4.883a3.088 3.088 0 0 0-.643 2.571l.49 2.456c-.444.34-.86.722-1.245 1.144l-.001.001a8.993 8.993 0 0 0-2.312 6.75 8.976 8.976 0 0 0 2.124 5.189 3.406 3.406 0 0 0 2.582 1.203l8.58.05h.019c.995 0 1.936-.433 2.588-1.188a8.979 8.979 0 0 0 2.153-6.9c-.252-2.357-1.42-4.426-3.11-5.885l.539-2.85a3.09 3.09 0 0 0-.659-2.553 3.094 3.094 0 0 0-2.387-1.121H9.57c-.933 0-1.807.413-2.396 1.133Zm1.112 4.52Zm3.99-1.072Zm.244.003c.046-.004.089-.007.135-.007-.046 0-.09.003-.135.007Zm5.096-1.157Zm-1.626-2.08a1.77 1.77 0 0 0-.098-.003l.098.003Zm-7.524 3.93L8.117 7.28l.004-.003-.026-.131A1.504 1.504 0 0 1 9.57 5.344h6.322c.45 0 .871.199 1.158.546.287.348.404.8.32 1.24l-.405 2.132a8.888 8.888 0 0 0-3.985-1.17 3.238 3.238 0 0 0-.325-.015c-.055 0-.107.004-.151.007h-.002c-.023 0-.045 0-.072-.002h-.027a1.79 1.79 0 0 0-.136-.001 9.035 9.035 0 0 0-3.801.947Zm5.665 1.052a1.982 1.982 0 0 0 0 0Zm-.972-.586a2.013 2.013 0 0 0-.38-.061c-.028 0-.056-.002-.084-.004a1.43 1.43 0 0 0-.09-.003m.554.068a2.013 2.013 0 0 0-.38-.061c-.028 0-.056-.002-.084-.004a1.43 1.43 0 0 0-.09-.003m2.055 1.998a2.044 2.044 0 0 1-.015.25 1.997 1.997 0 0 1 .015-.25Zm-.257 0v.158a1.76 1.76 0 0 1-1.748 1.597 1.759 1.759 0 0 1-1.755-1.755c0-.965.763-1.725 1.708-1.748l.07.003h.004l.087.004a1.748 1.748 0 0 1 1.634 1.741ZM10.01 9.826a3.053 3.053 0 0 0-.447 1.594 3.086 3.086 0 0 0 .447-1.594Zm-3.048 2.3a7.39 7.39 0 0 0-1.91 5.562 7.412 7.412 0 0 0 1.75 4.275c.344.406.845.644 1.375.644h.001l8.58.049h.01c.499 0 .976-.204 1.322-.568v-.003l.061-.07a7.4 7.4 0 0 0 1.774-5.686c-.277-2.57-1.87-4.732-4.055-5.848a3.35 3.35 0 0 1-3.211 4.284 3.35 3.35 0 0 1-3.346-3.346c0-.399.071-.78.2-1.133a7.448 7.448 0 0 0-2.551 1.84Z"
				clipRule="evenodd"
			/>
		</g>
		<defs>
			<clipPath id="crossfit_stroke_svg__a">
				<path fill="#fff" d="M.5 0h24v24H.5z" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgCrossfitStroke;
