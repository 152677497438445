import {
	addReview,
	AddTrainerReviewRequest,
	create,
	CreateTrainerRequestV2,
	downloadTrainerCertificate,
	type DownloadTrainerCertificateResponse,
	getMyPackages,
	GetMyPackagesResponse,
	getTrainer,
	list,
	listTrainings,
	ListTrainingsResponse,
	PageResponseTrainerResponse,
	TrainerResponse,
	UrlDTO
} from '@/api';
import {
	ClientTrainingPackage,
	DownloadFileResponse,
	isNil,
	Maybe,
	Page,
	Trainer,
	Training,
	useStore
} from '@/shared';
import { mapTrainer } from '@/shared/repository/trainer-mapper';
import { useCallback } from 'react';
import { EMPTY, from, map, Observable, of, pipe, switchMap, tap } from 'rxjs';
import { Culture, useCulture } from '@/config';
import { TrainerQuery } from '@/shared/repository/trainer/trainer-api-types';
import {
	castRequestResult,
	handleApiError,
	mapApiResult
} from '@/shared/utils/helpers/rxjs/handle-api-error';
import { mapTrainingPackages } from '@/shared/repository/client-mapper';
import { mapClientTraining } from '@/shared/repository/training-mapper';

type TrainerFunctions = {
	createTrainer: (request: CreateTrainerRequestV2) => Observable<Maybe<string>>;
	getTrainer: (trainerId: string) => Observable<Trainer>;
	getTrainers: (query: TrainerQuery) => Observable<Page<Trainer>>;
	addTrainerReview: (
		trainingId: string,
		trainerId: string,
		request: AddTrainerReviewRequest
	) => Observable<void>;
	downloadCertificate: (trainerId: string, certificateId: string) => Observable<File>;
};

const getTrainersFromApi = (query: TrainerQuery, culture: Culture): Observable<Page<Trainer>> => {
	return from(
		list({
			query: {
				ids: isNil(query.ids) ? undefined : query.ids,
				name: isNil(query.name) ? undefined : query.name,
				languages:
					isNil(query.languages) || query.languages.length === 0
						? undefined
						: query.languages,
				discipline: isNil(query.discipline) ? undefined : query.discipline,
				placeId: isNil(query.placeId) ? undefined : query.placeId,
				startTime: isNil(query.startTime) ? undefined : query.startTime.toISOString(),
				endTime: isNil(query.endTime) ? undefined : query.endTime.toISOString(),
				priceMin: isNil(query.priceMin) ? undefined : query.priceMin,
				priceMax: query.priceMax || undefined,
				sort: isNil(query.sort) ? undefined : query.sort,
				pageNumber: isNil(query.pageNumber) ? undefined : query.pageNumber,
				pageSize: isNil(query.pageSize) ? undefined : query.pageSize,
				contentLanguage: culture
			}
		})
	).pipe(
		handleApiError(),
		mapApiResult<PageResponseTrainerResponse, Page<Trainer>>((response) => {
			const trainers = response.content?.map((trainer) => mapTrainer(trainer)) || [];
			return {
				content: trainers,
				pageNumber: response.pageNumber,
				pageSize: response.pageSize,
				totalElements: response.totalElements,
				totalPages: response.totalPages,
				hasMore: response.hasMore
			};
		})
	);
};

export const useTrainersRepository = (): TrainerFunctions => {
	const { culture } = useCulture();
	const {
		replaceElement: replaceTrainerInStore,
		addElements: addTrainersToStore,
		getElement: getTrainerFromStore
	} = useStore<Trainer>('trainers');

	const { replaceElement: replacePackagesToStore } =
		useStore<ClientTrainingPackage>('client-packages');
	const { replaceElement: replaceTrainingsToStore } = useStore<Training>('trainings');

	const getTrainersCallback = useCallback(
		(query: TrainerQuery) => {
			return getTrainersFromApi(query, culture).pipe(
				tap((trainersPage) => addTrainersToStore(trainersPage.content))
			);
		},
		[addTrainersToStore, culture]
	);

	const createTrainerCallback = useCallback(
		(request: CreateTrainerRequestV2): Observable<Maybe<string>> => {
			return from(
				create({
					body: request
				})
			).pipe(
				handleApiError(),
				mapApiResult<UrlDTO, string>((response) => response.url)
			);
		},
		[]
	);

	const getTrainerCallback = useCallback(
		(trainerId: string) => {
			return getTrainerFromStore(trainerId).pipe(
				switchMap((trainer: Maybe<Trainer>) => {
					if (isNil(trainer)) {
						return from(getTrainer({ path: { trainerId: encodeURI(trainerId) } })).pipe(
							handleApiError(),
							mapApiResult<TrainerResponse, Trainer>(mapTrainer),
							tap((t) => replaceTrainerInStore(trainerId, t))
						);
					}
					return of(trainer);
				})
			);
		},
		[getTrainerFromStore, replaceTrainerInStore]
	);

	const addTrainerReviewCallback = useCallback(
		(
			trainingId: string,
			trainerId: string,
			request: AddTrainerReviewRequest
		): Observable<void> => {
			const updatePackages = () =>
				pipe<Observable<unknown>, Observable<ClientTrainingPackage[]>>(
					switchMap(() =>
						from(getMyPackages()).pipe(
							handleApiError(),
							mapApiResult<GetMyPackagesResponse, ClientTrainingPackage[]>(
								(packagesResponse) =>
									packagesResponse.map((p) => mapTrainingPackages(p))
							),
							tap((packages: ClientTrainingPackage[]) =>
								packages.forEach((clientPackage) =>
									replacePackagesToStore(clientPackage.id, clientPackage)
								)
							)
						)
					)
				);

			const updateTrainings = () =>
				pipe<Observable<unknown>, Observable<Training[]>>(
					switchMap(() =>
						from(listTrainings()).pipe(
							handleApiError(),
							mapApiResult<ListTrainingsResponse, Training[]>(
								(listTrainingsResponse) =>
									listTrainingsResponse.map(mapClientTraining)
							),
							tap((trainings: Training[]) =>
								trainings.forEach((training) =>
									replaceTrainingsToStore(training.id, training)
								)
							)
						)
					)
				);

			return from(
				addReview({
					body: request,
					path: {
						trainingId: trainingId,
						trainerId: trainerId
					}
				})
			).pipe(
				handleApiError(),
				mapApiResult<TrainerResponse, Trainer>(mapTrainer),
				tap((t) => replaceTrainerInStore(trainerId, t)),
				updatePackages(),
				updateTrainings(),
				switchMap(() => EMPTY)
			);
		},
		[replacePackagesToStore, replaceTrainerInStore, replaceTrainingsToStore]
	);

	const downloadCertificateCallback = useCallback(
		(trainerId: string, certificateId: string): Observable<File> => {
			return from(
				downloadTrainerCertificate({
					parseAs: 'auto',
					path: { certificateId: certificateId, trainerId: encodeURI(trainerId) }
				})
			).pipe(
				handleApiError(),
				castRequestResult<DownloadTrainerCertificateResponse>(),
				map((r) => {
					const contentDisposition = r.response.headers.get('content-disposition');
					const fileName = (contentDisposition?.match(/filename="(.+?)"/) || [])[1] || '';
					return {
						...r,
						data: {
							data: r.data,
							fileName: fileName
						}
					};
				}),
				mapApiResult<DownloadFileResponse, File>((data: DownloadFileResponse) => {
					return new File([data.data], data.fileName);
				})
			);
		},
		[]
	);

	return {
		createTrainer: createTrainerCallback,
		getTrainer: getTrainerCallback,
		getTrainers: getTrainersCallback,
		addTrainerReview: addTrainerReviewCallback,
		downloadCertificate: downloadCertificateCallback
	};
};
