import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSurfingStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M21.828 2.172a.59.59 0 0 0-.491-.167c-.322.043-7.941 1.11-13.387 6.557-3.375 3.376-5.087 7.61-5.928 10.569a.585.585 0 0 0 .642.742l1.69-.228-.227 1.69a.59.59 0 0 0 .742.643c2.958-.84 7.192-2.55 10.568-5.926 5.447-5.446 6.516-13.066 6.558-13.389a.58.58 0 0 0-.167-.491Zm-7.218 13.05c-2.884 2.885-6.453 4.491-9.198 5.367l.207-1.55a.585.585 0 0 0-.658-.659l-1.55.207c.404-1.262.964-2.7 1.729-4.165.063.304.155.59.287.82a4.15 4.15 0 0 0 2.53 1.96 4.163 4.163 0 0 0 3.173-.402 4.15 4.15 0 0 0 1.96-2.528 4.149 4.149 0 0 0-.661-3.574 6.028 6.028 0 0 1 3.755 2.724c-.477.62-1 1.224-1.574 1.799v.001Zm2.308-2.813a7.21 7.21 0 0 0-6.284-3.036.587.587 0 0 0-.232 1.11c.533.265.97.679 1.267 1.198a3.011 3.011 0 0 1-1.122 4.104c-.698.398-1.51.5-2.283.288a2.979 2.979 0 0 1-1.819-1.41c-.188-.33-.29-1.133-.264-2.03A19.554 19.554 0 0 1 8.778 9.39c4.16-4.16 9.833-5.652 11.91-6.078-.336 1.634-1.332 5.497-3.77 9.095v.002Z"
		/>
	</svg>
);
export default SvgSurfingStroke;
