import { Flex } from '@/ui/flex/Flex';
import { primary, secondary } from '@/ui/theme/components-overrides/colors';
import { FC } from 'react';
import SvgProBadge from '@/assets/icons/generated/ProBadge';

export type ProLogoMode = 'dark' | 'light';

type ProBadgeProps = {
	mode?: ProLogoMode;
};

export const ProBadge: FC<ProBadgeProps> = ({ mode = 'light' }) => {
	return (
		<Flex
			alignItems={'center'}
			justifyContent={'center'}
			sx={{
				backgroundColor: mode === 'light' ? secondary['900'] : primary.black,
				borderRadius: '3px',
				padding: '3px'
			}}>
			<SvgProBadge
				fill={mode === 'light' ? primary.black : secondary['900']}
				height={'15px'}
				width={'20px'}
			/>
		</Flex>
	);
};
