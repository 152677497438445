import { useAuth0 } from '@auth0/auth0-react';
import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import {
	hasTrainerRole,
	isNil,
	Loader,
	Maybe,
	MeTrainer,
	useClient,
	useObservable
} from '@/shared';
import { NEVER } from 'rxjs';
import { useToken } from '@/config';
import { useSelfTrainerRepository } from '@/shared/repository/trainer/use-self-trainer-repository';
import { useLoaderHandler } from '@/shared/utils/loader/use-loader-handler';

type TrainerContextProperties = {
	me: Maybe<MeTrainer>;
	loader: Loader;
};

export const trainerContext = createContext<TrainerContextProperties>({
	me: undefined,
	loader: {
		loading: false
	}
});

/**
 * @deprecated use useTrainer from shared/hooks
 */
export const useTrainer = () => {
	return useContext(trainerContext);
};

export const TrainerProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const { tokenReady } = useToken();
	const { me: client, loading: clientLoading } = useClient();
	const { getMe } = useSelfTrainerRepository();
	const { isAuthenticated, user } = useAuth0();

	const me$ = useMemo(() => {
		if (
			tokenReady &&
			isAuthenticated &&
			hasTrainerRole(user) &&
			!isNil(client) &&
			!clientLoading
		) {
			return getMe();
		}
		return NEVER;
	}, [client, clientLoading, getMe, isAuthenticated, tokenReady, user]);

	const { value: me, loader: observableLoader } = useObservable<MeTrainer>(me$);

	const loading = useLoaderHandler([observableLoader, { loading: clientLoading }]);

	const contextValue = useMemo<TrainerContextProperties>(
		() => ({
			me: me,
			loader: { loading }
		}),
		[loading, me]
	);

	return <trainerContext.Provider value={contextValue}>{children}</trainerContext.Provider>;
};
