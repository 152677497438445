import { FC } from 'react';
import { primary } from '@/ui/theme/components-overrides/colors';
import SvgCheckStroke from '@/assets/icons/generated/stroke/CheckStroke';
import Box from '@mui/material/Box';

type CheckBoxIconProps = {
	rootClassName?: string;
	checkIconContainerClassName?: string;
	checked?: boolean;
};

export const CheckBoxIcon: FC<CheckBoxIconProps> = ({
	rootClassName,
	checkIconContainerClassName,
	checked = false
}) => {
	return (
		<Box
			sx={{
				width: '20px',
				height: '20px',
				border: `1px solid ${primary['100']}`,
				borderRadius: '4px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
			className={rootClassName}>
			{checked && <SvgCheckStroke className={checkIconContainerClassName} />}
		</Box>
	);
};
