import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSnowboardFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="m20.469 11.766-3.242-2.422-2.579 1.21 2.032 1.524c.468.352.742.899.742 1.485 0 .117 0 .273-.04.39l-.859 3.945c-.078.391-.351.704-.703.86l3.555 1.289c.156.078.352.078.508.078.234 0 .43-.04.625-.117.156-.078.273-.078.39-.078.274 0 .977.195.977.898a.96.96 0 0 1-.547.86c-.469.234-.937.312-1.445.312-.39 0-.781-.04-1.172-.195L4.492 16.609C3.008 16.102 2.5 14.812 2.5 14.5c0-.273.195-.977.898-.977 1.016 0 .704.977 1.72 1.329l1.523.546a1.1 1.1 0 0 1-.43-.898c0-.508.351-.977.86-1.172l2.89-.976v-2.07c0-.938.547-1.798 1.406-2.227l1.602-.82-.625-.196c-.508-.156-.86-.508-1.094-.937l-1.133-2.266a1.068 1.068 0 0 1-.156-.547c0-.703.586-1.25 1.25-1.25.469 0 .898.234 1.133.664l1.015 2.031 2.5.86a6.24 6.24 0 0 1 1.758.898l4.375 3.281c.313.235.469.625.469 1.016 0 .899-.82 1.211-1.25 1.211a1.38 1.38 0 0 1-.742-.234Zm-5.625 2.03L12.46 12.04v.781c0 .82-.508 1.524-1.29 1.797l-3.32 1.094c-.078.039-.195.039-.312.039l6.64 2.422a1.38 1.38 0 0 1-.117-.547v-.234l.782-3.594Zm4.492-8.046c-1.016 0-1.875-.898-1.875-1.875 0-1.016.86-1.875 1.875-1.875 1.055 0 1.875.938 1.875 1.914 0 1.016-.82 1.836-1.875 1.836Z"
		/>
	</svg>
);
export default SvgSnowboardFilled;
