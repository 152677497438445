import { CalendarOfferResponse } from '@/api';
import { mapOffer } from '@/shared/repository/offer-mapper';
import { mapClient } from '@/shared/repository/client-mapper';
import { CalendarOffer } from '@/shared/model/CalendarOffer';

export const mapCalendarOffer = (response: CalendarOfferResponse): CalendarOffer => {
	return {
		id: response.offer.id,
		offer: mapOffer(response.offer),
		clients: response.clients.map((client) => mapClient(client))
	};
};
