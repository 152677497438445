import { FC } from 'react';
import { useLayout } from '@/config';
import { ApplicationLayout } from '@/config/layout/ApplicationLayout';
import { NonApplicationContextLayout } from '@/config/layout/NonApplicationContextLayout';

export const Layout: FC = () => {
	const type = useLayout();

	if (type === 'non-application-context' || type === 'sign-up') {
		return <NonApplicationContextLayout variant={type} />;
	}
	return <ApplicationLayout />;
};
