import { menu, MenuItem, Path } from '@/config';

export const trainerTabs: MenuItem[] = menu.items.filter((item) => item.appType === 'pro');
export const userTabs: MenuItem[] = menu.items.filter((tab) => tab.appType === 'regular');
export const getTabConfig = (path: Path): MenuItem => {
	const found = menu.items.find((tab) => tab.route.path === path);

	if (!found) {
		throw new Error(`There is no tab with path ${path}`);
	}

	return found;
};
