import { Maybe } from '@/shared';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { MenuItem, trainerTabs, userTabs } from '@/config';

export type Path =
	| ''
	| 'pro'
	| 'listing'
	| 'me/profile'
	| 'me/services'
	| 'me/saved-trainers'
	| 'me/settings'
	| 'me/trainer'
	| 'me/trainer/profile'
	| 'me/trainer/dashboard'
	| 'me/trainer/calendar'
	| 'me/trainer/settlements'
	| 'me/trainer/settings'
	| 'notifications'
	| `trainer/${string}`
	| `trainer/${string}/sign-up`
	| `checkout/${string}`
	| 'cashout'
	| 'not-found'
	| 'privacy-policy'
	| 'faq'
	| 'terms-and-conditions'
	| '*';

export type RouteConfig = {
	path: Path;
	subNavbarTabs?: MenuItem[];
	isPro?: boolean;
	secured?: boolean;
};

export type Routes = {
	[key: string]: RouteConfig;
};

export const routes: Routes = {
	landingRoute: { path: '' },
	landingProRoute: { path: 'pro', isPro: true },
	listingRoute: { path: 'listing' },
	myProfileRoute: { path: 'me/profile', secured: true },
	myServicesRoute: { path: 'me/services', subNavbarTabs: userTabs, secured: true },
	mySavedTrainersRoute: { path: 'me/saved-trainers', subNavbarTabs: userTabs, secured: true },
	mySettingsRoute: { path: 'me/settings', subNavbarTabs: userTabs, secured: true },
	myTrainerProfileRoute: {
		path: 'me/trainer/profile',
		subNavbarTabs: trainerTabs,
		isPro: true,
		secured: true
	},
	myTrainerDashboardRoute: {
		path: 'me/trainer/dashboard',
		subNavbarTabs: trainerTabs,
		isPro: true,
		secured: true
	},
	myTrainerCalendarRoute: {
		path: 'me/trainer/calendar',
		subNavbarTabs: trainerTabs,
		isPro: true,
		secured: true
	},
	myTrainerSettlementsRoute: {
		path: 'me/trainer/settlements',
		subNavbarTabs: trainerTabs,
		isPro: true,
		secured: true
	},
	myTrainerSettingsRoute: {
		path: 'me/trainer/settings',
		subNavbarTabs: trainerTabs,
		isPro: true,
		secured: true
	},
	notificationsRoute: {
		path: 'notifications'
	},
	trainerRoute: { path: 'trainer/:id' },
	trainerSignUpRoute: { path: 'trainer/:id/sign-up', secured: true, isPro: true },
	trainerPaymentResultRoute: { path: 'me/trainer', secured: true, isPro: true },
	offerCheckoutRoute: { path: 'checkout/offer/:offerId', secured: true },
	packageCheckoutRoute: { path: 'checkout/package/:trainerId/variant/:variantId', secured: true },
	cashoutRoute: { path: 'cashout', secured: true, isPro: true },
	privacyPolicyRoute: { path: 'privacy-policy' },
	faqRoute: { path: 'faq' },
	termsAndConditionsRoute: { path: 'terms-and-conditions' },
	notFoundRoute: { path: 'not-found' },
	wildcardRoute: { path: '*' }
};

type RouteConfigProps = {
	pathConfig: Maybe<RouteConfig>;
};

export const useRouteConfig = (): RouteConfigProps => {
	const location = useLocation();
	const [pathConfig, setPathConfig] = useState<RouteConfig>();

	useEffect(() => {
		const routeConfig = Object.values(routes).find((value) => {
			const cleanedPathName = location.pathname.replace('/', '');
			return cleanedPathName === value.path;
		});

		setPathConfig(routeConfig);
	}, [location, location.pathname]);

	return {
		pathConfig: pathConfig
	};
};
