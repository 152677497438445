import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';

type MuiAutocompleteOverrides = {
	defaultProps?: ComponentsProps['MuiAutocomplete'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiAutocomplete'];
	variants?: ComponentsVariants['MuiAutocomplete'];
};

export const muiAutocomplete: MuiAutocompleteOverrides = {
	styleOverrides: {
		root: {
			width: '100%',
			'& .MuiOutlinedInput-root': {
				paddingTop: 0,
				paddingBottom: 0
			}
		},
		input: {
			cursor: 'pointer'
		}
	}
};
