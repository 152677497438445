import {
	createContext,
	FC,
	forwardRef,
	PropsWithChildren,
	ReactElement,
	ReactNode,
	Ref,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { DialogProps, Menu, MenuItem, Slide } from '@mui/material';
import { useDialog } from '@/ui/dialog/dialog-context';
import { isNil, useScreenType } from '@/shared';
import { ContextMenu } from '@/ui/context-menu-context/ContextMenu';
import { TransitionProps } from '@mui/material/transitions/transition';
import { Each } from '@/ui/each/Each';
import Typography from '@mui/material/Typography';

export type ContextMenuOption = {
	content: string | ReactNode;
	onClick: () => void;
};

export type ContextMenuOptions = {
	options: ContextMenuOption[];
	menuAnchor?: HTMLElement;
	title?: string | ReactNode;
	body?: string | ReactNode;
	dialogProps?: Omit<DialogProps, 'open'>;
};

type ContextMenuProperties = {
	open: (opts: ContextMenuOptions) => void;
	close: () => void;
};

const contextMenuContext = createContext<ContextMenuProperties>({
	open: () => {},
	close: () => {}
});

const Transition = forwardRef(
	(
		props: TransitionProps & {
			children: ReactElement;
		},
		ref: Ref<unknown>
	) => <Slide direction="up" ref={ref} {...props} />
);

Transition.displayName = 'Transition';

export const useContextMenu = (): ContextMenuProperties => useContext(contextMenuContext);

export const ContextMenuProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [menuOpts, setMenuOpts] = useState<ContextMenuOptions>({
		options: []
	});
	const screen = useScreenType();
	const { open: openDialog, close: closeDialog } = useDialog();

	useEffect(() => {
		if (screen === 'desktop' && isOpen) {
			closeDialog();
			if (isNil(menuOpts.menuAnchor)) {
				setIsOpen(false);
				setMenuOpts({ options: [] });
			}
		}
	}, [closeDialog, isOpen, menuOpts.menuAnchor, screen]);

	const openMenu = useCallback(
		(opts: ContextMenuOptions) => {
			if (opts.options.length === 0) {
				return;
			}

			setMenuOpts(opts);
			setIsOpen(true);
			if (screen === 'mobile') {
				openDialog({
					component: (
						<ContextMenu
							title={opts.title}
							body={opts.body}
							options={opts.options}
							close={closeDialog}
						/>
					),
					dialogProps: {
						TransitionComponent: Transition,
						onClose: () => {
							setIsOpen(false);
							closeDialog();
						},
						sx: {
							'& .MuiDialog-paper': {
								borderStartStartRadius: '8px',
								borderStartEndRadius: '8px',
								minWidth: '100%',
								position: 'absolute',
								bottom: 0,
								margin: 0
							}
						},
						...opts.dialogProps
					}
				});
			}
		},
		[screen, openDialog, closeDialog]
	);

	const closeMenu = useCallback(() => {
		setIsOpen(false);
		if (screen === 'mobile') {
			closeDialog();
		}
	}, [closeDialog, screen]);

	const contextValue = useMemo<ContextMenuProperties>(
		() => ({
			open: openMenu,
			close: closeMenu
		}),
		[closeMenu, openMenu]
	);

	return (
		<contextMenuContext.Provider value={contextValue}>
			{children}
			{menuOpts.options.length > 0 && screen === 'desktop' && !isNil(menuOpts.menuAnchor) && (
				<Menu
					anchorEl={menuOpts.menuAnchor}
					open={isOpen}
					onClose={closeMenu}
					PaperProps={menuOpts.dialogProps?.PaperProps}>
					<Each
						of={menuOpts.options}
						render={(option) => (
							<MenuItem
								onClick={() => {
									option.onClick();
									closeMenu();
								}}>
								{typeof option.content === 'string' ? (
									<Typography variant={'p3'}>{option.content}</Typography>
								) : (
									option.content
								)}
							</MenuItem>
						)}
					/>
				</Menu>
			)}
		</contextMenuContext.Provider>
	);
};
