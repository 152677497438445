import { BoxProps, Switch } from '@mui/material';
import { primary } from '@/ui/theme/components-overrides/colors';
import { useAppType } from '@/config/app-type/appTypeContext';
import { ChangeEvent, FC } from 'react';
import { WorkoutlyLogoBlack } from '@/assets/icons/generated';
import { ProBadge } from '@/ui/app-switcher/ProBadge';
import { Flex } from '@/ui/flex/Flex';
import { useLayout } from '@/config';
import WorkoutlyLogoWhite from '@/assets/icons/generated/WorkoutlyLogoWhite';

type AppTypeSwitcherProps = BoxProps & {
	logoColor?: 'black' | 'white';
	onChangeType?: () => void;
};

export const AppTypeSwitcher: FC<AppTypeSwitcherProps> = ({
	logoColor = 'white',
	onChangeType = () => {},
	...boxProps
}) => {
	const { changeAppType, appType } = useAppType();
	const layout = useLayout();

	return (
		<Flex
			alignItems={'center'}
			gap={1.5}
			padding={1.5}
			borderRadius={'4px'}
			sx={{
				border: layout === 'landing' ? `1px solid ${primary.contrast}` : 'none',
				backgroundColor: layout === 'landing' ? primary.main : primary['50'],
				...boxProps.sx
			}}>
			<Switch
				checked={appType === 'pro'}
				onChange={(event: ChangeEvent<HTMLElement>, isPro: boolean) => {
					changeAppType(isPro ? 'pro' : 'regular');
					onChangeType();
				}}
				focusVisibleClassName=".Mui-focusVisible"
				disableRipple
				sx={{
					width: 42,
					height: 26,
					padding: 0,
					'& .MuiSwitch-switchBase': {
						top: '-14px',
						left: '-14px',
						padding: 0,
						margin: 2,
						transitionDuration: '300ms',
						'&.Mui-checked': {
							transform: 'translateX(16px)',
							color: primary.contrast,
							'& + .MuiSwitch-track': {
								backgroundColor: primary.black,
								opacity: 1,
								border: 0
							},
							'& .MuiSwitch-thumb': {
								border: 'none'
							}
						},
						'&.Mui-focusVisible .MuiSwitch-thumb': {
							color: primary['100'],
							border: `6px solid ${primary.contrast}`
						}
					},
					'& .MuiSwitch-thumb': {
						boxSizing: 'border-box',
						width: 22,
						height: 22,
						boxShadow: 'none'
					},
					'& .MuiSwitch-track': {
						borderRadius: '100px',
						border: `1px solid ${primary['200']}`,
						backgroundColor: primary['100'],
						opacity: 1,
						transition: 'background-color 500ms'
					}
				}}
			/>
			<Flex alignItems={'center'} gap={0.5}>
				{logoColor === 'black' ? (
					<WorkoutlyLogoBlack width={'70px'} height={'16px'} />
				) : (
					<WorkoutlyLogoWhite width={'70px'} height={'16px'} />
				)}
				<ProBadge />
			</Flex>
		</Flex>
	);
};
