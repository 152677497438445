import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFootballFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M8.914 10.945 12 8.758l3.047 2.187-1.172 3.555h-3.79l-1.17-3.555ZM11.961 2c5.547 0 10 4.57 10 10.04A9.953 9.953 0 0 1 12 22 9.97 9.97 0 0 1 2 12C2 6.492 6.453 2 11.96 2Zm6.289 14.102c.703-1.055 1.133-2.344 1.21-3.711l-2.733-1.914 1.093-3.165c-.82-1.015-1.914-1.796-3.164-2.304L12 7 9.305 5.008A7.48 7.48 0 0 0 6.14 7.312l1.093 3.165L4.5 12.39c.078 1.367.508 2.656 1.21 3.71l3.321-.038.977 3.203c.625.156 1.289.234 1.992.234.664 0 1.328-.078 1.953-.234l.977-3.203 3.32.039Z"
		/>
	</svg>
);
export default SvgFootballFilled;
