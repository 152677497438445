import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToTop = () => {
	const location = useLocation();

	const scrollToTop = useCallback(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'auto'
		});
	}, []);

	useEffect(() => {
		scrollToTop();
	}, [location, scrollToTop]);

	useEffect(() => {
		if ('scrollRestoration' in window.history) {
			window.history.scrollRestoration = 'manual';
		}

		scrollToTop();

		return () => {
			if ('scrollRestoration' in window.history) {
				window.history.scrollRestoration = 'auto';
			}
		};
	}, [scrollToTop]);

	return scrollToTop;
};
