import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEmsFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="m18.363 13.129-8.965 7.84c-.246.21-.527.281-.773.281-.247 0-.493-.07-.668-.21a1.092 1.092 0 0 1-.387-1.337l2.707-6.328H6.375c-.493 0-.915-.281-1.055-.703-.176-.457-.07-.95.281-1.266l9-7.84c.387-.351.95-.351 1.371-.035.457.282.598.844.387 1.336l-2.707 6.293h3.937c.457 0 .88.317 1.055.738.176.422.07.915-.281 1.23Z"
		/>
	</svg>
);
export default SvgEmsFilled;
