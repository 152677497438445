import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { primary } from '@/ui/theme/components-overrides/colors';
import SvgChevronDownStroke from '@/assets/icons/generated/stroke/ChevronDownStroke';

type MuiSelectOverrides = {
	defaultProps?: ComponentsProps['MuiSelect'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiSelect'];
	variants?: ComponentsVariants['MuiSelect'];
};

export const muiSelect: MuiSelectOverrides = {
	defaultProps: {
		IconComponent: SvgChevronDownStroke,
		size: 'medium'
	},
	variants: [
		{
			props: { size: 'large' },
			style: {
				'&.MuiInputBase-root': {
					height: '48px'
				},
				'& .MuiSelect-icon': {
					width: '24px',
					height: '24px'
				}
			}
		},
		{
			props: { size: 'medium' },
			style: {
				'&.MuiInputBase-root': {
					height: '40px'
				},
				'& .MuiSelect-icon': {
					width: '20px',
					height: '20px'
				}
			}
		},
		{
			props: { size: 'small' },
			style: {
				'&.MuiInputBase-root': {
					height: '32px'
				},
				'& .MuiSelect-icon': {
					width: '16px',
					height: '16px'
				}
			}
		}
	],
	styleOverrides: {
		select: {
			display: 'flex',
			alignItems: 'center'
		},
		icon: {
			color: primary[400],
			top: 'auto'
		}
	}
};
