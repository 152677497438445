import {
	Discipline,
	DisciplineOrTrainerAutocompleteItem,
	GoogleMapAutocompleteItem,
	isItemAutocompletePrediction,
	isItemDiscipline,
	isItemPlace,
	isItemTrainer,
	isItemTrainerNameQuery,
	Maybe,
	Trainer
} from '@/shared';
import { useCallback } from 'react';
import { ListingSearchQueryParamKey } from '@/config/routes/query-param/use-listing-search-query-param';
import { joinToQueryParams, useNavigate } from '@/config';

export type ListingSearchInputs = {
	disciplineOrTrainerItem: Maybe<DisciplineOrTrainerAutocompleteItem>;
	placeItem: Maybe<GoogleMapAutocompleteItem>;
};

type ListingSearchFunctions = {
	search: (inputs: ListingSearchInputs) => void;
};

const isTrainerPresentInInputs = (inputs: ListingSearchInputs): boolean =>
	isItemTrainer(inputs.disciplineOrTrainerItem);

const transformSearchInputs = (
	inputs: ListingSearchInputs
): Partial<Record<ListingSearchQueryParamKey, Maybe<string | Discipline>>> => {
	return {
		trainerNameQuery: isItemTrainerNameQuery(inputs.disciplineOrTrainerItem)
			? inputs.disciplineOrTrainerItem
			: undefined,
		discipline: isItemDiscipline(inputs.disciplineOrTrainerItem)
			? inputs.disciplineOrTrainerItem
			: undefined,
		placeId: isItemAutocompletePrediction(inputs.placeItem)
			? inputs.placeItem.place_id
			: isItemPlace(inputs.placeItem)
			? inputs.placeItem.externalId
			: undefined
	};
};

export const useListingSearch = (): ListingSearchFunctions => {
	const navigate = useNavigate();

	const navigateToTrainer = useCallback(
		(inputs: ListingSearchInputs) => {
			const trainer = inputs.disciplineOrTrainerItem as Trainer;
			navigate(`trainer/${trainer.id}`);
		},
		[navigate]
	);

	const navigateToListing = useCallback(
		(inputs: ListingSearchInputs) => {
			const queryParamsObject = transformSearchInputs(inputs);
			const params = joinToQueryParams(queryParamsObject);
			navigate({
				pathname: 'listing',
				search: params
			});
		},
		[navigate]
	);

	const searchCallback = useCallback(
		(inputs: ListingSearchInputs) => {
			if (isTrainerPresentInInputs(inputs)) {
				navigateToTrainer(inputs);
			} else {
				navigateToListing(inputs);
			}
		},
		[navigateToListing, navigateToTrainer]
	);

	return {
		search: searchCallback
	};
};
