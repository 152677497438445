import { Divider, List } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { MenuListNotAuthenticated } from '@/config/layout/navigation/helpers/mobile/MenuListNotAuthenticated';
import { MenuListAuthenticated } from '@/config/layout/navigation/helpers/mobile/MenuListAuthenticated';
import { Flex } from '@/ui/flex/Flex';
import Typography from '@mui/material/Typography';
import { useNavigate, useTranslation } from '@/config';
import { useDrawer } from '@/ui/drawer/drawer-context';

export const MenuList = () => {
	const { t } = useTranslation();
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const { closeAll: closeDrawers } = useDrawer();

	return (
		<List sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
			<Flex flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
				{isAuthenticated ? <MenuListAuthenticated /> : <MenuListNotAuthenticated />}
				<Flex flexDirection={'column'} gap={3} alignItems={'center'} mb={3}>
					<Divider sx={{ width: '100%' }} />
					<Typography
						onClick={() => {
							closeDrawers();
							navigate('terms-and-conditions');
						}}
						variant={'h7'}
						sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
						{t('shared.menu.terms-and-conditions')}
					</Typography>
					<Typography
						onClick={() => {
							closeDrawers();
							navigate('privacy-policy');
						}}
						variant={'h7'}
						sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
						{t('shared.menu.privacy-policy')}
					</Typography>
					<Typography
						onClick={() => {
							closeDrawers();
							navigate('faq');
						}}
						variant={'h7'}
						sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
						{t('shared.menu.frequency-asked-questions')}
					</Typography>
				</Flex>
			</Flex>
		</List>
	);
};
