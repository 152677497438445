import { FC } from 'react';
import { accent, primary } from '@/ui/theme/components-overrides/colors';
import { Flex } from '@/ui/flex/Flex';
import SvgChevronDownFilled from '@/assets/icons/generated/filled/ChevronDownFilled';
import SvgChevronDownStroke from '@/assets/icons/generated/stroke/ChevronDownStroke';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Theme } from '@mui/material';

type IconType = 'stroke' | 'filled';

type ChevronDownIconProps = {
	size: string;
	padding?: string;
	iconType?: IconType;
	sx?: SxProps<Theme>;
};

export const ChevronDownIcon: FC<ChevronDownIconProps> = ({
	size,
	padding = '2px',
	iconType = 'stroke',
	sx
}) => {
	return (
		<Flex
			alignItems={'center'}
			sx={{
				backgroundColor: primary.main,
				padding: padding,
				borderRadius: '100px',
				boxShadow: '0px 5px 20px 0px rgba(0, 0, 0, 0.10)',
				border: `1px solid ${accent}`,
				...sx
			}}>
			{iconType === 'stroke' ? (
				<SvgChevronDownStroke width={size} height={size} color={primary.contrast} />
			) : (
				<SvgChevronDownFilled width={size} height={size} color={primary.contrast} />
			)}
		</Flex>
	);
};
