import { Flex } from '@/ui/flex/Flex';
import { FC } from 'react';
import { primary } from '@/ui/theme/components-overrides/colors';
import Typography from '@mui/material/Typography';
import { useDrawer } from '@/ui/drawer/drawer-context';
import { joinToQueryParams, MenuItem, SubMenu, useNavigate, useTranslation } from '@/config';
import { List, ListItem, ListItemButton } from '@mui/material';
import { isNil } from '@/shared';
import SvgChevronLeftStroke from '@/assets/icons/generated/stroke/ChevronLeftStroke';

type SubMenuDrawerProps = {
	subMenu: SubMenu;
};

export const SubMenuDrawer: FC<SubMenuDrawerProps> = ({ subMenu }) => {
	const { t } = useTranslation();
	const { close: closeDrawer, closeAll: closeAllDrawer } = useDrawer();
	const navigate = useNavigate();

	const handleMenuItemClick = (item: MenuItem) => {
		closeAllDrawer();

		const queryString = !isNil(item.route.queryParams)
			? item.route.queryParams.map((param) => joinToQueryParams(param)).join('&')
			: '';

		navigate({
			pathname: item.route.path,
			search: queryString
		});
	};

	return (
		<Flex flexDirection={'column'} gap={2}>
			<Flex alignItems={'center'} gap={1} onClick={() => closeDrawer()}>
				<SvgChevronLeftStroke height={'16px'} width={'16px'} color={primary.black} />
				<Typography variant={'p2'}>{t('shared.back')}</Typography>
			</Flex>
			<List sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
				<Typography variant={'h4'}>{t(subMenu.title)}</Typography>
				{subMenu.items.map((item, index) => (
					<ListItem
						key={index}
						disableGutters={true}
						sx={{
							'&.MuiListItem-root': {
								paddingTop: 0,
								paddingBottom: 0
							}
						}}>
						<ListItemButton
							onClick={() => handleMenuItemClick(item)}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								'&.MuiListItemButton-root': {
									justifyContent: 'space-between',
									paddingTop: 0,
									paddingBottom: 0
								}
							}}
							alignItems={'flex-start'}
							disableGutters={true}>
							<Typography variant={'p2'}>{t(item.name)}</Typography>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Flex>
	);
};
