import { isNil, Maybe } from '@/shared';
import { GlobalState, Id, IdAware, StoreSelector } from '@/shared/store/store-common-props';
import { useCallback, useEffect } from 'react';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';

const subject = new BehaviorSubject<GlobalState>({});

export const useStore = <T extends IdAware>(selector: StoreSelector, initialState?: Array<T>) => {
	const initiateSelectorElements = useCallback((storeSelector: StoreSelector) => {
		const currentValue = subject.getValue();
		subject.next({
			...currentValue,
			[storeSelector]: {
				elements: []
			}
		});
	}, []);

	const createGlobalStateWithNewSelectorElements = useCallback(
		(newElements: Array<T>, storeSelector: StoreSelector): GlobalState => {
			const { [storeSelector]: selectorElementsObject } = subject.getValue();
			if (isNil(selectorElementsObject)) {
				initiateSelectorElements(storeSelector);
			}
			const {
				[storeSelector]: { elements: selectorElements }
			} = subject.getValue();
			const newElementsIds = newElements.map((el) => el.id);
			const commonElementIds = selectorElements
				.filter((el) => newElementsIds.includes(el.id))
				.map((el) => el.id);
			const selectorStateWithoutNewElements = selectorElements.filter(
				(el) => !commonElementIds.includes(el.id)
			);
			return {
				...subject.getValue(),
				[storeSelector]: {
					elements: [...selectorStateWithoutNewElements, ...newElements]
				}
			};
		},
		[initiateSelectorElements]
	);

	useEffect(() => {
		if (!isNil(initialState)) {
			const newState = createGlobalStateWithNewSelectorElements(initialState, selector);
			subject.next(newState);
		}
	}, [createGlobalStateWithNewSelectorElements, initialState, selector]);

	const setState = useCallback(
		(setStateAction: Array<T> | ((prevState: Array<T>) => Array<T>)) => {
			const currentGlobalState = subject.getValue();
			if (typeof setStateAction === 'function') {
				const currentSelectorState = subject.getValue()[selector]?.elements || [];
				subject.next({
					...currentGlobalState,
					[selector]: {
						elements: setStateAction(currentSelectorState as Array<T>)
					}
				});
			} else {
				subject.next({
					...currentGlobalState,
					[selector]: {
						elements: setStateAction
					}
				});
			}
		},
		[selector]
	);

	const getState = useCallback((): Observable<Maybe<Array<T>>> => {
		return subject.asObservable().pipe(
			map((state) => {
				if (isNil(state[selector])) {
					return null;
				}
				return state[selector].elements as Array<T>;
			}),
			distinctUntilChanged()
		);
	}, [selector]);

	const getElement = useCallback(
		(id: Id): Observable<Maybe<T>> =>
			getState().pipe(map((state) => state?.find((el) => el.id === id) as Maybe<T>)),
		[getState]
	);

	const addElements = useCallback(
		(elements: Array<T>): void => {
			const newState = createGlobalStateWithNewSelectorElements(elements, selector);
			subject.next(newState);
		},
		[createGlobalStateWithNewSelectorElements, selector]
	);

	const addElement = useCallback(
		(element: T): void => {
			addElements([element]);
		},
		[addElements]
	);

	const replaceElement = useCallback(
		(id: Id, element: T) => {
			const currentState = subject.getValue();
			const currentElements = currentState[selector]?.elements || [element];
			const newElements = currentElements.map((el) => (el.id === id ? element : el)) as T[];
			setState(newElements);
		},
		[selector, setState]
	);

	const deleteElement = useCallback(
		(id: Id) => {
			const newState = (subject.getValue()[selector]?.elements || []).filter(
				(el) => el.id !== id
			) as T[];
			setState(newState);
		},
		[selector, setState]
	);

	return {
		getState,
		addElement,
		addElements,
		setState,
		getElement,
		replaceElement,
		deleteElement
	};
};
