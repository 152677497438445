import { FC, HTMLAttributes } from 'react';
import parse from 'autosuggest-highlight/parse';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
	GoogleMapAutocompleteItem,
	isItemAutocompletePrediction,
	isItemCoordinates,
	isItemPlace
} from '@/shared';
import { IconButton } from '@/ui/icon-button/IconButton';
import { primary } from '@/ui/theme/components-overrides/colors';
import SvgPinStroke from '@/assets/icons/generated/stroke/PinStroke';
import PredictionSubstring = google.maps.places.PredictionSubstring;

type GoogleMapAutocompleteOptionProps = {
	item: GoogleMapAutocompleteItem;
	htmlProps: HTMLAttributes<HTMLLIElement>;
};

export const GoogleMapAutocompleteOption: FC<GoogleMapAutocompleteOptionProps> = ({
	item,
	htmlProps
}) => {
	if (isItemPlace(item) || isItemCoordinates(item)) {
		return null;
	}
	const matches = isItemAutocompletePrediction(item)
		? item.structured_formatting.main_text_matched_substrings
		: [];

	const parts = isItemAutocompletePrediction(item)
		? parse(
				item.structured_formatting.main_text,
				matches.map((match: PredictionSubstring) => [
					match.offset,
					match.offset + match.length
				])
		  )
		: [
				{
					text: item,
					highlight: false
				}
		  ];
	return (
		<li {...htmlProps}>
			<Grid container alignItems="center">
				<Grid item sx={{ display: 'flex', width: 44 }}>
					<IconButton variant={'secondary'}>
						<SvgPinStroke width={'16px'} height={'16px'} color={primary.main} />
					</IconButton>
				</Grid>
				<Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
					{parts.map((part, index) => (
						<Box
							key={index}
							component="span"
							sx={{
								fontWeight: part.highlight ? 'bold' : 'regular'
							}}>
							{part.text}
						</Box>
					))}
					<Typography variant="body2" color="text.secondary">
						{isItemAutocompletePrediction(item)
							? item.structured_formatting.secondary_text
							: item}
					</Typography>
				</Grid>
			</Grid>
		</li>
	);
};
