import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHorseRidingFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 60 60"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M58.125 0H38.996c-1.035 0-1.875.854-1.875 1.908v8.259c0 1.054.84 1.908 1.875 1.908h1.573c2.203 6.054 3.504 13.13 3.504 16.783 0 7.897-6.313 14.32-14.073 14.32-7.76 0-14.073-6.423-14.073-14.32 0-3.655 1.3-10.73 3.504-16.783h1.572c1.037 0 1.875-.854 1.875-1.908v-8.26C22.878.855 22.04 0 21.003 0H1.875C.84 0 0 .854 0 1.908v8.259c0 1.054.84 1.908 1.875 1.908h1.968C1.96 18.367.786 25.27.786 30.274.786 46.664 13.891 60 30 60c16.109 0 29.214-13.335 29.214-29.726 0-5.004-1.175-11.908-3.057-18.2h1.968c1.036 0 1.875-.853 1.875-1.907v-8.26C60 .855 59.16 0 58.125 0Zm-49.4 34.025c-.944 0-1.709-.779-1.709-1.739 0-.96.765-1.738 1.709-1.738.943 0 1.708.779 1.708 1.738 0 .96-.765 1.74-1.708 1.74Zm.914-13.474c-.944 0-1.709-.78-1.709-1.74 0-.959.765-1.738 1.709-1.738.943 0 1.708.779 1.708 1.739 0 .96-.765 1.738-1.708 1.738Zm4.393 25.17c-.943 0-1.709-.78-1.709-1.739 0-.96.766-1.739 1.709-1.739.943 0 1.709.779 1.709 1.739 0 .96-.766 1.739-1.709 1.739Zm36.33-28.648c.943 0 1.708.779 1.708 1.739 0 .96-.765 1.738-1.709 1.738-.943 0-1.708-.778-1.708-1.738s.765-1.739 1.708-1.739ZM22.195 51.025c0-.96.766-1.74 1.71-1.74.942 0 1.708.78 1.708 1.74s-.766 1.738-1.709 1.738c-.943 0-1.709-.779-1.709-1.738Zm13.899 1.738c-.943 0-1.709-.779-1.709-1.738 0-.96.766-1.74 1.709-1.74.943 0 1.709.78 1.709 1.74s-.766 1.738-1.71 1.738Zm9.873-7.042c-.943 0-1.709-.78-1.709-1.739 0-.96.766-1.739 1.709-1.739.943 0 1.709.779 1.709 1.739 0 .96-.766 1.739-1.709 1.739Zm5.307-11.696c-.943 0-1.708-.779-1.708-1.739 0-.96.765-1.738 1.708-1.738.944 0 1.709.779 1.709 1.738 0 .96-.765 1.74-1.709 1.74Z"
		/>
	</svg>
);
export default SvgHorseRidingFilled;
