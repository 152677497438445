import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';

type MuiToolbarOverrides = {
	defaultProps?: ComponentsProps['MuiToolbar'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiToolbar'];
	variants?: ComponentsVariants['MuiToolbar'];
};

export const muiToolbar: MuiToolbarOverrides = {
	defaultProps: {
		variant: 'dense'
	}
};
