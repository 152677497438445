import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMartialArtsFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M7 12.5V8.219L5.562 10.78l-.062.25V15.5c0 .563-.469 1-1 1h-1c-.563 0-1-.438-1-1v-4.469c0-.594.125-1.187.438-1.719l2.218-3.968C5.625 4.53 6.5 4 7.438 4H10l2.156 4.344-1.625 4.156H7Zm8.719 3.688-.438-.688H18v2c0 1.125-.906 2-2 2H9c-1.125 0-2-.875-2-2v-2h2.688l-.438.688c-.188.25-.25.53-.25.812 0 .844.656 1.5 1.5 1.5.5 0 .938-.25 1.219-.656l.781-1.125.75 1.125c.281.437.719.656 1.25.656.813 0 1.469-.656 1.469-1.5 0-.281-.063-.563-.25-.813ZM7 14.5v-1h11v1h-4.594l1.5 2.25c.063.063.063.188.063.281a.48.48 0 0 1-.469.469c-.188 0-.344-.063-.438-.219L12.5 14.906l-1.594 2.375a.455.455 0 0 1-.406.219c-.281 0-.5-.219-.5-.469 0-.093 0-.218.063-.281l1.5-2.25H7Zm15.031-5.188c.313.532.469 1.126.469 1.72V15.5c0 .563-.469 1-1 1h-1c-.563 0-1-.438-1-1v-4.469l-.094-.25L18 8.22V12.5h-6.406L14.969 4h2.562c.938 0 1.813.531 2.282 1.344l2.218 3.968Z"
		/>
	</svg>
);
export default SvgMartialArtsFilled;
