import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { red } from '@mui/material/colors';

type MuiFormControlLabelOverrides = {
	defaultProps?: ComponentsProps['MuiFormControlLabel'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiFormControlLabel'];
	variants?: ComponentsVariants['MuiFormControlLabel'];
};

export const muiFormControlLabel: MuiFormControlLabelOverrides = {
	styleOverrides: {
		asterisk: {
			color: red['600']
		},
		root: {
			gap: '8px',
			margin: 0
		}
	}
};
