import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCrossStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 20 20"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M17.29 17.29c-.282.28-.798.28-1.08 0l-6.187-6.235-6.234 6.234c-.281.281-.797.281-1.078 0s-.281-.797 0-1.078l6.234-6.234-6.234-6.188c-.281-.281-.281-.797 0-1.078s.797-.281 1.078 0l6.234 6.234 6.188-6.234c.281-.281.797-.281 1.078 0s.281.797 0 1.078l-6.234 6.188 6.234 6.234c.281.281.281.797 0 1.078Z"
		/>
	</svg>
);
export default SvgCrossStroke;
