import { Flex } from '@/ui/flex/Flex';
import { DisciplineOrTrainerAutocomplete } from '@/ui/autocomplete/discipline-or-trainer/DisciplineOrTrainerAutocomplete';
import { GoogleMapAutocomplete } from '@/ui/autocomplete/google-map/GoogleMapAutocomplete';
import { IconButton } from '@/ui/icon-button/IconButton';
import SvgSearchStroke from '@/assets/icons/generated/stroke/SearchStroke';
import { primary } from '@/ui/theme/components-overrides/colors';
import { useTranslation } from '@/config';
import { Controller, useForm } from 'react-hook-form';
import {
	ListingSearchInputs,
	useListingSearch
} from '@/shared/utils/listing-search/use-listing-search';
import { FC, useState } from 'react';
import { useScreenType } from '@/shared';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';

type Position = 'default' | 'landing';

type SearchBarProps = {
	onSearch?: () => void;
	position?: Position;
};

export const SearchBar: FC<SearchBarProps> = ({ onSearch = () => {}, position = 'default' }) => {
	const { t } = useTranslation();
	const screenType = useScreenType();

	const [placePromptState, setPlacePromptState] = useState(false);
	const [disciplineOrTrainerPromptState, setDisciplineOrTrainerPromptState] = useState(false);

	const { control, handleSubmit: handleFormSubmit } = useForm<ListingSearchInputs>({
		mode: 'onSubmit'
	});

	const { search } = useListingSearch();

	const searchButton = (
		<>
			{position === 'default' ? (
				<>
					{screenType === 'desktop' ? (
						<IconButton
							type={'submit'}
							size={'large'}
							variant={'secondary'}
							sx={{
								padding: '12px',
								borderRadius: '4px'
							}}>
							<SvgSearchStroke width={'24px'} height={'24px'} color={primary.black} />
						</IconButton>
					) : (
						<Button size={'large'} type={'submit'} onClick={onSearch}>
							<Typography variant={'h6'}>{t('shared.search')}</Typography>
						</Button>
					)}
				</>
			) : (
				<Flex>
					<Button
						sx={{ width: '100%' }}
						size={'large'}
						type={'submit'}
						onClick={onSearch}>
						<Typography variant={'h6'}>{t('shared.search')}</Typography>
					</Button>
				</Flex>
			)}
		</>
	);

	return (
		<form
			onSubmit={handleFormSubmit((inputs) => search(inputs))}
			style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
			<Flex
				flexDirection={{ xs: 'column', md: 'row' }}
				gap={{ xs: 2, md: 0.5 }}
				width={'100%'}
				maxWidth={position === 'landing' ? '100%' : '550px'}>
				<Controller
					control={control}
					name={'disciplineOrTrainerItem'}
					render={({ field: { onChange: onFieldChange, value } }) => {
						return (
							<Flex
								width={'100%'}
								maxWidth={{
									xs: '100%',
									md: position === 'landing' ? '100%' : '250px'
								}}>
								<DisciplineOrTrainerAutocomplete
									value={value}
									promptOpen={disciplineOrTrainerPromptState}
									onPromptStateChange={(val) => {
										setDisciplineOrTrainerPromptState(val);
										if (val && placePromptState) {
											setPlacePromptState(false);
										}
									}}
									id={'navbar-DisciplineOrTrainerAutocomplete-id'}
									size={'large'}
									onChange={onFieldChange}
									paperProps={{
										sx: {
											width: {
												xs: '100%',
												md: position === 'landing' ? '618px' : '500px'
											}
										}
									}}
								/>
							</Flex>
						);
					}}
				/>
				<Controller
					control={control}
					name={'placeItem'}
					render={({ field: { onChange: onFieldChange } }) => {
						return (
							<Flex
								width={'100%'}
								maxWidth={{
									xs: '100%',
									md: position === 'landing' ? '100%' : '250px'
								}}>
								<GoogleMapAutocomplete
									id={'navbar-googleMapAutocomplete-id'}
									size={'large'}
									inputPlaceholder={t(
										'shared.component.NavbarContent.google-map-autocomplete-placeholder'
									)}
									onChange={onFieldChange}
									paperProps={{
										sx: {
											width: {
												xs: '100%',
												md: position === 'landing' ? '618px' : '500px'
											},
											marginLeft: {
												xs: '0',
												md: position === 'landing' ? '-310px' : '-250px'
											}
										}
									}}
								/>
							</Flex>
						);
					}}
				/>
				{searchButton}
			</Flex>
		</form>
	);
};
