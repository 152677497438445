import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';

declare module '@mui/material/TextField' {
	interface TextFieldPropsSizeOverrides {
		large: true;
	}
}

type MuiTextFieldOverrides = {
	defaultProps?: ComponentsProps['MuiTextField'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiTextField'];
	variants?: ComponentsVariants['MuiTextField'];
};

export const muiTextField: MuiTextFieldOverrides = {
	variants: [
		{
			props: { size: 'large' },
			style: {
				'& .MuiInputBase-root': {
					height: '48px'
				}
			}
		},
		{
			props: { size: 'medium' },
			style: {
				'& .MuiInputBase-root': {
					height: '40px'
				}
			}
		},
		{
			props: { size: 'small' },
			style: {
				'& .MuiInputBase-root': {
					height: '32px'
				}
			}
		}
	]
};
