import { Flex } from '@/ui/flex/Flex';
import SvgWLogoBlack from '@/assets/icons/generated/WLogoBlack';
import SvgWLogoWhite from '@/assets/icons/generated/WLogoWhite';
import { ProBadge } from '@/ui/app-switcher/ProBadge';
import { useAppType } from '@/config/app-type/appTypeContext';
import { useLayout, useNavigate } from '@/config';
import { FC } from 'react';
import { useDrawer } from '@/ui/drawer/drawer-context';
import { useAuth0 } from '@auth0/auth0-react';

type MobileWorkoutlyIconProps = {
	iconColor?: 'white' | 'black';
};

export const MobileWorkoutlyIcon: FC<MobileWorkoutlyIconProps> = ({ iconColor = 'white' }) => {
	const { appType } = useAppType();
	const layoutType = useLayout();
	const navigate = useNavigate();
	const { closeAll: closeAllDrawers } = useDrawer();
	const { isAuthenticated } = useAuth0();

	const handleLogoClick = () => {
		closeAllDrawers();
		const path = isAuthenticated ? (appType === 'pro' ? 'me/trainer/dashboard' : '') : '';
		navigate(path);
	};

	const renderLogo = () => {
		if (iconColor === 'black' || layoutType !== 'landing') {
			return <SvgWLogoBlack width="40px" height="40px" />;
		}
		return <SvgWLogoWhite width="40px" height="40px" />;
	};

	return (
		<Flex
			onClick={handleLogoClick}
			alignItems="center"
			gap={1}
			width="200px"
			sx={{ cursor: 'pointer' }}>
			{renderLogo()}
			{appType === 'pro' && <ProBadge mode="dark" />}
		</Flex>
	);
};
