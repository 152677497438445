import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVolleyballFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M10.313 6.14c-1.758.743-5.743 3.008-7.54 8.282C2.578 13.64 2.5 12.859 2.5 12c0-3.828 2.148-7.148 5.352-8.828 1.093.937 1.875 1.992 2.46 2.969Zm7.07 6.876a11.938 11.938 0 0 1-4.258-1.329c0-1.328-.352-5.546-4.023-9.062A9.724 9.724 0 0 1 12.5 2c.195 0 .43.04.625.04 3.71 3.358 4.375 7.187 4.375 9.491 0 .625-.078 1.094-.117 1.485Zm-5.508-1.368c-1.133.665-4.61 3.086-5.86 8.008a11.095 11.095 0 0 1-2.5-3.203c1.329-6.172 5.743-8.515 7.344-9.18.86 1.954.977 3.672 1.016 4.375Zm3.36-9.218C19.414 3.602 22.5 7.469 22.5 12c0 .156-.04.313-.04.43a11.623 11.623 0 0 1-3.788.625h-.04c.04-.39.118-.899.118-1.485 0-2.304-.586-5.86-3.516-9.14ZM12.46 12.742c.82.469 3.086 1.563 6.21 1.563 1.134 0 2.345-.117 3.634-.508-.235 1.367-.742 2.656-1.524 3.75-1.523.508-2.93.703-4.218.703-3.75 0-6.368-1.68-7.383-2.46 1.25-1.72 2.695-2.696 3.28-3.048Zm-3.984 4.102c1.25.898 4.101 2.656 8.125 2.656.859 0 1.835-.078 2.812-.273A9.945 9.945 0 0 1 12.5 22c-1.992 0-3.828-.547-5.39-1.523a11.362 11.362 0 0 1 1.367-3.633Z"
		/>
	</svg>
);
export default SvgVolleyballFilled;
