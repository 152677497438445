type ZIndexComponent =
	| 'drawer'
	| 'dialog'
	| 'navbar'
	| 'calendarTimeIndicator'
	| 'tooltip'
	| 'fabButton'
	| 'focusable';
export const zIndex: Record<ZIndexComponent, number> = {
	tooltip: 102,
	drawer: 100,
	dialog: 99,
	navbar: 98,
	focusable: 97,
	fabButton: 96,
	calendarTimeIndicator: 95
};
