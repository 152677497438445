import { User } from '@auth0/auth0-react';
import { Maybe, Role } from '@/shared';

export const userMetaRolesField = 'https://workoutly.pl/roles';
export const userMetaDetailsField = 'https://workoutly.pl/details';
export const userMetaDetailsPhoneNumberFieldKey = 'PHONE_NUMBER';

export const hasTrainerRole = (user: Maybe<User>): boolean => {
	return (user?.[userMetaRolesField] as Role[])?.includes('ROLE_TRAINER');
};
