import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBadmintonStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M20.902 9.485c.896 0 1.559.702 1.598 1.56l-.039 1.364c0 .585-.35 1.131-.897 1.404l-9.473 4.405-2.495 2.534a4.291 4.291 0 0 1-2.963 1.209c-2.223 0-4.133-1.793-4.133-4.133 0-1.052.429-2.105 1.248-2.923l2.495-2.496 4.405-9.473c.273-.546.819-.936 1.404-.936h1.364c.897 0 1.56.702 1.677 1.56v1.559h2.768c.818 0 1.52.663 1.52 1.56v2.806h1.52ZM17.47 6.99h-2.417l-1.131 3.548 3.548-1.13V6.99ZM5.853 15.45l3.197 3.197.818-.819-3.235-3.196-.78.818Zm6.433-11.579-1.599 3.392 2.417-1.442V3.87h-.818Zm.428 4.367-3.391 1.988-1.404 3.002 1.014 1.014 2.729-2.73 1.052-3.274ZM6.633 20.09c.545 0 1.052-.156 1.52-.546l-3.197-3.197c-.39.429-.546.975-.546 1.52 0 1.56 1.365 2.223 2.223 2.223Zm4.6-3.51 3.04-1.403 1.95-3.391-3.236 1.052-2.768 2.73 1.014 1.013Zm9.357-4.366h-.04v-.818h-1.949l-1.364 2.378 3.353-1.56Z"
		/>
	</svg>
);
export default SvgBadmintonStroke;
