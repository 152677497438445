import { TrainerBalanceResponse } from '@/api';
import { TrainerBalance } from '@/shared';

export const mapBalance = (response: TrainerBalanceResponse): TrainerBalance => ({
	available: response.available.map((available) => ({
		value: parseFloat(available.value),
		currencyCode: available.currencyCode
	})),
	pending: response.pending.map((available) => ({
		value: parseFloat(available.value),
		currencyCode: available.currencyCode
	}))
});
