import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSkiingStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M18.842 5.782a1.872 1.872 0 0 1-1.871-1.872c0-1.052.819-1.91 1.871-1.91 1.014 0 1.872.858 1.872 1.91 0 1.014-.858 1.872-1.872 1.872ZM6.64 5.587 5.626 4.69c-.04-.039-.078-.078-.078-.156 0-.039.039-.078.078-.117.039-.078.078-.078.156-.078h.039l1.325.273.468-.858c.507.273.78.82.78 1.365v.039L11.24 6.56l2.885-1.17c.273-.116.546-.155.819-.155.194 0 1.598.039 2.066 1.481l.701 2.106 2.145 1.091c.35.156.546.507.546.858a.949.949 0 0 1-.936.936.786.786 0 0 1-.429-.117l-2.261-1.131a1.472 1.472 0 0 1-.78-.897l-.546-1.637-2.378.936-5.185-2.574c-.273.234-.624.351-.975.351-.234 0-.468-.078-.701-.156l.428-.896Zm15.283 14.737c0 .662-1.17 1.637-2.69 1.676a3.31 3.31 0 0 1-1.482-.35L2.468 13.773c-.312-.156-.468-.507-.468-.819 0-.507.35-.935.897-.935.156 0 .312 0 .429.078l7.992 4.132 1.95-2.846-3.12-3.119a2.122 2.122 0 0 1-.624-1.52V8.51l3.002 1.481 2.184 2.222c.311.312.467.702.467 1.131 0 .273-.078.585-.272.858l-1.91 2.885 5.574 2.885c.195.117.39.156.624.156 1.053 0 1.053-.741 1.793-.741.507 0 .936.39.936.936Z"
		/>
	</svg>
);
export default SvgSkiingStroke;
