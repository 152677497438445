import { forwardRef, ReactElement } from 'react';
import Grow from '@mui/material/Grow';
import { TransitionProps } from '@mui/material/transitions/transition';

export const Transition = forwardRef<
	unknown,
	TransitionProps & { children: ReactElement<HTMLDivElement, string> }
>((props, ref) => <Grow in={true} mountOnEnter unmountOnExit ref={ref} {...props} />);

Transition.displayName = 'Transition';
