import {
	Achievement,
	Certificate,
	CurrencyCode,
	DisciplineDetails,
	LanguageCode,
	Location,
	MeTrainer,
	OfferTemplate,
	Review,
	Sex,
	Study,
	Trainer
} from '@/shared';
import {
	type AchievementResponse,
	CertificateResponse,
	type DisciplineDetailsResponse,
	type GetMeResponse,
	LocationDTO,
	OfferTemplateResponse,
	type StudyResponse,
	TrainerResponse,
	TrainerReviewResponse
} from '@/api';
import { mapContactDetails } from '@/shared/repository/client-mapper';
import { toLocation } from '@/shared/repository/common-mappers';
import { mapOffer } from '@/shared/repository/offer-mapper';
import { mapPackage } from '@/shared/repository/package-mapper';

const mapLocations = (locationsResponses: LocationDTO[]): Location[] => {
	return locationsResponses.map(toLocation);
};

const mapOfferTemplate = (metaDataResponses: OfferTemplateResponse[]): OfferTemplate[] => {
	return metaDataResponses.map((responseMetadata) => {
		return {
			category: { name: responseMetadata.category },
			locations: mapLocations(responseMetadata.locations),
			price: {
				value: parseFloat(responseMetadata.price.value),
				currencyCode: responseMetadata.price.currencyCode as CurrencyCode
			},
			type: responseMetadata.type,
			maxNumberOfPeople: responseMetadata.maxNumberOfPeople
		};
	});
};

const mapAchievements = (achievementsResponses: AchievementResponse[]): Achievement[] => {
	return achievementsResponses.map((responseAchievement) => {
		return {
			id: responseAchievement.id,
			title: responseAchievement.title,
			date: new Date(responseAchievement.date)
		};
	});
};

const mapStudies = (studyResponses: StudyResponse[]): Study[] => {
	return studyResponses.map((studyResponse) => {
		return {
			id: studyResponse.id,
			title: studyResponse.title,
			date: new Date(studyResponse.date),
			faculty: studyResponse.faculty
		};
	});
};

const mapCertificates = (certificatesResponses: CertificateResponse[]): Certificate[] => {
	return certificatesResponses.map((certificatesResponse) => {
		return {
			id: certificatesResponse.id,
			title: certificatesResponse.title,
			attachmentName: certificatesResponse.attachmentName
		};
	});
};

const mapDisciplineDetails = (
	disciplineDetailsResponse: DisciplineDetailsResponse[]
): DisciplineDetails[] => {
	return disciplineDetailsResponse.map((responseDetails) => {
		return {
			discipline: responseDetails.discipline,
			offerTemplates: mapOfferTemplate(responseDetails.offerTemplates),
			packages: mapPackage(responseDetails.packages),
			achievements: mapAchievements(responseDetails.achievements),
			studies: mapStudies(responseDetails.studies),
			certificates: mapCertificates(responseDetails.certificates),
			locations: mapLocations(responseDetails.locations)
		};
	});
};

const mapReviews = (reviewsResponses: TrainerReviewResponse[]): Review[] => {
	return reviewsResponses.map((reviewResponse) => ({
		id: reviewResponse.id,
		message: reviewResponse.message,
		qualityScore: reviewResponse.qualityScore,
		communicationScore: reviewResponse.communicationScore,
		hygieneScore: reviewResponse.hygieneScore,
		training: {
			id: reviewResponse.training.id,
			discipline: reviewResponse.training.discipline,
			trainingCategory: { name: reviewResponse.training.trainingCategory }
		},
		reviewer: {
			id: reviewResponse.reviewer.id,
			name: reviewResponse.reviewer.name,
			surname: reviewResponse.reviewer.surname,
			avatarUrl: reviewResponse.reviewer.avatarUrl
		}
	}));
};

export const mapMeTrainer = (response: GetMeResponse): MeTrainer => {
	return {
		id: response.id,
		contactDetails: mapContactDetails(response.contactDetails),
		description: response.description,
		languages: response.languages as LanguageCode[],
		sex: response.sex as Sex,
		avatarUrl: response.avatarUrl,
		reviews: mapReviews(response.reviews),
		overallRating: response.overallRating ?? 0,
		offers: response.offers.map(mapOffer),
		disciplineDetails: mapDisciplineDetails(response.disciplineDetails),
		enabledNotifications: response.enabledNotifications,
		license: {
			limits: {
				locationsValid: response.license.limits.locationsValid,
				disciplinesValid: response.license.limits.disciplinesValid,
				offersValid: response.license.limits.offersValid,
				packagesValid: response.license.limits.packagesValid
			},
			isPurchased: response.license.isPurchased,
			currentPeriod: response.license.currentPeriod
				? {
						start: new Date(response.license.currentPeriod.start),
						end: new Date(response.license.currentPeriod.end)
				  }
				: undefined,
			price: {
				value: parseFloat(response.license.price.value),
				currencyCode: response.license.price.currencyCode as CurrencyCode
			},
			cancelsAtPeriodEnd: response.license.cancelsAtPeriodEnd
		},
		status: response.status
	};
};

export const mapTrainer = (response: TrainerResponse): Trainer => {
	return {
		id: response.id,
		contactDetails: mapContactDetails(response.contactDetails),
		description: response.description,
		languages: response.languages as LanguageCode[],
		sex: response.sex as Sex,
		avatarUrl: response.avatarUrl,
		reviews: mapReviews(response.reviews),
		overallRating: response.overallRating ?? 0,
		offers: response.offers.map(mapOffer),
		disciplineDetails: mapDisciplineDetails(response.disciplineDetails),
		status: response.status
	};
};
