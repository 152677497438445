import * as React from 'react';
import type { SVGProps } from 'react';
const SvgError = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 16 16"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currenColor"
			d="M8 1.333c3.672 0 6.667 2.995 6.667 6.667A6.663 6.663 0 0 1 8 14.667 6.646 6.646 0 0 1 1.333 8 6.663 6.663 0 0 1 8 1.333Zm-.625 3.959v3.333c0 .365.26.625.625.625.339 0 .625-.26.625-.625V5.292A.634.634 0 0 0 8 4.667a.617.617 0 0 0-.625.625ZM8 11.75a.811.811 0 0 0 .807-.807.811.811 0 0 0-.807-.808.817.817 0 0 0-.833.808c0 .442.364.807.833.807Z"
		/>
	</svg>
);
export default SvgError;
