import { CountryCode } from 'libphonenumber-js';
import { Culture } from '@/config';

export type Sex = 'MALE' | 'FEMALE';

export type Price = {
	value: number;
	currencyCode: CurrencyCode;
};

export type PaymentMethod = 'STRIPE' | 'PACKAGE';

export type CurrencyCode = 'PLN' | 'EUR' | 'USD';

export const languages = ['any', 'pl', 'en', 'es', 'it', 'pt', 'fr'] as const;
export type LanguageCode = (typeof languages)[number];
export const languageCodeToCountryCode: Record<LanguageCode, CountryCode> = {
	any: 'GB',
	pl: 'PL',
	en: 'GB',
	es: 'ES',
	it: 'IT',
	pt: 'PT',
	fr: 'FR'
};

export type RangeDate = {
	startDate: Date;
	endDate: Date;
};

export const formatPrice = (price: Price, culture: Culture): string => {
	const formattedAmount = new Intl.NumberFormat(culture.replace('_', '-'), {
		style: 'currency',
		currency: price.currencyCode,
		minimumFractionDigits: 2
	}).format(price.value);

	// Replace any type of space character with a regular space
	return formattedAmount.replace(/\s+/g, ' ');
};

export type DownloadFileResponse = {
	data: Blob | File;
	fileName: string;
};

export type Balance = {
	value: number;
	currencyCode: CurrencyCode;
};
