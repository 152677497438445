import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSwimmingFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M8.859 14.54c-.687 0-1.335-.152-1.792-.457A6.521 6.521 0 0 1 8.363 10.5l.877-1.106c.038-.076 2.211-3.355 6.787-3.355.686 0 1.334.076 1.982.229l1.488.343c.533.114.915.648.915 1.182 0 .724-.61 1.22-1.182 1.22-.115 0-.191 0-.305-.038l-1.45-.305c-.495-.114-.953-.19-1.448-.19-1.64 0-2.745.648-2.86.724l5.338 4.423c-.229.228-.877.915-2.364.915-2.44 0-2.02-1.487-3.66-1.525-1.563.038-1.22 1.525-3.622 1.525ZM23.5 17.478c0 .648-.496 1.258-1.22 1.22-.381 0-1.525-.305-2.44-.84a7.439 7.439 0 0 1-3.66.954c-1.716 0-2.937-.534-3.66-.953a7.439 7.439 0 0 1-3.661.953c-1.716 0-2.936-.496-3.699-.915-.915.534-2.059.839-2.44.839-.724 0-1.22-.61-1.22-1.22 0-1.525 1.716-.992 2.821-2.021a1.44 1.44 0 0 1 .877-.305c.305 0 .61.114.84.343.037 0 .952.839 2.783.839 2.478 0 2.745-1.182 3.698-1.182.305 0 .61.114.839.343.038 0 .953.839 2.783.839 2.479 0 2.746-1.182 3.699-1.182.915 0 .8.762 2.63 1.106.61.114 1.03.61 1.03 1.182ZM5.16 10.88a2.433 2.433 0 0 1-2.44-2.44A2.433 2.433 0 0 1 5.16 6 2.457 2.457 0 0 1 7.6 8.44a2.433 2.433 0 0 1-2.44 2.44Z"
		/>
	</svg>
);
export default SvgSwimmingFilled;
