import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWindsurfingFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M11.387 3.552a.56.56 0 0 1 .389-.53c.212-.07.494.035.636.212l7.911 12.302a.717.717 0 0 1 .035.6.608.608 0 0 1-.494.283h-7.911a.558.558 0 0 1-.566-.565V3.552ZM2.522 18.29c-.106-.353.177-.742.566-.742h18.825c.388 0 .67.389.565.742l-.142.494c-.565 1.943-2.33 2.285-4.344 2.285H7.008c-2.013 0-3.78-.342-4.344-2.285l-.142-.494Z"
		/>
	</svg>
);
export default SvgWindsurfingFilled;
