import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChevronDownStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="m21.647 8.926-8.837 8.467c-.278.232-.556.324-.787.324-.277 0-.555-.092-.786-.277L2.353 8.926a1.104 1.104 0 0 1-.046-1.573 1.104 1.104 0 0 1 1.573-.046l8.143 7.773 8.097-7.773a1.104 1.104 0 0 1 1.573.046 1.104 1.104 0 0 1-.046 1.573Z"
		/>
	</svg>
);
export default SvgChevronDownStroke;
