import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { red } from '@mui/material/colors';

type MuiFormLabelOverrides = {
	defaultProps?: ComponentsProps['MuiFormLabel'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiFormLabel'];
	variants?: ComponentsVariants['MuiFormLabel'];
};

export const muiFormLabel = (theme: Theme): MuiFormLabelOverrides => ({
	styleOverrides: {
		root: {
			display: 'flex',
			fontFamily: 'Matter',
			...theme.typography.h7,
			[theme.breakpoints.down('md')]: {
				...theme.typography.h6
			}
		},
		asterisk: {
			color: red['600']
		}
	}
});
