import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { Theme } from '@mui/material';

type MuiSkeletonOverrides = {
	defaultProps?: ComponentsProps['MuiSkeleton'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiSkeleton'];
	variants?: ComponentsVariants['MuiSkeleton'];
};

export const muiSkeleton: MuiSkeletonOverrides = {
	defaultProps: {
		variant: 'rounded',
		animation: 'pulse',
		height: 300
	}
};
