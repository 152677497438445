import { TransactionResponse } from '@/api';
import { CurrencyCode } from '@/shared';
import { TrainerTransaction } from '@/shared/model/TrainerTransaction';

export const mapTransaction = (response: TransactionResponse): TrainerTransaction => {
	return {
		id: response.id,
		date: new Date(response.date),
		type: response.type,
		price: {
			value: parseFloat(response.price.value),
			currencyCode: response.price.currencyCode as CurrencyCode
		}
	};
};
