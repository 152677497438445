import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSquashFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="m1.387 18.05 3.902-2.706 2.18 3.094-3.903 2.636a.886.886 0 0 1-.562.176c-.317 0-.633-.14-.844-.422l-.984-1.406a.799.799 0 0 1-.211-.563c0-.316.176-.597.422-.808ZM23.5 8.173c0 2.32-1.441 4.922-3.938 6.68-1.722 1.23-3.656 1.828-5.378 1.828-.457 0-1.16-.106-2.075-.106-1.02 0-2.355.14-3.972.774L6.52 15.063c1.16-1.336 2.039-2.918 2.39-4.641C9.965 5.57 15.062 3.25 18.086 3.25c2.039 0 5.414 1.125 5.414 4.922Zm-2.25 0c0-1.582-1.195-2.637-3.2-2.637-4.288 0-7.066 4.078-7.066 6.223 0 1.617 1.196 2.672 3.164 2.672 3.13 0 7.102-2.918 7.102-6.258Z"
		/>
	</svg>
);
export default SvgSquashFilled;
