import { Container } from '@mui/material';
import { Navbar } from '@/config/layout/navigation/Navbar';
import { Flex } from '@/ui/flex/Flex';
import { Footer } from '@/ui/footer/Footer';
import { FC, useState } from 'react';
import { isLoading, Loader } from '@/shared';
import { LayoutType, Router, useLayout } from '@/config';

export const ApplicationLayout: FC = () => {
	const [routerLoader, setRouterLoader] = useState<Loader>({ loading: false });
	const layout = useLayout();

	const getNavbarLayout = (): LayoutType => {
		switch (layout) {
			case 'landing':
				return 'landing';
			case 'landing-pro':
				return 'landing-pro';
			default:
				return 'application';
		}
	};

	const isLanding = layout === 'landing' || layout === 'landing-pro';

	return (
		<Flex height="100%" flexDirection="column" justifyContent={'space-between'}>
			<Navbar layoutType={getNavbarLayout()} loading={isLoading([routerLoader])} />
			{isLanding ? (
				<Router onRouteLoaderChange={setRouterLoader} />
			) : (
				<Container maxWidth="lg" sx={{ my: 4 }}>
					<Router onRouteLoaderChange={setRouterLoader} />
				</Container>
			)}
			<Footer />
		</Flex>
	);
};
