import { FC } from 'react';
import { ContextMenuOptions } from '@/ui/context-menu-context/context-menu-context';
import { Flex } from '@/ui/flex/Flex';
import Typography from '@mui/material/Typography';
import { IconButton } from '@/ui/icon-button/IconButton';
import SvgCross from '@/assets/icons/generated/Cross';
import { Each } from '@/ui/each/Each';
import Box from '@mui/material/Box';

type ContextMenuProps = Omit<ContextMenuOptions, 'dialogProps'> & {
	close: () => void;
};

export const ContextMenu: FC<ContextMenuProps> = ({ options, title, body, close }) => {
	return (
		<Flex flexDirection={'column'} padding={2} gap={2}>
			<Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
				{title &&
					(typeof title === 'string' ? (
						<Typography component={'h4'} textAlign={'center'}>
							{title}
						</Typography>
					) : (
						title
					))}
				<IconButton onClick={close} sx={{ ml: 'auto' }}>
					<SvgCross width={16} height={16} />
				</IconButton>
			</Flex>
			{typeof body === 'string' ? <Typography variant={'p2'}>{body}</Typography> : body}
			<Flex flexDirection={'column'} gap={1}>
				<Each
					of={options}
					render={(option) =>
						typeof option.content === 'string' ? (
							<Box
								onClick={() => {
									option.onClick();
									close();
								}}>
								<Typography variant={'p2'}>{option.content}</Typography>
							</Box>
						) : (
							<Box
								onClick={() => {
									option.onClick();
									close();
								}}>
								{option.content}
							</Box>
						)
					}
				/>
			</Flex>
		</Flex>
	);
};
