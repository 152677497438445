import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReformerPilatesStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M23.435 16.698h-5.932l4.48-5.234.007-.009c.333-.358.539-.84.539-1.37a2 2 0 0 0-1.99-2.007h-4.744c-1.358 0-2.463 1.115-2.463 2.486v2.303H9.244V8.17a2 2 0 0 0-1.99-2.008A2 2 0 0 0 5.267 8.17v5.095a2.429 2.429 0 0 0-1.332-.398c-1.358 0-2.463 1.114-2.463 2.485 0 .496.146.956.395 1.345h-1.3a.567.567 0 0 0-.566.57c0 .316.253.57.565.57h22.87a.567.567 0 0 0 .565-.57.567.567 0 0 0-.565-.57Zm-6.143-1.5c.011-.107.017-.215.017-.325v-2.781h2.641l-2.658 3.106Zm-14.69.154a1.34 1.34 0 0 1 1.332-1.344 1.34 1.34 0 0 1 1.332 1.344 1.34 1.34 0 0 1-1.332 1.345 1.34 1.34 0 0 1-1.332-1.345Zm3.796 0V8.17c0-.478.384-.867.858-.867s.858.389.858.867v5.267c0 .316.253.571.566.571h3.23v2.69H7.731A1.34 1.34 0 0 1 6.4 15.354l-.001-.002Zm6.642 1.346v-2.69h.858a.567.567 0 0 0 .565-.57v-2.874a1.34 1.34 0 0 1 1.332-1.344h4.745c.473 0 .858.388.858.866a.863.863 0 0 1-.858.866h-3.796a.567.567 0 0 0-.566.57v3.352c0 1.006-.81 1.824-1.806 1.824H13.04Z"
		/>
	</svg>
);
export default SvgReformerPilatesStroke;
