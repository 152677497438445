import * as React from 'react';
import type { SVGProps } from 'react';
const SvgStepStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#221F20"
			d="M22.497 15.32a1.336 1.336 0 0 0-.028-.246l-1.547-6.716a1.302 1.302 0 0 0-1.284-1.008H4.361c-.622 0-1.15.414-1.285 1.008L1.53 15.074a1.217 1.217 0 0 0-.029.247l-.001.037.003.057a1.037 1.037 0 0 0 .023.19c.003.01.003.02.005.028.043.192.13.375.258.534.12.146.268.26.432.343l.022.012.025.01c.165.076.344.118.528.118H6.109c.177 0 .35-.04.508-.11.193-.084.366-.212.502-.38.144-.158.22-.339.253-.528.045-.19.045-.386-.005-.576l-.01-.04c-.032-.182-.08-.36-.124-.526a7.008 7.008 0 0 1-.082-.327c-.13-.566-.621-.956-1.24-1.002h-.048c-.025 0-.05-.003-.074-.003l.354-1.08h11.714l.354 1.08-.074.002c-.015 0-.032 0-.048.002-.619.047-1.11.437-1.24 1.001-.022.103-.052.212-.082.327-.044.165-.092.344-.123.526l-.01.04a1.2 1.2 0 0 0-.006.576c.033.19.108.369.253.529.136.167.309.295.502.378.16.072.331.111.508.111H21.204c.184 0 .363-.042.528-.118l.025-.01.022-.012c.164-.082.312-.197.432-.343a1.3 1.3 0 0 0 .258-.534c.002-.009.002-.019.005-.028.006-.034.014-.068.018-.104.004-.03.004-.057.005-.086 0-.02.003-.038.003-.057l-.001-.037h-.002ZM4.037 8.566a.336.336 0 0 1 .043-.103.343.343 0 0 1 .227-.145h.003a.398.398 0 0 1 .056-.004h15.268c.02 0 .038.002.056.004h.003a.342.342 0 0 1 .227.145c.02.03.035.065.044.103l.085.384H3.952l.086-.384h-.002Zm-1.219 7.97h-.01.01Zm3.27 0H6.1 6.09Zm11.812 0h.011-.011Zm3.28 0h.011-.01Zm.26-.977a.34.34 0 0 1-.206.124c-.018.002-.035.006-.053.006H17.907a.33.33 0 0 1-.255-.132.308.308 0 0 1-.062-.268l.218-.904a.333.333 0 0 1 .325-.254h1.431l-.05-.149-.915-2.785-.026-.08H5.422l-.026.08-.916 2.785-.05.149h1.432c.155 0 .289.104.325.254l.218.904a.3.3 0 0 1-.062.268.331.331 0 0 1-.256.132H2.813a.254.254 0 0 1-.053-.006.328.328 0 0 1-.206-.124.314.314 0 0 1-.066-.27l1.237-5.376h16.539l1.237 5.376c.024.091 0 .189-.066.27h.004Z"
		/>
	</svg>
);
export default SvgStepStroke;
