import * as React from 'react';
import type { SVGProps } from 'react';
const SvgYogaFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 60 50"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M23.768 40.884c2.427 4.171 4.844 6.76 4.946 6.868a1.765 1.765 0 0 0 2.571 0c.102-.108 2.52-2.697 4.946-6.868 3.235-5.562 4.945-11.334 4.945-16.694 0-5.359-1.71-11.13-4.945-16.693C33.804 3.326 31.387.737 31.285.63a1.765 1.765 0 0 0-2.57 0c-.103.108-2.52 2.697-4.947 6.868-3.235 5.562-4.945 11.334-4.945 16.694 0 5.359 1.71 11.13 4.945 16.693ZM13.565 48.7c2.497.908 5.03 1.227 7.354 1.227.952 0 1.867-.054 2.731-.142l.02-.073a48.852 48.852 0 0 1-3.651-1.892c-6.123-3.509-10.94-8.101-13.931-13.282-.155-.267-.304-.54-.451-.813-2.307.481-3.817 1.075-3.93 1.12a1.765 1.765 0 0 0-.9 2.473c.183.342 4.587 8.408 12.758 11.382ZM53.911 34.538c-2.99 5.18-7.808 9.773-13.93 13.282a48.871 48.871 0 0 1-3.652 1.892l.02.073c.864.088 1.78.142 2.73.142 2.325 0 4.858-.319 7.355-1.227 8.17-2.974 12.575-11.04 12.758-11.381a1.765 1.765 0 0 0-.9-2.474c-.113-.045-1.623-.639-3.93-1.12-.147.274-.296.546-.45.813Z"
		/>
		<path
			fill="#000"
			d="m21.777 44.758.378.214c-.467-.71-.95-1.482-1.433-2.314-3.549-6.1-5.424-12.486-5.424-18.468 0-4.28.96-8.769 2.808-13.21-5.094-3.353-9.693-4.766-9.946-4.843a1.765 1.765 0 0 0-2.227 1.286c-.034.144-.833 3.595-.849 8.421-.02 6.434 1.385 12.289 4.064 16.93 2.68 4.64 7.047 8.785 12.63 11.984ZM44.71 24.19c0 5.982-1.876 12.368-5.424 18.468a48.893 48.893 0 0 1-1.434 2.314c.126-.07.252-.142.378-.214 5.583-3.2 9.95-7.344 12.63-11.985 2.68-4.64 4.084-10.495 4.064-16.929-.016-4.826-.815-8.277-.85-8.421a1.765 1.765 0 0 0-2.226-1.286c-.253.077-4.852 1.49-9.946 4.842 1.847 4.442 2.808 8.93 2.808 13.212Z"
		/>
	</svg>
);
export default SvgYogaFilled;
