import * as React from 'react';
import type { SVGProps } from 'react';
const SvgStepFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="m22.475 15.792-1.564-8.218c-.126-.666-.61-1.124-1.184-1.124H4.28c-.573 0-1.058.46-1.188 1.124l-1.56 8.218c-.185.873.43 1.782 1.188 1.757h3.302c.372 0 .72-.202.952-.549.486-.644.137-1.604.007-2.325-.149-.775-.785-1.193-1.427-1.111l.437-1.61H18.01l.44 1.61c-.641-.084-1.283.333-1.427 1.111l-.224 1.098c-.19.875.42 1.798 1.184 1.776h3.301c.373 0 .718-.197.949-.54.23-.346.318-.787.238-1.219l.004.002Z"
		/>
	</svg>
);
export default SvgStepFilled;
