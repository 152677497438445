import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEmsStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			fillRule="evenodd"
			d="M18.522 13.358c.492-.406.62-1.037.402-1.602l-.003-.008a1.451 1.451 0 0 0-1.332-.888H13.68l2.912-5.823c.313-.627.128-1.338-.392-1.771l-.005-.004a1.41 1.41 0 0 0-.856-.312c-.362 0-.674.138-.93.35l-9.005 7.88-.004.003c-.449.404-.576 1.027-.363 1.59.19.558.737.902 1.338.902h3.873l-2.911 5.824c-.314.626-.128 1.337.392 1.77l.013.01.013.01c.262.174.567.261.87.261.295 0 .65-.083.93-.316l8.967-7.876Zm-2.198-8.456-3.13 6.258h4.395c.458 0 .88.281 1.055.703.176.457.07.95-.316 1.266l-8.965 7.875 8.965-7.875c.386-.316.492-.809.316-1.266a1.151 1.151 0 0 0-1.055-.703h-4.394l3.129-6.258c.246-.492.105-1.054-.317-1.406.422.352.563.914.317 1.406Zm-.985-.527L6.375 12.25h4.359-4.36l8.965-7.875Zm-4.533 7.877c.358.02.68.196.877.525.211.317.211.739.035 1.09l-3.093 6.258 3.093-6.258c.176-.351.176-.773-.035-1.09a1.068 1.068 0 0 0-.877-.525Zm2.389.333h3.596l-7.23 6.322 2.426-4.906c.214-.43.226-.965-.05-1.384a1.374 1.374 0 0 0-1.203-.667H7.171l7.214-6.338-2.443 4.887c-.214.429-.226.964.05 1.384.26.43.703.702 1.203.702Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgEmsStroke;
