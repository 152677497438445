import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheckStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M21.677 6.746 9.953 18.47c-.173.215-.431.302-.69.302-.302 0-.56-.087-.733-.302l-6.207-6.207a.944.944 0 0 1 0-1.422.944.944 0 0 1 1.423 0l5.517 5.474L20.254 5.323a.944.944 0 0 1 1.423 0 .944.944 0 0 1 0 1.423Z"
		/>
	</svg>
);
export default SvgCheckStroke;
