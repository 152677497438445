import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHealthySpineFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 20 20"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M14.618 2.066h-.675A1.735 1.735 0 0 0 12.31.911H7.69c-.753 0-1.394.483-1.633 1.155h-.675c-.955 0-1.732.777-1.732 1.732 0 .954.777 1.732 1.732 1.732h.675c.24.673.88 1.156 1.633 1.156h4.62c.753 0 1.394-.483 1.633-1.156h.675a1.733 1.733 0 0 0 0-3.464ZM14.618 8.268h-.675a1.735 1.735 0 0 0-1.633-1.155H7.69c-.753 0-1.394.482-1.633 1.155h-.675a1.733 1.733 0 0 0 0 3.464h.675c.24.673.88 1.155 1.633 1.155h4.62c.753 0 1.394-.482 1.633-1.155h.675c.955 0 1.732-.777 1.732-1.731 0-.954-.777-1.733-1.732-1.733ZM14.618 14.47h-.675a1.735 1.735 0 0 0-1.633-1.155H7.69c-.753 0-1.394.482-1.633 1.155h-.675c-.955 0-1.732.777-1.732 1.73 0 .955.777 1.734 1.732 1.734h.675c.24.672.88 1.155 1.633 1.155h4.62c.753 0 1.394-.483 1.633-1.155h.675a1.733 1.733 0 0 0 0-3.464ZM19.276 9.198H18.12a.578.578 0 0 0 0 1.156h1.155a.578.578 0 0 0 0-1.156ZM1.88 10.354a.578.578 0 0 0 0-1.156H.723a.578.578 0 0 0 0 1.156H1.88ZM19.111 11.609l-1.115-.3a.577.577 0 1 0-.3 1.116l1.116.3a.578.578 0 0 0 .299-1.116ZM2.304 7.127l-1.116-.3A.577.577 0 1 0 .89 7.945l1.116.299a.579.579 0 0 0 .3-1.116ZM17.845 8.262c.05 0 .1-.007.15-.02l1.116-.3a.577.577 0 1 0-.299-1.116l-1.115.3a.577.577 0 0 0 .148 1.136ZM2.006 11.31l-1.116.299a.577.577 0 1 0 .299 1.115l1.116-.299a.577.577 0 1 0-.3-1.116Z"
		/>
	</svg>
);
export default SvgHealthySpineFilled;
