import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPilatesStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 18 18"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M15.256 15.252c.52-.52.961-1.09 1.324-1.694l.017-.027a8.786 8.786 0 0 0 1.096-2.916.601.601 0 0 0 .02-.124 8.883 8.883 0 0 0-.046-3.24c-.001-.017-.001-.034-.005-.051-.005-.02-.012-.038-.019-.057a8.788 8.788 0 0 0-2.389-4.398 8.789 8.789 0 0 0-4.398-2.39c-.019-.005-.037-.013-.057-.017-.017-.005-.034-.003-.05-.006A8.9 8.9 0 0 0 7.505.287a.55.55 0 0 0-.118.02A8.814 8.814 0 0 0 4.473 1.4c-.012.008-.023.013-.034.022a8.829 8.829 0 0 0-2.724 2.564c-.013.015-.023.032-.034.05-2.335 3.44-1.982 8.172 1.064 11.218A8.817 8.817 0 0 0 9 17.84a8.83 8.83 0 0 0 6.253-2.586l.003-.002Zm1.152-8.404a4.842 4.842 0 0 1-3.855-1.404A4.84 4.84 0 0 1 11.15 1.59c1.211.349 2.353 1 3.304 1.952a7.682 7.682 0 0 1 1.952 3.305l.002.001ZM9 1.288c.347 0 .694.023 1.04.07a5.96 5.96 0 0 0 1.714 4.886A5.964 5.964 0 0 0 16.64 7.96a7.81 7.81 0 0 1 .006 2.03 7.376 7.376 0 0 1-6.635-2.003 7.377 7.377 0 0 1-2.003-6.635c.33-.042.662-.065.994-.065H9ZM3.547 3.542c.165-.165.336-.32.511-.466a10.323 10.323 0 0 0 1.95 6.654.565.565 0 1 0 .914-.667 9.195 9.195 0 0 1-1.64-6.824A7.695 7.695 0 0 1 6.82 1.6 8.512 8.512 0 0 0 9.21 8.787a8.512 8.512 0 0 0 7.188 2.392 7.649 7.649 0 0 1-.64 1.536 9.188 9.188 0 0 1-6.825-1.64.566.566 0 0 0-.667.914 10.312 10.312 0 0 0 6.653 1.952 7.494 7.494 0 0 1-1.46 1.348A10.141 10.141 0 0 1 2.707 4.537a7.7 7.7 0 0 1 .838-.995l.002.001Zm0 10.91a7.72 7.72 0 0 1-1.892-7.801 11.26 11.26 0 0 0 3.206 6.486 11.267 11.267 0 0 0 6.49 3.203 7.72 7.72 0 0 1-7.804-1.89v.002Z"
		/>
	</svg>
);
export default SvgPilatesStroke;
