import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { primary, secondary } from '@/ui/theme/components-overrides/colors';
import { SliderThumbLabel } from '@/ui/slider/SliderThumbLabel';

type MuiSliderOverrides = {
	defaultProps?: ComponentsProps['MuiSlider'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiSlider'];
	variants?: ComponentsVariants['MuiSlider'];
};

export const muiSlider: MuiSliderOverrides = {
	defaultProps: {
		color: 'secondary',
		valueLabelDisplay: 'auto',
		valueLabelFormat: (value) => <SliderThumbLabel value={`${value}`} />
	},
	styleOverrides: {
		root: {
			'& .MuiSlider-thumbColorSecondary': {
				height: '16px',
				width: '16px',
				color: secondary.main,
				border: `4px solid ${primary.black}`
			},
			'& .MuiSlider-valueLabel': {
				backgroundColor: 'inherit'
			},
			'& .MuiSlider-valueLabel:before': {
				display: 'none'
			},
			'& .MuiSlider-rail': {
				backgroundColor: primary['50'],
				opacity: 1
			}
		}
	}
};
