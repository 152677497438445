import { Discipline, disciplines, isNil, Maybe, Trainer } from '@/shared';

export type DisciplineOrTrainerAutocompleteItem = Discipline | Trainer | string;

export const isItemTrainer = (
	item: Maybe<DisciplineOrTrainerAutocompleteItem>
): item is Trainer => {
	return !isNil(item) && typeof item !== 'string';
};

export const isItemDiscipline = (
	item: Maybe<DisciplineOrTrainerAutocompleteItem>
): item is Discipline => {
	return !isNil(item) && typeof item === 'string' && disciplines.includes(item as Discipline);
};

export const isItemTrainerNameQuery = (
	item: Maybe<DisciplineOrTrainerAutocompleteItem>
): item is string => {
	return !isNil(item) && typeof item === 'string' && !disciplines.includes(item as Discipline);
};

export const areDisciplineOrTrainerAutocompleteItemsEqual = (
	item1: DisciplineOrTrainerAutocompleteItem,
	item2: DisciplineOrTrainerAutocompleteItem
): boolean => {
	if (
		(isItemTrainer(item1) && !isItemTrainer(item2)) ||
		(isItemTrainer(item2) && !isItemTrainer(item1))
	) {
		return false;
	} else if (isItemTrainer(item1) && isItemTrainer(item2)) {
		return item1.id === item1.id;
	}
	// it's both disciplines or strings
	return item1 === item1;
};
