import { IconType } from '@/shared';
import { Path, TranslationKey } from '@/config';
import SvgHomeStroke from '@/assets/icons/generated/stroke/HomeStroke';
import SvgCalendarStroke from '@/assets/icons/generated/stroke/CalendarStroke';
import SvgDollarBadgeStroke from '@/assets/icons/generated/stroke/DollarBadgeStroke';
import SvgSettingsStroke from '@/assets/icons/generated/stroke/SettingsStroke';
import SvgUserFilled from '@/assets/icons/generated/filled/UserFilled';
import { AppType } from '@/config/app-type/appTypeContext';
import SvgSearchStroke from '@/assets/icons/generated/stroke/SearchStroke';
import SvgWorkoutly from '@/assets/icons/generated/Workoutly';

export type Menu = {
	items: MenuItem[];
};

export type MenuRoute = {
	path: Path;
	queryParams?: { [key: string]: string }[];
};

export type SubMenu = {
	title: TranslationKey;
	items: MenuItem[];
};

export type MenuItem = {
	title?: TranslationKey;
	icon?: IconType;
	name: TranslationKey;
	route: MenuRoute;
	appType: AppType;
	children?: SubMenu;
};

export const menu: Menu = {
	items: [
		{
			icon: SvgHomeStroke,
			name: 'shared.menu.dashboard',
			route: { path: 'me/trainer/dashboard' },
			appType: 'pro'
		},
		{
			icon: SvgUserFilled,
			name: 'shared.menu.profile',
			route: { path: 'me/trainer/profile' },
			appType: 'pro',
			children: {
				items: [
					{
						name: 'page.MyTrainerProfile.component.TrainerProfileMenu.personal-information',
						appType: 'pro',
						route: {
							path: 'me/trainer/profile',
							queryParams: [{ type: 'PERSONAL_INFORMATION' }]
						}
					},
					{
						name: 'page.MyTrainerProfile.component.TrainerProfileMenu.locations',
						appType: 'pro',
						route: {
							path: 'me/trainer/profile',
							queryParams: [{ type: 'LOCATIONS' }]
						}
					},
					{
						name: 'page.MyTrainerProfile.component.TrainerProfileMenu.disciplines-templates',
						appType: 'pro',
						route: {
							path: 'me/trainer/profile',
							queryParams: [{ type: 'DISCIPLINES_TEMPLATES' }]
						}
					},
					{
						name: 'page.MyTrainerProfile.component.TrainerProfileMenu.education',
						appType: 'pro',
						route: {
							path: 'me/trainer/profile',
							queryParams: [{ type: 'EDUCATION' }]
						}
					},
					{
						name: 'page.MyTrainerProfile.component.TrainerProfileMenu.achievements',
						appType: 'pro',
						route: {
							path: 'me/trainer/profile',
							queryParams: [{ type: 'ACHIEVEMENTS' }]
						}
					},
					{
						name: 'page.MyTrainerProfile.component.TrainerProfileMenu.certificates',
						appType: 'pro',
						route: {
							path: 'me/trainer/profile',
							queryParams: [{ type: 'CERTIFICATES' }]
						}
					}
				],
				title: 'shared.menu.profile'
			}
		},
		{
			icon: SvgCalendarStroke,
			name: 'shared.menu.calendar',
			appType: 'pro',
			route: { path: 'me/trainer/calendar' }
		},
		{
			icon: SvgDollarBadgeStroke,
			name: 'shared.menu.settlements',
			appType: 'pro',
			route: { path: 'me/trainer/settlements' }
		},
		{
			icon: SvgSettingsStroke,
			name: 'shared.menu.settings',
			appType: 'pro',
			route: { path: 'me/trainer/settings' },
			children: {
				items: [
					{
						name: 'page.MyTrainerSettings.component.TrainerSettingsMenu.general',
						appType: 'pro',
						route: {
							path: 'me/trainer/settings',
							queryParams: [{ type: 'GENERAL' }]
						}
					},
					{
						name: 'page.MyTrainerSettings.component.TrainerSettingsMenu.notifications',
						appType: 'pro',
						route: {
							path: 'me/trainer/settings',
							queryParams: [{ type: 'NOTIFICATIONS' }]
						}
					},
					{
						name: 'page.MyTrainerSettings.component.TrainerSettingsMenu.payments',
						appType: 'pro',
						route: {
							path: 'me/trainer/settings',
							queryParams: [{ type: 'PAYMENTS' }]
						}
					}
				],
				title: 'shared.menu.settings'
			}
		},
		{
			icon: SvgSearchStroke,
			name: 'shared.menu.my-trainings',
			appType: 'regular',
			route: { path: 'me/services' }
		},
		{
			icon: SvgWorkoutly,
			name: 'shared.menu.saved-trainers',
			appType: 'regular',
			route: { path: 'me/saved-trainers' }
		},
		{
			icon: SvgSettingsStroke,
			name: 'shared.menu.settings',
			appType: 'regular',
			route: { path: 'me/settings' }
		}
	]
};
