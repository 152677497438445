import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTennisFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M16.68 14.476a6.793 6.793 0 0 1 3.906-1.209c.625 0 1.21.078 1.758.234a9.893 9.893 0 0 1-8.36 8.343 6.77 6.77 0 0 1-.234-1.754 6.827 6.827 0 0 1 2.93-5.614ZM4.414 10.733c-.625 0-1.21-.078-1.797-.195.664-4.327 4.063-7.72 8.36-8.343.156.546.234 1.17.234 1.754 0 3.158-2.461 6.784-6.797 6.784ZM12.461 2c5.547 0 10 4.483 10.039 10.02 0 .078-.04.117-.04.194a10.21 10.21 0 0 0-1.874-.194c-5.117 0-8.086 4.327-8.086 8.109 0 .623.078 1.247.195 1.832-.078 0-.156.039-.195.039-5.547 0-10-4.483-10-9.98v-.234c.625.155 1.25.194 1.875.194 5.117 0 8.086-4.288 8.086-8.109 0-.623-.078-1.247-.234-1.832.078 0 .156-.039.234-.039Z"
		/>
	</svg>
);
export default SvgTennisFilled;
