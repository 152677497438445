import { ComponentsProps } from '@mui/material/styles/props';
import { primary, secondary } from '@/ui/theme/components-overrides/colors';
import { ComponentsOverrides } from '@mui/material/styles';
import { Theme } from '@mui/material';

type MuiPickersDayOverrides = {
	// @ts-ignore
	defaultProps?: ComponentsProps['MuiPickersDay'];
	// @ts-ignore
	styleOverrides?: ComponentsOverrides<Theme>['MuiPickersDay'];
};

export const muiPickersDay: MuiPickersDayOverrides = {
	styleOverrides: {
		root: {
			'&.MuiPickersDay-root.Mui-selected': {
				color: primary['900'],
				backgroundColor: secondary['900']
			}
		}
	}
};
