import * as React from 'react';
import type { SVGProps } from 'react';
const SvgYogaStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<mask
			id="yoga_stroke_svg__a"
			width={24}
			height={18}
			x={0}
			y={3}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: 'luminance'
			}}>
			<path fill="#fff" d="M0 3h24v18H0V3Z" />
		</mask>
		<g mask="url(#yoga_stroke_svg__a)">
			<path
				fill="#000"
				fillRule="evenodd"
				d="M10.2 18.544c-.848.133-1.93.161-2.954-.211-1.023-.373-1.834-1.09-2.399-1.738a8.056 8.056 0 0 1-.483-.613 7.378 7.378 0 0 1 2.237-.24c.295.017.383.053.399-.242.016-.295-.205-.984-.5-1-.905-.05-1.572.251-2.167.388a8.549 8.549 0 0 0-.973.287l-.016.006-.005.002h-.001l.197.498-.471.253v.001l.002.003.004.007.013.024.05.086a9.042 9.042 0 0 0 .908 1.244c.629.721 1.582 1.582 2.84 2.04 1.257.457 2.54.41 3.486.262a9.05 9.05 0 0 0 1.495-.37c.04-.013.07-.024.093-.033l.026-.01.007-.003h.003v-.001s.002 0-.197-.497l.198.497a.536.536 0 0 0-.396-.995l-.003.002-.016.006a6.062 6.062 0 0 1-.354.117 7.966 7.966 0 0 1-1.022.23ZM3.536 15.68l-.471.253a.536.536 0 0 1 .273-.75l.198.497Z"
				clipRule="evenodd"
			/>
			<path
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit={10}
				strokeWidth={0.4}
				d="M3.535 15.68c-.198-.496-.197-.497-.197-.497h.001l.005-.002.016-.006.056-.021a8.549 8.549 0 0 1 .916-.266c.596-.137 1.263-.438 2.168-.388.295.016.516.705.5 1-.016.295-.104.26-.4.243a7.378 7.378 0 0 0-2.236.24c.128.18.29.39.483.612.565.648 1.376 1.365 2.4 1.738 1.023.372 2.105.344 2.954.21a7.966 7.966 0 0 0 1.306-.32l.07-.026.016-.006.003-.002a.535.535 0 0 1 .396.995M3.535 15.68l-.471.253m.471-.253-.471.253m.471-.253-.198-.497a.535.535 0 0 0-.273.75m0 0v.001l.002.003.004.007.013.024.05.086a9.042 9.042 0 0 0 .908 1.244c.629.721 1.582 1.582 2.84 2.04 1.257.457 2.54.41 3.486.262a9.05 9.05 0 0 0 1.495-.37c.04-.013.07-.024.093-.033l.026-.01.007-.003h.003v-.001m0 0s.002 0-.197-.497l.198.497Z"
			/>
			<path
				fill="#000"
				fillRule="evenodd"
				d="M13.222 19.601c.946.149 2.23.195 3.487-.263 1.257-.457 2.21-1.318 2.84-2.04a9.034 9.034 0 0 0 .908-1.243l.049-.086.013-.024.004-.007.002-.003-.47-.254.197-.497-.002-.001-.005-.002-.016-.006a3.647 3.647 0 0 0-.257-.09 8.545 8.545 0 0 0-.715-.197 8.451 8.451 0 0 0-2.327-.214.535.535 0 0 0 .059 1.069 7.382 7.382 0 0 1 2.237.24c-.129.18-.29.39-.484.612-.565.648-1.376 1.365-2.4 1.738-1.022.372-2.105.344-2.954.21a7.98 7.98 0 0 1-1.306-.32 3.578 3.578 0 0 1-.07-.026l-.016-.006-.002-.001a.535.535 0 0 0-.397.994l.198-.497-.198.497h.001l.003.001.007.003.026.01.093.034a9.045 9.045 0 0 0 1.495.37Zm-1.228-1.411Zm8.06-2.51.198-.496a.536.536 0 0 1 .273.75l-.47-.253Z"
				clipRule="evenodd"
			/>
			<path
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit={10}
				strokeWidth={0.4}
				d="m20.525 15.934-.47-.253m.47.253a.537.537 0 0 0-.273-.75m.273.75-.47-.253m.47.253v.001l-.002.003-.004.007-.013.024a5.358 5.358 0 0 1-.236.383 9.034 9.034 0 0 1-.721.946c-.63.722-1.583 1.583-2.84 2.04-1.258.458-2.54.412-3.487.263a9.045 9.045 0 0 1-1.495-.37 4.499 4.499 0 0 1-.093-.033l-.026-.01-.007-.003h-.003l-.002-.001m8.458-3.503.198-.497m-.198.497.198-.497m0 0-.002-.001-.005-.002-.016-.006a3.647 3.647 0 0 0-.257-.09 8.545 8.545 0 0 0-.715-.197 8.451 8.451 0 0 0-2.327-.214.535.535 0 0 0 .059 1.069 7.382 7.382 0 0 1 2.237.24c-.129.18-.29.39-.484.612-.565.648-1.376 1.365-2.4 1.738-1.022.372-2.105.344-2.954.21a7.98 7.98 0 0 1-1.306-.32 3.578 3.578 0 0 1-.07-.026l-.016-.006-.002-.001m0 0a.535.535 0 0 0-.397.994m.397-.994s0 0 0 0Zm-.397.994.198-.497-.198.497Z"
			/>
			<path
				fill="#000"
				fillRule="evenodd"
				d="m11.609 17.953-.007-.002-.027-.01a9.673 9.673 0 0 1-.562-.208c-.38-.153-.907-.39-1.488-.724-1.17-.67-2.526-1.706-3.383-3.19-.856-1.484-1.076-3.176-1.072-4.524a12.345 12.345 0 0 1 .12-1.68c.21.085.468.196.756.336.792.383 1.803.977 2.71 1.82a.535.535 0 1 0 .729-.784c-1.009-.938-2.12-1.586-2.972-2a12.8 12.8 0 0 0-1.364-.565 5.563 5.563 0 0 0-.085-.028l-.024-.008-.007-.002h-.004l-.154.511-.521-.122v.002l-.001.003-.003.012-.009.039a10.64 10.64 0 0 0-.115.667A13.415 13.415 0 0 0 4 9.29c-.005 1.448.228 3.352 1.216 5.063.987 1.711 2.52 2.864 3.776 3.584.632.362 1.204.62 1.619.788a10.605 10.605 0 0 0 .634.234l.039.012.01.004h.004l.001.001.146-.482-.146.482a.536.536 0 0 0 .31-1.024ZM4.775 6.895l-.521-.122a.536.536 0 0 1 .675-.39l-.154.512Z"
				clipRule="evenodd"
			/>
			<path
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit={10}
				strokeWidth={0.4}
				d="M11.609 17.953s0 0 0 0Zm0 0-.007-.002-.027-.01a9.673 9.673 0 0 1-.562-.208c-.38-.153-.907-.39-1.488-.724-1.17-.67-2.526-1.706-3.383-3.19-.856-1.484-1.076-3.176-1.072-4.524a12.345 12.345 0 0 1 .12-1.68c.21.085.468.196.756.336.792.383 1.803.977 2.71 1.82a.535.535 0 1 0 .729-.784c-1.009-.938-2.12-1.586-2.972-2a12.8 12.8 0 0 0-1.364-.565 5.563 5.563 0 0 0-.085-.028l-.024-.008-.007-.002h-.004m6.68 11.569a.536.536 0 1 1-.31 1.024M4.93 6.383l-.154.512m.154-.512a.536.536 0 0 0-.675.39m.675-.39-.154.512m0 0-.521-.122m.52.122-.52-.122m0 0v.002l-.001.003-.003.012-.009.039a10.64 10.64 0 0 0-.115.667A13.415 13.415 0 0 0 4 9.29c-.005 1.448.228 3.352 1.216 5.063m0 0 .413-.239m-.413.239c.987 1.711 2.52 2.864 3.776 3.584.632.362 1.204.62 1.619.788a10.605 10.605 0 0 0 .634.234l.039.012.01.004h.004l.001.001m0 0 .146-.482-.146.482Z"
			/>
			<path
				fill="#000"
				fillRule="evenodd"
				d="m11.844 17.953.007-.002.028-.01c.025-.008.065-.02.117-.039.103-.036.255-.091.445-.168.38-.154.906-.391 1.488-.725 1.17-.67 2.525-1.706 3.382-3.19.856-1.484 1.076-3.176 1.072-4.524a12.35 12.35 0 0 0-.12-1.68c-.21.085-.468.196-.756.336-.792.383-1.803.977-2.71 1.82a.535.535 0 0 1-.729-.784c1.009-.938 2.12-1.586 2.972-2a12.804 12.804 0 0 1 1.364-.565l.085-.028.024-.008.007-.002h.003l.155.511.521-.122v.002l.002.003.002.012.009.039.029.144a13.416 13.416 0 0 1 .212 2.318c.005 1.448-.228 3.352-1.215 5.063-.988 1.711-2.521 2.864-3.777 3.584-.632.362-1.204.62-1.619.788a10.605 10.605 0 0 1-.634.234l-.039.012-.01.004h-.004l-.001.001-.146-.482.146.482a.536.536 0 0 1-.31-1.024Zm6.834-11.058.521-.122a.536.536 0 0 0-.675-.39l.154.512Z"
				clipRule="evenodd"
			/>
			<path
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit={10}
				strokeWidth={0.4}
				d="M11.844 17.953s0 0 0 0Zm0 0 .007-.002.028-.01c.025-.008.065-.02.117-.039.103-.036.255-.091.445-.168.38-.154.906-.391 1.488-.725 1.17-.67 2.525-1.706 3.382-3.19.856-1.484 1.076-3.176 1.072-4.524a12.35 12.35 0 0 0-.12-1.68c-.21.085-.468.196-.756.336-.792.383-1.803.977-2.71 1.82a.535.535 0 0 1-.729-.784c1.009-.938 2.12-1.586 2.972-2a12.804 12.804 0 0 1 1.364-.565l.085-.028.024-.008.007-.002h.003m-6.679 11.569a.536.536 0 1 0 .31 1.024m6.37-12.594.154.512m-.154-.512a.536.536 0 0 1 .675.39m-.675-.39.154.512m0 0 .521-.122m-.52.122.52-.122m0 0v.002l.002.003.002.012.009.039.029.144a13.416 13.416 0 0 1 .212 2.318c.005 1.448-.228 3.352-1.215 5.063m0 0-.414-.239m.414.239c-.988 1.711-2.521 2.864-3.777 3.584-.632.362-1.204.62-1.619.788a10.605 10.605 0 0 1-.634.234l-.039.012-.01.004h-.004l-.001.001m0 0-.146-.482.146.482Z"
			/>
			<path
				fill="#000"
				fillRule="evenodd"
				d="m12.19 19.053-.39-.366-.39.366a.535.535 0 0 0 .78 0ZM11.8 5.327l-.39-.366v.001l-.003.003-.008.008-.027.03a10.596 10.596 0 0 0-.433.52c-.275.353-.641.862-1.007 1.492-.728 1.251-1.479 3.016-1.479 4.992s.75 3.74 1.479 4.992c.366.63.732 1.139 1.007 1.492a10.596 10.596 0 0 0 .433.52l.027.03.008.008.002.003.002.001.39-.366.39.366.001-.001.002-.003.008-.008.027-.03.098-.11a13.422 13.422 0 0 0 1.343-1.902c.728-1.251 1.478-3.016 1.478-4.992s-.75-3.74-1.478-4.992a13.426 13.426 0 0 0-1.008-1.492 10.676 10.676 0 0 0-.433-.52l-.027-.03-.008-.008-.002-.003c-.001 0-.001-.001-.391.365Zm0 0-.39-.366a.536.536 0 0 1 .781 0l-.39.366Zm0 .832.018.022c.252.323.59.792.926 1.372.679 1.166 1.334 2.74 1.334 4.454 0 1.713-.655 3.288-1.334 4.454a12.35 12.35 0 0 1-.926 1.372l-.017.022-.018-.022a12.35 12.35 0 0 1-.926-1.372c-.678-1.166-1.334-2.74-1.334-4.454 0-1.713.656-3.288 1.334-4.454.337-.58.674-1.049.926-1.372l.018-.022Z"
				clipRule="evenodd"
			/>
			<path
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit={10}
				strokeWidth={0.4}
				d="m11.8 18.687.39.366m-.39-.366-.39.366m.39-.366-.39.366m.39-.366.39.366m0 0a.535.535 0 0 1-.78 0m.78 0 .002-.001.002-.003.008-.008.027-.03.098-.11a13.422 13.422 0 0 0 1.343-1.902c.728-1.251 1.478-3.016 1.478-4.992s-.75-3.74-1.478-4.992a13.426 13.426 0 0 0-1.008-1.492 10.676 10.676 0 0 0-.433-.52l-.027-.03-.008-.008-.002-.003h0m-.781 14.091-.002-.001-.002-.003-.008-.008-.027-.03a10.596 10.596 0 0 1-.433-.52 13.402 13.402 0 0 1-1.007-1.492c-.728-1.251-1.479-3.016-1.479-4.992s.75-3.74 1.479-4.992c.366-.63.732-1.139 1.007-1.492a10.596 10.596 0 0 1 .433-.52l.027-.03.008-.008.002-.003.002-.001m0 0 .39.366m-.39-.366.39.366m-.39-.366a.536.536 0 0 1 .78 0m-.39.366.39-.366m-.39.366.39-.366m-.373 1.22-.017-.022-.018.022c-.252.323-.589.792-.926 1.372-.678 1.166-1.334 2.74-1.334 4.454 0 1.713.656 3.288 1.334 4.454.337.58.674 1.049.926 1.372l.018.022.017-.022c.252-.323.59-.792.926-1.372.679-1.166 1.334-2.74 1.334-4.454 0-1.713-.655-3.288-1.334-4.454a12.35 12.35 0 0 0-.926-1.372Z"
			/>
		</g>
	</svg>
);
export default SvgYogaStroke;
