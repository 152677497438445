import { Maybe } from '@/shared';
import { useEffect, useState } from 'react';
import { NEVER, Observable } from 'rxjs';
import { Loader } from '@/shared/utils/loader/loader';
import { catchError } from 'rxjs/operators';

type ObserverResult<T> = {
	value: Maybe<T>;
	loader: Loader;
	initializing: boolean;
};

export function useObservable<T>(observable: Observable<T>, initialState?: T): ObserverResult<T> {
	const [state, setState] = useState<Maybe<T>>(initialState);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isInitializing, setIsInitializing] = useState<boolean>(true);

	useEffect(() => {
		if (observable === NEVER) {
			setIsLoading(false);
			setIsInitializing(false);
			return;
		}

		setIsLoading(true);
		const subscription = observable
			.pipe(
				catchError((err) => {
					setIsLoading(false);
					setIsInitializing(false);
					throw err;
				})
			)
			.subscribe({
				next: (currentState) => {
					setState(currentState);
					setIsLoading(false);
					setIsInitializing(false);
				},
				complete: () => {
					setIsLoading(false);
					setIsInitializing(false);
				},
				error: () => {
					setIsLoading(false);
					setIsInitializing(false);
				}
			});

		return () => subscription.unsubscribe();
	}, [observable]);

	return {
		value: state,
		loader: { loading: isLoading },
		initializing: isInitializing
	};
}
