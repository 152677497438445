import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { Theme } from '@mui/material';

type MuiDrawerOverrides = {
	defaultProps?: ComponentsProps['MuiDrawer'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiDrawer'];
	variants?: ComponentsVariants['MuiDrawer'];
};

export const muiDrawer: MuiDrawerOverrides = {
	defaultProps: {
		anchor: 'right',
		PaperProps: {
			sx: { width: { xs: '100%', md: '606px' }, pb: 1 }
		}
	}
};
