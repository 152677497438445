import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTennisStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M12.453 2c5.547 0 10 4.492 10 10 0 5.547-4.492 10-10 10a9.97 9.97 0 0 1-10-10c0-5.508 4.453-10 10-10Zm-1.562 2.07a8.046 8.046 0 0 0-6.368 6.367c1.25 0 2.5-.39 3.594-1.171 1.758-1.25 2.695-3.204 2.774-5.196Zm-6.524 8.242c.156 4.258 3.555 7.657 7.774 7.813 0-3.906 3.046-8.398 8.398-8.398-.117-4.258-3.516-7.657-7.773-7.813 0 3.945-3.047 8.399-8.399 8.399Zm9.727 7.657c3.203-.625 5.703-3.164 6.367-6.367-1.29.039-2.54.39-3.633 1.171a6.503 6.503 0 0 0-2.734 5.196Z"
		/>
	</svg>
);
export default SvgTennisStroke;
