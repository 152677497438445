import { Theme, useMediaQuery } from '@mui/material';

export type ScreenType = 'mobile' | 'desktop';

export const useScreenType = (): ScreenType => {
	const isScreenSmallerThanMediumBreakPoint = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('md')
	);
	return isScreenSmallerThanMediumBreakPoint ? 'mobile' : 'desktop';
};
