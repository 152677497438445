import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowRightLine = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 16 16"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M13.207 2.714c0-.387.327-.714.714-.714a.73.73 0 0 1 .744.714v10c0 .417-.327.715-.744.715a.688.688 0 0 1-.714-.715v-10ZM6.54 4.44c-.297-.267-.297-.714-.03-1.011.268-.298.715-.298 1.013-.03l4.047 3.81a.72.72 0 0 1 0 1.041l-4.047 3.81c-.298.267-.745.267-1.012-.03a.808.808 0 0 1-.179-.506c0-.179.06-.357.208-.506l2.738-2.59H2.046a.688.688 0 0 1-.714-.714c0-.387.298-.714.714-.684h7.232L6.54 4.44Z"
		/>
	</svg>
);
export default SvgArrowRightLine;
