import * as React from 'react';
import type { SVGProps } from 'react';
const SvgQuestionStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M12 2c5.508 0 10 4.492 10 10 0 5.547-4.492 10-10 10A9.97 9.97 0 0 1 2 12C2 6.492 6.453 2 12 2Zm0 18.125c4.453 0 8.125-3.633 8.125-8.125 0-4.453-3.672-8.125-8.125-8.125-4.492 0-8.125 3.672-8.125 8.125A8.119 8.119 0 0 0 12 20.125Zm0-5c.664 0 1.25.547 1.25 1.25s-.586 1.25-1.25 1.25c-.742 0-1.25-.547-1.25-1.25s.547-1.25 1.25-1.25ZM13.29 7c1.562 0 2.773 1.21 2.733 2.734 0 .938-.507 1.836-1.328 2.344l-1.758 1.094v.078a.95.95 0 0 1-.937.938.95.95 0 0 1-.938-.938v-.625c0-.313.157-.625.47-.82l2.226-1.328a.838.838 0 0 0 .43-.743c0-.468-.43-.859-.938-.859h-1.992c-.469 0-.82.39-.82.86a.95.95 0 0 1-.938.937.95.95 0 0 1-.938-.938A2.718 2.718 0 0 1 11.297 7h1.992Z"
		/>
	</svg>
);
export default SvgQuestionStroke;
