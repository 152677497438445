import { Container } from '@mui/system';
import Box from '@mui/material/Box';
import { Flex } from '@/ui/flex/Flex';
import { Discipline, disciplines, getDisciplineMetaData } from '@/shared';
import Typography from '@mui/material/Typography';
import { Divider, Link } from '@mui/material';

import { accent, primary } from '@/ui/theme/components-overrides/colors';
import { WLogoWhite, WorkoutlyLogoWhite } from '@/assets/icons/generated';
import { Facebook, Instagram } from '@/assets/icons/generated/filled';
import { useTranslation } from '@/config/translations/use-translation';
import { useNavigate } from '@/config';
import { useDateFunctions } from '@/shared/utils/helpers/dates/use-date-functions';
import { useCallback } from 'react';
import packageJson from 'root/package.json';

export const Footer = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { formatDate } = useDateFunctions();

	const logo = (
		<Box
			onClick={() => navigate('')}
			sx={{
				display: { xs: 'none', md: 'block' },
				cursor: 'pointer'
			}}>
			<WorkoutlyLogoWhite width={130} height={40} />
		</Box>
	);

	const logoLetter = (
		<Box
			onClick={() => navigate('')}
			sx={{
				display: { xs: 'none', md: 'block' },
				cursor: 'pointer'
			}}>
			<WLogoWhite width={260} height={160} />
		</Box>
	);

	const socialSection = (
		<Flex gap={3} alignItems={'center'}>
			<Typography variant={'p3'} color={accent}>
				{t('shared.follow-us')}
			</Typography>
			<Facebook
				color={primary.contrast}
				cursor={'pointer'}
				onClick={() => window.open('https://www.facebook.com/workoutlyofficial/', '_blank')}
			/>
			<Instagram
				color={primary.contrast}
				cursor={'pointer'}
				onClick={() =>
					window.open('https://www.instagram.com/workoutly_official/', '_blank')
				}
			/>
		</Flex>
	);

	const navigateToListing = useCallback(
		(discipline: Discipline) => {
			navigate({
				pathname: 'listing',
				search: `discipline=${discipline}`
			});
		},
		[navigate]
	);

	const disciplinesSection = (
		<Flex flexDirection={'column'} gap={3}>
			<Typography variant={'h5'} color={accent}>
				{t('shared.disciplines')}
			</Typography>
			<Box display={'grid'} gridTemplateColumns={'repeat(2, 2fr)'} columnGap={5} rowGap={3}>
				{disciplines.map((discipline, index) => {
					const disciplineMetaData = getDisciplineMetaData(discipline);
					return (
						<Typography
							onClick={() => navigateToListing(discipline)}
							key={index}
							variant={'p3'}
							color={primary[300]}
							sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
							{t(disciplineMetaData.translationKey)}
						</Typography>
					);
				})}
			</Box>
		</Flex>
	);

	const commonLinksSection = (
		<Flex flexDirection={'column'} gap={3}>
			<Typography variant={'h5'} color={accent}>
				{t('shared.helpful-links')}
			</Typography>
			<Typography
				onClick={() => navigate('')}
				variant={'p3'}
				color={primary[300]}
				sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
				{t('shared.component.NavbarContent.for-athletes')}
			</Typography>
			<Typography
				onClick={() => navigate('pro')}
				variant={'p3'}
				color={primary[300]}
				sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
				{t('shared.component.NavbarContent.for-professionals')}
			</Typography>
			<Typography
				onClick={() => navigate('privacy-policy')}
				variant={'p3'}
				color={primary[300]}
				sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
				{t('shared.menu.privacy-policy')}
			</Typography>
			<Typography
				onClick={() => navigate('terms-and-conditions')}
				variant={'p3'}
				color={primary[300]}
				sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
				{t('shared.menu.terms-and-conditions')}
			</Typography>
			<Typography
				onClick={() => navigate('faq')}
				variant={'p3'}
				color={primary[300]}
				sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
				{t('shared.menu.frequency-asked-questions')}
			</Typography>
		</Flex>
	);

	const help = (
		<Flex
			gap={{ xs: 1, md: 5 }}
			width={'100%'}
			justifyContent={'space-between'}
			flexDirection={{ xs: 'column', md: 'row' }}>
			<Flex gap={0.5} flexDirection={'row'}>
				<Typography variant={'p4'} color={primary[300]}>
					Copyrights
				</Typography>
				<Typography variant={'p4'} color={primary[300]}>
					{formatDate(new Date(), {
						year: 'numeric'
					})}
				</Typography>
				<Typography variant={'p4'} color={primary[300]}>
					by Workoutly. All Rights Reserved
				</Typography>
			</Flex>
			<Typography variant={'p4'} color={primary[300]}>
				version: {packageJson.version}
			</Typography>
		</Flex>
	);

	const suggestions = (
		<Flex
			gap={{ xs: 1, md: 5 }}
			width={'100%'}
			justifyContent={'space-between'}
			flexDirection={{ xs: 'column', md: 'row' }}>
			<Flex gap={0.5} flexDirection={'row'}>
				<Typography variant={'p4'} color={primary[300]}>
					{t('page.LandingPage.component.Faq.more-question')}
				</Typography>
				<Link href="mailto:office@workoutly.pl" underline="none">
					<Typography
						variant={'p4'}
						color={primary[300]}
						sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
						{t('page.LandingPage.component.Faq.contact-us')}
					</Typography>
				</Link>
			</Flex>
		</Flex>
	);

	const descriptionSection = (
		<Flex flexDirection={'column'} gap={5}>
			<Typography variant={'p3'} color={primary[300]}>
				{t('shared.app-description')}
			</Typography>
			<Divider light sx={{ border: `1px solid ${primary[800]}` }} />
			<Flex flexDirection={'column'} gap={1}>
				<Box>{help}</Box>
				<Box>{suggestions}</Box>
			</Flex>
		</Flex>
	);

	return (
		<Flex flexDirection={'column'} gap={1}>
			<Box
				component="footer"
				sx={{
					padding: { xs: 3, md: '40px 120px 80px 120px' },
					mt: 'auto',
					backgroundColor: primary.black,
					border: `1px solid ${primary[800]}`,
					color: primary[300]
				}}>
				<Container maxWidth="lg">
					<Flex flexDirection={'column'} gap={5}>
						<Flex
							gap={{ xs: 1, md: 5 }}
							width={'100%'}
							justifyContent={'space-between'}
							flexDirection={{ xs: 'column', md: 'row' }}>
							{logo}
							{socialSection}
						</Flex>
						<Divider light sx={{ border: `1px solid ${primary[800]}` }} />
						<Flex
							gap={3}
							width={'100%'}
							justifyContent={'space-between'}
							flexDirection={{ xs: 'column', md: 'row' }}>
							{disciplinesSection}
							{commonLinksSection}
						</Flex>
						<Divider light sx={{ border: `1px solid ${primary[800]}` }} />
						<Flex
							flexDirection={{ xs: 'column', md: 'row' }}
							width={'100%'}
							justifyContent={'space-between'}
							gap={'150px'}
							alignItems={'center'}>
							{logoLetter}
							{descriptionSection}
						</Flex>
					</Flex>
				</Container>
			</Box>
		</Flex>
	);
};
