import { Coordinates, isNil, Maybe, Place } from '@/shared';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;

export type GoogleMapAutocompleteItem = AutocompletePrediction | Coordinates | Place | string;

export const isItemAutocompletePrediction = (
	item: Maybe<GoogleMapAutocompleteItem>
): item is AutocompletePrediction => {
	return !isNil(item) && typeof item !== 'string' && 'place_id' in item;
};

export const isItemCoordinates = (item: Maybe<GoogleMapAutocompleteItem>): item is Coordinates => {
	return !isNil(item) && typeof item !== 'string' && 'longitude' in item && 'latitude' in item;
};

export const isItemPlace = (item: Maybe<GoogleMapAutocompleteItem>): item is Place => {
	return !isNil(item) && typeof item !== 'string' && 'externalId' in item;
};

export const areGoogleMapAutocompleteItemsEqual = (
	item1: Maybe<GoogleMapAutocompleteItem>,
	item2: Maybe<GoogleMapAutocompleteItem>
): boolean => {
	if (isItemAutocompletePrediction(item1) && isItemAutocompletePrediction(item2)) {
		return item1.place_id === item2.place_id;
	} else if (isItemCoordinates(item1) && isItemCoordinates(item2)) {
		return item1.latitude === item2.latitude && item1.longitude === item2.longitude;
	} else if (isItemPlace(item1) && isItemPlace(item2)) {
		return item1.externalId === item2.externalId;
	} else if (typeof item1 === 'string' && typeof item2 === 'string') {
		return item1 === item2;
	}
	return false;
};
