import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBasketballFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M7.781 8.719a6.198 6.198 0 0 1-3.906 1.406 6.015 6.015 0 0 1-1.68-.234c.39-1.68 1.172-3.243 2.305-4.493l3.281 3.32Zm1.797 0a7.464 7.464 0 0 0 1.797-4.844c0-.625-.117-1.21-.273-1.797C11.414 2.04 11.687 2 12 2c2.5 0 4.844.977 6.602 2.54L12 11.14 9.578 8.72Zm.547-4.844c0 1.523-.586 2.89-1.445 3.945L5.36 4.54c1.25-1.134 2.812-1.915 4.492-2.306.156.547.273 1.094.273 1.641ZM8.68 9.617 11.102 12 4.5 18.64C2.937 16.845 2 14.54 2 12c0-.273 0-.547.04-.86a6.95 6.95 0 0 0 1.835.235A7.406 7.406 0 0 0 8.68 9.617Zm5.703 5.703a7.406 7.406 0 0 0-1.758 4.805c0 .664.078 1.25.234 1.836C12.547 22 12.273 22 12 22a9.981 9.981 0 0 1-6.64-2.5L12 12.898l2.383 2.422Zm1.797 0c1.054-.86 2.422-1.445 3.945-1.445.547 0 1.094.117 1.64.273-.39 1.68-1.171 3.243-2.304 4.493l-3.281-3.32Zm3.28-9.922C21.024 7.156 22 9.5 22 12c0 .313-.04.586-.078.898-.586-.156-1.172-.273-1.797-.273a7.464 7.464 0 0 0-4.844 1.797L12.86 12l6.602-6.602Zm-5.585 14.727c0-1.484.547-2.852 1.406-3.906l3.32 3.281c-1.25 1.133-2.812 1.914-4.492 2.305a6.015 6.015 0 0 1-.234-1.68Z"
		/>
	</svg>
);
export default SvgBasketballFilled;
