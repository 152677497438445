import { orange, red } from '@mui/material/colors';

export const primary = {
	25: '#F9F9F9',
	50: '#F2F2F2',
	100: '#E5E5E5',
	200: '#CCCCCC',
	300: '#B2B2B2',
	400: '#999999',
	500: '#7F7F7F',
	600: '#666666',
	700: '#4D4D4D',
	800: '#333333',
	900: '#1A1A1A',
	black: '#000000',
	main: '#1A1A1A',
	contrast: '#FFFFFF'
};

export const secondary = {
	50: '#FFFAE5',
	100: '#FFF5CC',
	200: '#FFF0B2',
	300: '#FFEC99',
	400: '#FFE77F',
	500: '#FFE266',
	600: '#FFDE4D',
	700: '#FFD41A',
	800: '#FFD211',
	900: '#FFCF00',
	main: '#FFCF00'
};

export const accent = '#FFFFFF';

export const success = {
	main: '#2F7C31',
	dark: '#1A5E20',
	light: '#4CAF4F',
	contrast: '#FFFFFF'
};

export const error = red['700'];
export const warning = orange['500'];

export const bgLight = '#F9F9F9';
export const bgDark = '#212121';
export const bgNavbarDark = '#000B11';
