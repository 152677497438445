import { ClientTraining, Discipline, TrainerTraining } from '@/shared';
import { mapClient } from '@/shared/repository/client-mapper';
import { mapTrainer } from '@/shared/repository/trainer-mapper';
import { toLocation } from '@/shared/repository/common-mappers';
import { ClientTrainingResponse, TrainerTrainingResponse } from '@/api';

export const mapTrainerTraining = (response: TrainerTrainingResponse): TrainerTraining => ({
	id: response.id,
	status: response.status,
	discipline: response.discipline.toLowerCase() as Discipline,
	startDate: new Date(response.startDate),
	endDate: new Date(response.endDate),
	location: toLocation(response.location),
	price: {
		value: parseFloat(response.price.value),
		currencyCode: response.price.currencyCode
	},
	trainingCategory: {
		name: response.trainingCategory
	},
	trainingType: response.trainingType,
	clients: response.clients.map((clientResponse) => mapClient(clientResponse))
});

export const mapClientTraining = (response: ClientTrainingResponse): ClientTraining => ({
	id: response.id,
	status: response.status,
	discipline: response.discipline.toLowerCase() as Discipline,
	startDate: new Date(response.startDate),
	endDate: new Date(response.endDate),
	location: toLocation(response.location),
	price: {
		value: parseFloat(response.price.value),
		currencyCode: response.price.currencyCode
	},
	trainingCategory: {
		name: response.trainingCategory
	},
	trainingType: response.trainingType,
	trainer: mapTrainer(response.trainer)
});
