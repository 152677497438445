import LanguageDetector from 'i18next-browser-languagedetector';
import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '@/config/translations/en.json';
import pl from '@/config/translations/pl.json';

use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en_GB',
		defaultNS: 'common',
		lng: 'pl_PL',

		resources: {
			en_GB: {
				common: en
			},
			pl_PL: {
				common: pl
			}
		}
	});
