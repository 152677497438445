import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBoxingStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M18.54 7.117C19.944 7.43 21 8.641 21 10.125v1.602a4.345 4.345 0 0 1-1.29 3.085l-2.46 2.461V19.5c0 1.406-1.133 2.5-2.5 2.5h-7.5a2.468 2.468 0 0 1-2.5-2.5l-.04-3.438A3.81 3.81 0 0 1 3.5 13.25v-7.5C3.5 3.719 5.18 2 7.21 2h7.54c2.031 0 3.71 1.719 3.71 3.75l.04 1.328s.04 0 .04.04Zm.585 4.61v-1.602c0-.664-.586-1.25-1.25-1.25h-2.188c-.898 0-1.562.703-1.562 1.563 0 .898.664 1.562 1.563 1.562a.95.95 0 0 1 .937.938c0 .546-.43.937-.938.937-1.718 0-3.085-1.21-3.398-2.813h-.937c-1.993 0-5.04-.976-5.079-2.187 0-.508.43-.938.977-.938.625 0 1.836 1.25 3.867 1.25h1.367C12.992 7.938 14.204 7 15.688 7h.937V5.75c0-1.016-.86-1.875-1.875-1.875h-7.5c-1.055 0-1.875.86-1.875 1.875v7.5c0 .547.195 1.055.586 1.406l.664.547V19.5c0 .352.273.625.625.625h7.5a.642.642 0 0 0 .625-.625v-3.008l3.008-3.008a2.485 2.485 0 0 0 .742-1.757ZM13.5 16.062c0 .235-.117.43-.313.547l-1.093.703 1.094.743a.623.623 0 0 1 .312.547c0 .312-.313.585-.625.585-.156 0-.273 0-.352-.078L11 18.094l-1.563 1.015c-.078.078-.195.078-.312.078-.352 0-.664-.273-.625-.585 0-.235.078-.43.273-.547l1.094-.742-1.094-.704a.623.623 0 0 1-.312-.547c0-.312.312-.585.664-.585.117 0 .234 0 .313.078L11 16.57l1.523-1.054c.079-.079.196-.079.313-.079.351 0 .664.274.664.626Z"
		/>
	</svg>
);
export default SvgBoxingStroke;
