import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHockeyStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M12.5 5c4.96 0 10 1.523 10 4.688v5.156c0 3.398-5.04 5.156-10 5.156-5 0-10-1.758-10-5.195V9.453C2.5 6.29 7.5 5 12.5 5Zm8.125 9.844v-2.227c-1.563 1.016-4.219 1.758-8.164 1.758-3.984 0-6.563-.703-8.086-1.758v2.227c0 1.797 3.71 3.281 8.125 3.281 4.375 0 8.125-1.484 8.125-3.281ZM12.5 12.5c4.453 0 8.125-1.25 8.125-2.813 0-1.289-3.281-2.812-8.125-2.812-4.883 0-8.125 1.523-8.125 2.813 0 1.562 3.633 2.812 8.125 2.812Z"
		/>
	</svg>
);
export default SvgHockeyStroke;
