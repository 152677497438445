import { Tab, Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { MenuItem, useNavigate, useRouteConfig, useTranslation } from '@/config';
import { isNil } from '@/shared';
import { getTabConfig } from '@/config/layout/navigation/sub-navbar/sub-navbar-tab-config';

export const SubNavbarTabs = () => {
	const { t } = useTranslation();
	const { pathConfig } = useRouteConfig();
	const navigate = useNavigate();

	const [currentTab, setCurrentTab] = useState<MenuItem>();

	useEffect(() => {
		setCurrentTab(pathConfig?.subNavbarTabs ? getTabConfig(pathConfig?.path) : undefined);
	}, [pathConfig, pathConfig?.subNavbarTabs]);

	const handleTabChange = (tabConfig: MenuItem) => {
		setCurrentTab(getTabConfig(tabConfig.route.path));
	};

	return (
		<>
			{!isNil(currentTab) && (
				<Tabs
					sx={{ width: 'fit-content' }}
					value={currentTab}
					onChange={(_, tabConfig: MenuItem) => handleTabChange(tabConfig)}>
					{pathConfig?.subNavbarTabs?.map((item, idx) => (
						<Tab
							onClick={() => item.route.path && navigate(item.route.path)}
							key={idx}
							value={item}
							disableRipple={true}
							sx={{ padding: '6px 12px' }}
							label={<Typography variant={'h8'}>{t(item.name)}</Typography>}
						/>
					))}
				</Tabs>
			)}
		</>
	);
};
