import * as React from 'react';
import type { SVGProps } from 'react';
const SvgKettlebellFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 16 20"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M12.58 7.805c.922-.94 2.153-2.555 1.78-4.184C13.814.742 9.443.71 7.996.7 6.557.71 2.187.74 1.64 3.62c-.372 1.631.859 3.245 1.78 4.185A7.042 7.042 0 0 0 .8 13.283c0 2.384 1.204 4.595 3.22 5.916a.617.617 0 0 0 .338.101h7.286a.609.609 0 0 0 .338-.101c2.015-1.32 3.219-3.532 3.219-5.916a7.046 7.046 0 0 0-2.62-5.478Zm-1.326-1.767c-.28.306-.5.5-.73.708-.181.163-.386.348-.66.62a.619.619 0 0 1-.569.164 6.159 6.159 0 0 0-2.594 0 .617.617 0 0 1-.566-.164c-.274-.273-.481-.457-.662-.62-.23-.207-.448-.404-.726-.705-.607-.672-.915-1.246-.915-1.71 0-.921 1.052-1.145 1.618-1.264.69-.131 1.591-.2 2.55-.202.958 0 1.86.07 2.537.199.579.122 1.63.346 1.63 1.268 0 .463-.305 1.038-.912 1.706h-.001Z"
		/>
	</svg>
);
export default SvgKettlebellFilled;
