import { FC } from 'react';
import { Flex } from '@/ui/flex/Flex';
import { WorkoutlyLogoBlack } from '@/assets/icons/generated';
import { Container } from '@mui/system';
import { useNavigate, useTranslation } from '@/config';
import { IconButton } from '@/ui/icon-button/IconButton';
import SvgChevronLeftStroke from '@/assets/icons/generated/stroke/ChevronLeftStroke';
import { primary } from '@/ui/theme/components-overrides/colors';
import Typography from '@mui/material/Typography';

export const NonApplicationContextNavbarContent: FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Container maxWidth="xl">
			<Flex py={'20px'} justifyContent={'center'} alignItems={'center'}>
				<Flex
					width={'100%'}
					justifyContent={'flex-start'}
					gap={1}
					alignItems={'center'}
					sx={{ textDecoration: 'underline' }}>
					<Flex gap={1} sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
						<IconButton variant={'secondary'} size={'small'}>
							<SvgChevronLeftStroke
								width={'16px'}
								height={'16px'}
								color={primary.black}
							/>
						</IconButton>
						<Typography variant={'h6'}>{t('shared.back')}</Typography>
					</Flex>
				</Flex>
				<Flex
					width={'100%'}
					justifyContent={'flex-start'}
					marginLeft={'-100px'}
					marginRight={'auto'}>
					<WorkoutlyLogoBlack
						cursor={'pointer'}
						onClick={() => navigate('')}
						width={'177px'}
						height={'40px'}
					/>
				</Flex>
			</Flex>
		</Container>
	);
};
