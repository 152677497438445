import { Children, ReactNode } from 'react';

// TODO create generic object which takes an id as prop and pass it as a key
type EachProps<T> = {
	of: T[];
	render: (value: T, index: number) => ReactNode;
};

export const Each = <T,>(props: EachProps<T>) => {
	return <>{Children.toArray(props.of.map((item: T, index) => props.render(item, index)))}</>;
};
