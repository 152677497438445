import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { primary } from '@/ui/theme/components-overrides/colors';
import { zIndex } from '@/shared';

type MuiTooltipOverrides = {
	defaultProps?: ComponentsProps['MuiTooltip'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiTooltip'];
	variants?: ComponentsVariants['MuiTooltip'];
};

export const muiTooltip: MuiTooltipOverrides = {
	defaultProps: {
		followCursor: true,
		arrow: true
	},
	styleOverrides: {
		tooltip: {
			backgroundColor: primary['900']
		},
		popper: {
			zIndex: zIndex.tooltip
		}
	}
};
