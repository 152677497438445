import { FC, ReactNode, useMemo } from 'react';
import { Flex } from '@/ui/flex/Flex';
import { rgba } from 'polished';
import Box from '@mui/material/Box';
import { error, primary, success, warning } from '@/ui/theme/components-overrides/colors';
import SvgQuestionFilled from '@/assets/icons/generated/filled/QuestionFilled';
import SvgCheckCircleDefault from '@/assets/icons/generated/filled/CheckCircleDefault';
import SvgWarning from '@/assets/icons/generated/filled/Warning';
import SvgError from '@/assets/icons/generated/filled/Error';

export type StatusIconType = 'success' | 'error' | 'warning' | 'info';

type StatusIconProps = {
	type: StatusIconType;
	iconSize?: number;
	size?: number;
};

export const StatusIcon: FC<StatusIconProps> = ({ type, iconSize = 14, size = 20 }) => {
	const icon = useMemo((): ReactNode => {
		switch (type) {
			default:
			case 'info':
				return (
					<SvgQuestionFilled color={primary.black} width={iconSize} height={iconSize} />
				);
			case 'success':
				return (
					<SvgCheckCircleDefault
						color={success.light}
						width={iconSize}
						height={iconSize}
					/>
				);
			case 'warning':
				return <SvgWarning color={warning} width={iconSize} height={iconSize} />;
			case 'error':
				return <SvgError color={error} width={iconSize} height={iconSize} />;
		}
	}, [type, iconSize]);

	const iconColor = useMemo((): string => {
		switch (type) {
			default:
			case 'info':
				return primary.main;
			case 'success':
				return success.light;
			case 'warning':
				return warning;
			case 'error':
				return error;
		}
	}, [type]);

	return (
		<Box
			sx={{
				cursor: 'pointer'
			}}>
			<Flex
				alignItems={'center'}
				justifyContent={'center'}
				sx={{
					width: size,
					height: size,
					backgroundColor: rgba(iconColor, 0.2),
					borderRadius: '100%'
				}}>
				{icon}
			</Flex>
		</Box>
	);
};
