import { FC, useCallback, useEffect, useState } from 'react';
import { NonApplicationContextNavbarContent } from '@/config/layout/navigation/helpers/NonApplicationContextNavbarContent';
import { NavbarContent } from '@/config/layout/navigation/helpers/NavbarContent';
import { AppBar, LinearProgress, Slide, useScrollTrigger } from '@mui/material';
import { SubNavbar } from '@/config/layout/navigation/sub-navbar/SubNavbar';
import { LayoutType, useRouteConfig } from '@/config';
import { isNil, useScreenType, zIndex } from '@/shared';
import { MobileNavbar } from '@/config/layout/navigation/helpers/mobile/MobileNavbar';
import { useAuth0 } from '@auth0/auth0-react';
import { bgNavbarDark, primary, secondary } from '@/ui/theme/components-overrides/colors';

type NavbarProps = {
	layoutType?: LayoutType;
	loading?: boolean;
};

export const Navbar: FC<NavbarProps> = ({ layoutType = 'application', loading = false }) => {
	const [stickyPosition, setStickyPosition] = useState<boolean>(false);
	const [currentScrollY, setCurrentScrollY] = useState<number>(0);
	const { pathConfig } = useRouteConfig();
	const trigger = useScrollTrigger();
	const screenType = useScreenType();
	const { isAuthenticated } = useAuth0();

	const handleScroll = useCallback(() => {
		const newScrollY = window.scrollY;

		// If scrolling down, make AppBar static
		if (newScrollY > currentScrollY && stickyPosition) {
			setStickyPosition(false);
		}
		// If scrolled up, make AppBar sticky
		else if (newScrollY < currentScrollY && !stickyPosition) {
			setStickyPosition(true);
		}

		setCurrentScrollY(newScrollY);
	}, [currentScrollY, stickyPosition]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [handleScroll, currentScrollY, stickyPosition]);

	const getAppBarBgColor = (): string => {
		switch (layoutType) {
			case 'landing':
				return bgNavbarDark;
			case 'landing-pro':
				return secondary.main;
			default:
				return primary.contrast;
		}
	};

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			<AppBar
				position={stickyPosition ? 'sticky' : 'static'}
				sx={{
					zIndex: zIndex.navbar,
					minHeight: { lg: '80px' },
					backgroundColor: getAppBarBgColor(),
					boxShadow:
						layoutType === 'landing' || layoutType === 'landing-pro' ? 'none' : ''
				}}>
				{loading && <LinearProgress color={'secondary'} />}
				{screenType === 'mobile' ? (
					<MobileNavbar />
				) : (
					<>
						{layoutType === 'non-application-context' || layoutType === 'sign-up' ? (
							<NonApplicationContextNavbarContent />
						) : (
							<NavbarContent />
						)}
						{!isNil(pathConfig?.subNavbarTabs) &&
							pathConfig?.secured === isAuthenticated && <SubNavbar />}
					</>
				)}
			</AppBar>
		</Slide>
	);
};
