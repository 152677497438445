import { forwardRef, ForwardRefRenderFunction, PropsWithChildren } from 'react';
import { Flex } from '@/ui/flex/Flex';

const DrawerBodyContainer: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren<{}>> = (
	{ children },
	ref
) => {
	return (
		<Flex
			ref={ref}
			flexDirection={'column'}
			height={'100%'}
			overflow={'auto'}
			px={2}
			pt={'20px'}
			pb={1}>
			{children}
		</Flex>
	);
};

export default forwardRef(DrawerBodyContainer);
