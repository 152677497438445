import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBadmintonFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M21.37 9.485c.623 0 1.13.507 1.13 1.092v2.144c0 .43-.273.819-.663.975l-9.746 4.522-1.52 1.52-5.849-5.808 1.52-1.52 4.523-9.747C10.96 2.273 11.35 2 11.78 2h2.105a1.09 1.09 0 0 1 1.092 1.092v.818h-.117c-.507 0-2.106.04-2.651 1.716l-1.092 3.547-1.793 1.053-1.404 3.002 1.014 1.014 2.729-2.73 1.715-5.496c.156-.507.624-.897 1.17-.897h3.703a1.09 1.09 0 0 1 1.092 1.092v3.664c0 .546-.39 1.014-.897 1.17l-5.497 1.754-2.729 2.73.975 1.013 3.04-1.404 1.053-1.754 3.548-1.13c1.638-.507 1.755-1.989 1.755-2.574v-.195h.78Zm-17.622 5.42.078-.117 5.847 5.808-.077.117a4.291 4.291 0 0 1-2.963 1.209c-2.34 0-4.133-1.95-4.133-4.133a3.99 3.99 0 0 1 1.248-2.885Z"
		/>
	</svg>
);
export default SvgBadmintonFilled;
