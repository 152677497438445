import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRunningStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M9.422 14.383c.273.43.625.82 1.094 1.094l.39.234-.547 1.25a2.189 2.189 0 0 1-1.992 1.289h-2.93c-.546 0-.937-.39-.937-.938 0-.507.39-.937.938-.937h2.93c.116 0 .234-.04.273-.156l.78-1.836Zm5.703-8.633a1.851 1.851 0 0 1-1.875-1.875c0-1.016.82-1.875 1.875-1.875C16.141 2 17 2.86 17 3.875c0 1.055-.86 1.875-1.875 1.875Zm4.688 5.664a.95.95 0 0 1 .937.938c0 .507-.43.937-.977.937h-1.875a2.133 2.133 0 0 1-2.07-1.562l-.547-1.797c-.078-.157-.156-.313-.273-.47l-1.64 4.102 2.03 1.211c.665.43 1.055 1.133 1.055 1.875 0 .196-.039.43-.078.625l-1.29 4.063c-.116.43-.507.664-.898.664-.703 0-.937-.625-.937-.938 0-.078 0-.156.04-.234l1.288-4.062v-.118a.338.338 0 0 0-.156-.273l-3.281-1.953c-.664-.43-1.055-1.133-1.055-1.875 0-.274.078-.547.195-.82l1.367-3.438-.585-.117c-.118 0-.235-.04-.352-.04a1.4 1.4 0 0 0-.938.352L7.86 9.93a.933.933 0 0 1-.546.195c-.586 0-.938-.469-.938-.938 0-.273.117-.546.352-.742L8.602 7c.625-.469 1.367-.742 2.109-.742.234 0 .508.039.781.078l3.047.703c1.211.274 2.149 1.172 2.54 2.344l.507 1.797c.039.156.195.234.312.234h1.915Z"
		/>
	</svg>
);
export default SvgRunningStroke;
