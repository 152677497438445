import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useTranslation } from '@/config';

export type Culture = 'en_GB' | 'pl_PL';
export const defaultCulture: Culture = 'en_GB';

const cultureContext = createContext<{
	culture: Culture;
	changeCulture: (culture: Culture) => void;
}>({
	culture: defaultCulture,
	changeCulture: () => {}
});

export const useCulture = () => {
	return useContext(cultureContext);
};

export const CultureProvider: FC<PropsWithChildren<{}>> = (props) => {
	const { i18n } = useTranslation();

	const contextValue = {
		culture: i18n.language as Culture,
		changeCulture: (newCulture) => i18n.changeLanguage(newCulture)
	};

	return <cultureContext.Provider value={contextValue}>{props.children}</cultureContext.Provider>;
};
