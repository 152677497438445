import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { CSSProperties } from 'react';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { primary } from '@/ui/theme/components-overrides/colors';

declare module '@mui/material/styles' {
	interface TypographyVariants {
		p1: CSSProperties;
		p2: CSSProperties;
		p3: CSSProperties;
		p4: CSSProperties;
		h7: CSSProperties;
		h8: CSSProperties;
		h9: CSSProperties;
		span: CSSProperties;
		h1Landing: CSSProperties;
		h2Landing: CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		p1?: CSSProperties;
		p2?: CSSProperties;
		p3?: CSSProperties;
		p4?: CSSProperties;
		h7?: CSSProperties;
		h8?: CSSProperties;
		h9?: CSSProperties;
		span?: CSSProperties;
		h1Landing: CSSProperties;
		h2Landing: CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		p1: true;
		p2: true;
		p3: true;
		p4: true;
		h7: true;
		h8: true;
		h9: true;
		span: true;
		h1Landing: true;
		h2Landing: true;
	}
}

type MuiTypographyOverrides = {
	defaultProps?: ComponentsProps['MuiTypography'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiTypography'];
	variants?: ComponentsVariants['MuiTypography'];
};

export const p1 = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '20px',
	lineHeight: '32px',
	fontWeight: 400,
	color: primary.black,
	letterSpacing: 0,
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '18px',
		lineHeight: '24px'
	}
});

export const p2 = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '16px',
	lineHeight: '24px',
	color: primary.black,
	fontWeight: 400,
	letterSpacing: 0,
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '16px',
		lineHeight: '24px'
	}
});

export const p3 = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '14px',
	lineHeight: '24px',
	color: primary.black,
	fontWeight: 400,
	letterSpacing: 0,
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '14px',
		lineHeight: '16px'
	}
});

export const p4 = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '12px',
	lineHeight: '16px',
	color: primary.black,
	fontWeight: 400,
	letterSpacing: 0,
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '12px',
		lineHeight: '16px'
	}
});

export const span = {
	color: primary.black,
	letterSpacing: 0
} as TypographyStyleOptions;

export const h1 = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '64px',
	lineHeight: '72px',
	fontWeight: 500,
	color: primary.black,
	letterSpacing: 0,
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '32px',
		lineHeight: '48px'
	}
});

export const h1Landing = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '64px',
	lineHeight: '72px',
	fontWeight: 600,
	color: primary.black,
	letterSpacing: '-2px',
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '40px',
		lineHeight: '48px',
		letterSpacing: '0px'
	}
});

export const h2Landing = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '64px',
	lineHeight: '72px',
	fontWeight: 600,
	color: primary.black,
	letterSpacing: '-2px',
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '32px',
		lineHeight: '40px',
		letterSpacing: '0px'
	}
});

export const h2 = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '48px',
	lineHeight: '56px',
	fontWeight: 500,
	color: primary.black,
	letterSpacing: 0,
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '28px',
		lineHeight: '40px'
	}
});

export const h3 = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '32px',
	lineHeight: '40px',
	color: primary.black,
	fontWeight: 500,
	letterSpacing: 0,
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '24px',
		lineHeight: '32px'
	}
});

export const h4 = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '24px',
	lineHeight: '32px',
	fontWeight: 500,
	color: primary.black,
	letterSpacing: 0,
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '20px',
		lineHeight: '24px'
	}
});

export const h5 = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '20px',
	lineHeight: '32px',
	color: primary.black,
	fontWeight: 500,
	letterSpacing: 0,
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '18px',
		lineHeight: '24px'
	}
});

export const h6 = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '16px',
	lineHeight: '24px',
	color: primary.black,
	fontWeight: 500,
	letterSpacing: 0,
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '16px',
		lineHeight: '20px'
	}
});

export const h7 = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '14px',
	lineHeight: '16px',
	color: primary.black,
	fontWeight: 500,
	letterSpacing: 0,
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '14px',
		lineHeight: '16px'
	}
});

export const h8 = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '12px',
	lineHeight: '16px',
	color: primary.black,
	fontWeight: 500,
	letterSpacing: 0,
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '12px',
		lineHeight: '16px'
	}
});

export const h9 = (theme: Theme): TypographyStyleOptions => ({
	fontSize: '10px',
	lineHeight: '12px',
	color: primary.black,
	letterSpacing: 0,
	fontWeight: 500,
	[theme.breakpoints.down('md')]: {
		// mobile
		fontSize: '10px'
	}
});

export const muiTypography: MuiTypographyOverrides = {
	defaultProps: {
		fontFamily: 'Matter',
		textTransform: 'none',
		variantMapping: {
			p2: 'p',
			p3: 'p',
			p4: 'p',
			h7: 'p',
			h1Landing: 'p',
			h2Landing: 'p',
			span: 'span'
		}
	}
};
