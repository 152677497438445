import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGymFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M5.075 6.2c.447 0 .825.378.825.825v9.35a.814.814 0 0 1-.825.825h-1.65c-.481 0-.825-.344-.825-.825L2.566 12.8c-.585 0-1.066-.481-1.066-1.1 0-.584.481-1.1 1.1-1.1V7.025c0-.447.344-.825.825-.825h1.65Zm12.1-2.2c.447 0 .825.378.825.825v13.75a.814.814 0 0 1-.825.825h-1.65c-.481 0-.825-.344-.825-.825V12.8h-4.4v5.775a.814.814 0 0 1-.825.825h-1.65c-.481 0-.825-.344-.825-.825V4.825C7 4.378 7.344 4 7.825 4h1.65c.447 0 .825.378.825.825V10.6h4.4V4.825c0-.447.344-.825.825-.825h1.65Zm5.225 6.6c.584 0 1.1.516 1.1 1.1 0 .619-.516 1.1-1.1 1.1v3.575a.814.814 0 0 1-.825.825h-1.65c-.481 0-.825-.344-.825-.825v-9.35c0-.447.344-.825.825-.825h1.65c.447 0 .825.378.825.825V10.6Z"
		/>
	</svg>
);
export default SvgGymFilled;
