import { FC } from 'react';
import { Balance, formatPrice } from '@/shared';
import { accent, primary } from '@/ui/theme/components-overrides/colors';
import { AppTypeSwitcher } from '@/ui/app-switcher/AppTypeSwitcher';
import { useDrawer } from '@/ui/drawer/drawer-context';
import { Flex } from '@/ui/flex/Flex';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { TrainerBalanceContextProvider, useCulture, useNavigate, useTranslation } from '@/config';
import { useDateFunctions } from '@/shared/utils/helpers/dates/use-date-functions';
import { useTrainerBalance } from '@/features/me/trainer/shared/hooks/use-trainer-balance';
import { FallbackProps } from '@/config/hoc/with-fallback';
import { useAppType } from '@/config/app-type/appTypeContext';

export const TrainerDetails: FC<FallbackProps> = (fallbackProps) => {
	const { closeAll: closeAllDrawers } = useDrawer();
	const { t } = useTranslation();
	const { formatDate } = useDateFunctions();
	const navigate = useNavigate();
	const { appType } = useAppType();
	const { culture } = useCulture();

	const { trainerBalance } = useTrainerBalance(fallbackProps);
	const availableAndPendingPLNBalance: Balance = {
		value:
			(trainerBalance?.available.find((available) => available.currencyCode === 'PLN')
				?.value || 0) +
			(trainerBalance?.pending.find((available) => available.currencyCode === 'PLN')?.value ||
				0),
		currencyCode: 'PLN'
	};

	return (
		<>
			<AppTypeSwitcher
				onChangeType={() => closeAllDrawers()}
				logoColor={'black'}
				sx={{
					backgroundColor: accent,
					flexDirection: 'row-reverse',
					justifyContent: 'space-between'
				}}
			/>
			{appType === 'pro' && (
				<TrainerBalanceContextProvider>
					<Flex
						gap={1}
						padding={2}
						bgcolor={accent}
						borderRadius={'4px'}
						justifyContent={'space-between'}>
						<Flex flexDirection={'column'} gap={1}>
							<Flex flexDirection={'column'}>
								<Typography variant={'h7'}>
									{t('shared.component.UserDetails.account-status')}
								</Typography>
								<Typography variant={'h4'}>
									{formatPrice(availableAndPendingPLNBalance, culture)}
								</Typography>
							</Flex>
							<Typography variant={'p3'} color={primary['600']}>
								{t('shared.component.UserDetails.for-day', {
									date: formatDate(new Date(), {
										year: 'numeric',
										month: 'long',
										day: 'numeric'
									})
								})}
							</Typography>
						</Flex>
						<Flex flexDirection={'column'} gap={1}>
							<Button
								size={'small'}
								disabled={availableAndPendingPLNBalance!.value === 0}
								onClick={() => {
									navigate('cashout');
									closeAllDrawers();
								}}>
								<Typography variant={'h8'}>
									{t('shared.component.UserDetails.withdraw')}
								</Typography>
							</Button>
							<Button
								variant={'secondary-outline'}
								size={'small'}
								onClick={() => {
									navigate('me/trainer/settlements');
									closeAllDrawers();
								}}>
								<Typography variant={'h8'}>
									{t('shared.component.UserDetails.settlement')}
								</Typography>
							</Button>
						</Flex>
					</Flex>
				</TrainerBalanceContextProvider>
			)}
		</>
	);
};
