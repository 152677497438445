import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPinStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M15.13 9.511c0 1.76-1.409 3.13-3.13 3.13-1.76 0-3.13-1.37-3.13-3.13 0-1.721 1.37-3.13 3.13-3.13a3.139 3.139 0 0 1 3.13 3.13ZM12 8.259a1.26 1.26 0 0 0-1.252 1.252c0 .704.548 1.252 1.252 1.252a1.26 1.26 0 0 0 1.252-1.252c0-.665-.587-1.252-1.252-1.252Zm7.511 1.252c0 3.443-4.577 9.506-6.611 12.049a1.18 1.18 0 0 1-1.839 0C9.027 19.017 4.49 12.954 4.49 9.51A7.488 7.488 0 0 1 12 2a7.513 7.513 0 0 1 7.511 7.511Zm-7.51-5.633A5.635 5.635 0 0 0 6.366 9.51c0 .509.156 1.252.587 2.269.391.939.978 2.034 1.643 3.13 1.134 1.799 2.425 3.52 3.403 4.772.94-1.252 2.23-2.973 3.365-4.772.665-1.096 1.251-2.191 1.643-3.13.43-1.017.626-1.76.626-2.269 0-3.09-2.543-5.633-5.634-5.633Z"
		/>
	</svg>
);
export default SvgPinStroke;
