import { useAuth0 } from '@auth0/auth0-react';
import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { useSelfClientRepository } from '@/shared/repository/client/use-self-client-repository';
import { isNil, Loader, Maybe, MeClient, useObservable } from '@/shared';
import { CreateClientRequest } from '@/api';
import { NEVER } from 'rxjs';
import { useToken } from '@/config';

type ClientContextProperties = {
	me: Maybe<MeClient>;
	loader: Loader;
};

export const clientContext = createContext<ClientContextProperties>({
	me: undefined,
	loader: {
		loading: false
	}
});

/**
 * @deprecated
 * use useClient from shared/hooks
 */
export const useClient = () => {
	return useContext(clientContext);
};

export const ClientProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const { tokenReady } = useToken();
	const { getOrCreateMe } = useSelfClientRepository();
	const { user, isAuthenticated } = useAuth0();

	const me$ = useMemo(() => {
		if (tokenReady && isAuthenticated && !isNil(user) && user.email) {
			const defaultAvatarURL = `https://avatar.oxro.io/avatar.svg?name=${
				user.email[0] + '+' + user.email[1]
			}&background=FFCF00&color=000000&length=2&caps=1`;

			const sex =
				user.gender?.toLowerCase() === 'male'
					? 'MALE'
					: user.gender?.toLowerCase() === 'female'
					? 'FEMALE'
					: undefined;

			const req: CreateClientRequest = {
				name: user.given_name,
				surname: user.family_name,
				email: user.email,
				phoneNumber: user.phone_number,
				avatarUrl: user.picture ?? defaultAvatarURL,
				sex: sex
			};
			return getOrCreateMe(req);
		}
		return NEVER;
	}, [getOrCreateMe, isAuthenticated, tokenReady, user]);

	const { value: me, loader } = useObservable<MeClient>(me$);

	const contextValue = useMemo<ClientContextProperties>(
		() => ({
			me: me,
			loader
		}),
		[loader, me]
	);

	return <clientContext.Provider value={contextValue}>{children}</clientContext.Provider>;
};
