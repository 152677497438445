import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHomeStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 20 20"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M18.091 9.137c.29.26.319.695.087.985a.722.722 0 0 1-.984.087l-.695-.609v5.386a2.304 2.304 0 0 1-2.316 2.316H5.845a2.287 2.287 0 0 1-2.316-2.316V9.6l-.724.609a.722.722 0 0 1-.985-.087.722.722 0 0 1 .087-.985l7.644-6.485a.738.738 0 0 1 .897 0l7.643 6.485ZM5.845 15.912h1.39v-4.4c0-.638.492-1.159 1.158-1.159h3.242c.637 0 1.158.521 1.158 1.158v4.401h1.39a.933.933 0 0 0 .926-.927V8.413L10.014 4.1 4.918 8.413v6.572c0 .522.406.927.927.927Zm2.78 0h2.778v-4.17H8.624v4.17Z"
		/>
	</svg>
);
export default SvgHomeStroke;
