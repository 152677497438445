import * as React from 'react';
import type { SVGProps } from 'react';
const SvgClimbingStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M17.716 5.378a6.314 6.314 0 0 0-5.31-2.878 6.346 6.346 0 0 0-6.338 6.339v.496h-.281a.535.535 0 0 0-.535.535v4.325c0 .295.24.535.535.535h.28v2.12a4.654 4.654 0 0 0 4.65 4.65 4.65 4.65 0 0 0 4.252-2.771l3.236-7.33a6.312 6.312 0 0 0-.49-6.019v-.002ZM6.32 13.66v-1.118H9.11v1.118H6.32Zm3.036 1.07h.287c.295 0 .535-.24.535-.535V9.869a.535.535 0 0 0-.535-.535h-.287v-.497a3.052 3.052 0 0 1 3.048-3.048c1.035 0 1.99.518 2.555 1.385.565.866.652 1.95.235 2.895l-3.236 7.329a1.36 1.36 0 0 1-2.602-.549v-2.12Zm-.247-4.326v1.068H6.32v-1.068H9.11Zm-.822 4.326v1.135h-1.15v-1.135h1.15Zm8.938-3.763-3.236 7.329a3.584 3.584 0 0 1-3.275 2.134 3.582 3.582 0 0 1-3.576-3.496h1.153a2.43 2.43 0 0 0 2.425 2.346 2.43 2.43 0 0 0 2.221-1.449l3.236-7.329a4.103 4.103 0 0 0-.317-3.911 4.106 4.106 0 0 0-3.45-1.87 4.122 4.122 0 0 0-4.118 4.117v.497H7.14v-.497a5.272 5.272 0 0 1 5.268-5.268 5.25 5.25 0 0 1 4.414 2.393 5.252 5.252 0 0 1 .406 5.004h-.002Z"
		/>
	</svg>
);
export default SvgClimbingStroke;
