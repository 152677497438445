import { ComponentsProps } from '@mui/material/styles/props';
import { primary, secondary } from '@/ui/theme/components-overrides/colors';
import { ComponentsOverrides } from '@mui/material/styles';
import { Theme } from '@mui/material';

type MuiPickersYearOverrides = {
	// @ts-ignore
	defaultProps?: ComponentsProps['MuiPickersYears'];
	// @ts-ignore
	styleOverrides?: ComponentsOverrides<Theme>['MuiPickersYear'];
};

export const muiPickersYear: MuiPickersYearOverrides = {
	styleOverrides: {
		root: {
			'&.MuiPickersYear-root': {
				'& .MuiPickersYear-yearButton.Mui-selected': {
					color: primary['900'],
					backgroundColor: secondary['900']
				}
			}
		}
	}
};
