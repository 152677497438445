import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';

type MuiLinkOverrides = {
	defaultProps?: ComponentsProps['MuiLink'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiLink'];
	variants?: ComponentsVariants['MuiLink'];
};

export const muiLink: MuiLinkOverrides = {
	styleOverrides: {
		root: {
			cursor: 'pointer',
			color: 'inherit'
		}
	}
};
