import {
	areLocationsEqual,
	Discipline,
	isNil,
	Location,
	Maybe,
	Offer,
	RangeDate,
	TrainingType
} from '@/shared';
import { TrainingCategory } from '@/shared/model/Training';
import { eachDayOfInterval, isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';
import { CalendarOffer, CalendarOfferQuery } from '@/shared/model/CalendarOffer';

export type OfferTime = {
	hour: number;
	minute: number;
};

export type CalendarDailyOffers = {
	date: Date;
	calendarOffers: CalendarOffer[];
};

export type DailyOffers = {
	date: Date;
	offers: Offer[];
};

export type CalendarOfferFilters = {
	discipline?: Maybe<Discipline>;
	location?: Maybe<Location>;
	trainingCategory?: Maybe<TrainingCategory>;
	trainingType?: Maybe<TrainingType>;
	offer?: Maybe<Offer>;
};

export const DEFAULT_NUMBER_OF_ENTRIES_IN_ONE_DAY = 5;

export const sortOfferTimes = (offerTimes: OfferTime[]): OfferTime[] => {
	return offerTimes.sort((a, b) => {
		if (a.hour !== b.hour) {
			return a.hour - b.hour;
		} else {
			return a.minute - b.minute;
		}
	});
};

const filterOffersByTrainingCategory = (
	offers: Offer[],
	trainingCategory: TrainingCategory
): Offer[] => offers.filter((offer) => offer.trainingCategory.name === trainingCategory.name);

const filterOffersByTrainingType = (offers: Offer[], trainingType: TrainingType): Offer[] =>
	offers.filter((offer) => offer.trainingType === trainingType);

const filterOffersByDiscipline = (offers: Offer[], discipline: Discipline): Offer[] =>
	offers.filter((offer) => offer.discipline === discipline);

const filterOffersByLocation = (offers: Offer[], location: Location): Offer[] =>
	offers.filter((offer) => areLocationsEqual(offer.location, location));

export const filterOffers = (offers: Offer[], filters: CalendarOfferFilters): Offer[] => {
	const disciplineFiltered = !isNil(filters.discipline)
		? filterOffersByDiscipline(offers, filters.discipline!)
		: offers;
	const locationFiltered = !isNil(filters.location)
		? filterOffersByLocation(disciplineFiltered, filters.location)
		: disciplineFiltered;
	const categoryFiltered = !isNil(filters.trainingCategory)
		? filterOffersByTrainingCategory(locationFiltered, filters.trainingCategory)
		: locationFiltered;
	return !isNil(filters.trainingType)
		? filterOffersByTrainingType(categoryFiltered, filters.trainingType)
		: categoryFiltered;
};

const filterOffersByStartDate = (offers: Offer[], startDate: Date): Offer[] => {
	return offers.filter((offer) => isSameDay(offer.startDate, startDate));
};

const findOfferWithMinStartDate = (offers: Offer[], rangeDate: RangeDate): Maybe<Offer> => {
	const offersWithinRange = offers.filter(
		(offer) =>
			isBefore(offer.startDate, rangeDate.endDate) &&
			isAfter(offer.endDate, rangeDate.startDate)
	);
	return offersWithinRange.reduce(
		(previousValue, currentValue) =>
			isBefore(currentValue.startDate, previousValue.startDate)
				? currentValue
				: previousValue,
		offersWithinRange[0]
	);
};

const findOfferWithMaxStartDate = (offers: Offer[], rangeDate: RangeDate): Maybe<Offer> => {
	const offersWithinRange = offers.filter(
		(offer) =>
			isBefore(offer.startDate, rangeDate.endDate) &&
			isAfter(offer.endDate, rangeDate.startDate)
	);
	return offersWithinRange.reduce(
		(previousValue, currentValue) =>
			isAfter(currentValue.startDate, previousValue.startDate) ? currentValue : previousValue,
		offers[0]
	);
};

export const getDailyOffers = (offers: Offer[], range: RangeDate): DailyOffers[] => {
	if (offers.length === 0) return [];
	const minDate = findOfferWithMinStartDate(offers, range)?.startDate;
	const maxDate = findOfferWithMaxStartDate(offers, range)?.startDate;

	if (isNil(minDate) || isNil(maxDate)) return [];

	return eachDayOfInterval({ start: range.startDate, end: range.endDate }).map((date) => {
		return {
			date,
			offers: filterOffersByStartDate(offers, date).sort(
				(a, b) => a.startDate.getTime() - b.startDate.getTime()
			)
		};
	});
};

export const groupByDate = (calendarOffers: CalendarOffer[]): CalendarDailyOffers[] => {
	return calendarOffers
		.reduce((result: CalendarDailyOffers[], calendarOffer: CalendarOffer) => {
			const offerDate = startOfDay(calendarOffer.offer.startDate);
			const existingGroup = result.find((group) => isSameDay(group.date, offerDate));

			if (existingGroup) {
				existingGroup.calendarOffers.push(calendarOffer);
			} else {
				result.push({
					date: offerDate,
					calendarOffers: [calendarOffer]
				});
			}

			return result;
		}, [])
		.sort((a, b) => a.date.getTime() - b.date.getTime());
};

export const filterCalendarOffers = (
	calendarOffers: CalendarOffer[],
	query: CalendarOfferQuery
): CalendarOffer[] => {
	const { startTime, endTime } = query;

	return calendarOffers.filter((calendarOffer) => {
		const { startDate } = calendarOffer.offer;

		const isWithinStart = !startTime || startDate >= startTime;
		const isWithinEnd = !endTime || startDate <= endTime;

		return isWithinStart && isWithinEnd;
	});
};
