import { FC, HTMLAttributes, useMemo } from 'react';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Avatar from '@mui/material/Avatar/Avatar';
import {
	DisciplineOrTrainerAutocompleteItem,
	getDisciplineMetaData,
	isItemDiscipline,
	isItemTrainer,
	Maybe,
	uniqueValues
} from '@/shared';
import { Box } from '@mui/material';
import { Flex } from '@/ui/flex/Flex';
import { useDisciplineOrTrainerAutocompleteItemDisplayValue } from '@/ui/autocomplete/discipline-or-trainer/helpers/use-discipline-or-trainer-autocomplete-item-display-value';
import Typography from '@mui/material/Typography';
import { primary } from '@/ui/theme/components-overrides/colors';
import { useTranslation } from '@/config';
import { Space } from '@/ui/space/Space';
import { IconButton } from '@/ui/icon-button/IconButton';

type DisciplineOrTrainerAutocompleteOptionProps = {
	item: DisciplineOrTrainerAutocompleteItem;
	htmlProps: HTMLAttributes<HTMLLIElement>;
	state: AutocompleteRenderOptionState;
};

export const DisciplineOrTrainerAutocompleteOption: FC<
	DisciplineOrTrainerAutocompleteOptionProps
> = ({ item, htmlProps, state: { inputValue } }) => {
	const { translateItem } = useDisciplineOrTrainerAutocompleteItemDisplayValue();
	const { t } = useTranslation();

	const optionLabel: string = translateItem(item);
	const matches = match(optionLabel, inputValue, { insideWords: true });
	const parts = parse(optionLabel, matches);
	const leftIcon = isItemTrainer(item) ? (
		<Avatar
			imgProps={{ referrerPolicy: 'no-referrer' }}
			alt={item.contactDetails.surname}
			src={item.avatarUrl}
			sx={{ borderRadius: '24px', width: '24px', height: '24px' }}
		/>
	) : isItemDiscipline(item) ? (
		<IconButton variant={'secondary'} size={'small'}>
			{getDisciplineMetaData(item).strokeIcon({
				width: '20px',
				height: '20px',
				color: primary.black
			})}
		</IconButton>
	) : null;

	const rightTextComponent = useMemo((): Maybe<JSX.Element> => {
		if (!isItemTrainer(item) || item.disciplineDetails.length === 0) {
			return null;
		}

		const trainerDisciplines = uniqueValues(
			item.disciplineDetails.map((details) => details.discipline)
		);

		const matchingDisciplines = trainerDisciplines.filter((discipline) => {
			const disciplineTranslationKey = getDisciplineMetaData(discipline).translationKey;
			return t(disciplineTranslationKey).toLowerCase().includes(inputValue.toLowerCase());
		});

		return (
			<Typography color={primary['500']} variant={'p3'}>
				{matchingDisciplines.length > 0
					? `${t(getDisciplineMetaData(matchingDisciplines[0]).translationKey)}`
					: t(getDisciplineMetaData(item.disciplineDetails[0].discipline).translationKey)}
				{trainerDisciplines.length > 1 ? (
					<>
						{','}
						<Space />
						{`+${trainerDisciplines.length - 1}`}
					</>
				) : null}
			</Typography>
		);
	}, [inputValue, item, t]);

	return (
		<Flex flexDirection={'column'}>
			<Box component="li" {...htmlProps}>
				<Flex alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
					<Flex alignItems={'center'} justifyContent={'center'} gap={1}>
						{leftIcon}
						<Flex>
							{parts.map((part, index) => {
								return (
									<span
										key={index}
										style={{
											whiteSpace: 'pre',
											fontWeight: part.highlight ? 700 : 400
										}}>
										{part.text}
									</span>
								);
							})}
						</Flex>
					</Flex>
					{rightTextComponent}
				</Flex>
			</Box>
		</Flex>
	);
};
