import { from, Observable, tap } from 'rxjs';
import { Maybe, Page, useStore } from '@/shared';
import { useCallback } from 'react';
import {
	changeAllNotificationReadStatus,
	type ChangeAllNotificationReadStatusResponse,
	ChangeReadNotificationStatus,
	countNotifications,
	type CountNotificationsResponse2,
	deleteNotification,
	type DeleteNotificationResponse,
	listNotifications,
	type ListNotificationsResponse,
	toggleNotificationReadStatus,
	type ToggleNotificationReadStatusResponse
} from '@/api';
import { handleApiError, mapApiResult } from '@/shared/utils/helpers/rxjs/handle-api-error';
import { Notification, NotificationQuery } from '@/shared/model/Notification';
import { mapNotification } from '@/shared/repository/notification-mapper';

type SelfClientFunctions = {
	listNotifications: (query: NotificationQuery) => Observable<Page<Notification>>;
	toggleNotificationReadStatus: (notificationId: string) => Observable<Notification>;
	deleteNotification: (notificationId: string) => Observable<void>;
	changeAllNotificationReadStatus: (request: ChangeReadNotificationStatus) => Observable<void>;
	countNotifications: (readStatus?: Maybe<boolean>) => Observable<number>;
};

export const useNotificationRepository = (): SelfClientFunctions => {
	const {
		replaceElement: replaceNotificationInStore,
		addElements: addNotificationsToStore,
		deleteElement: deleteNotificationFromStore,
		setState: setNotificationState
	} = useStore<Notification>('notifications');

	const listNotificationsCallback = useCallback(
		(query: NotificationQuery): Observable<Page<Notification>> => {
			return from(
				listNotifications({
					query: {
						read: query.read ?? undefined,
						pageNumber: query.pageNumber || 0,
						pageSize: query.pageSize || 5
					}
				})
			).pipe(
				handleApiError(),
				mapApiResult<ListNotificationsResponse, Page<Notification>>((response) => {
					const notifications = response.content?.map((n) => mapNotification(n)) || [];
					return {
						content: notifications,
						pageNumber: response.pageNumber,
						pageSize: response.pageSize,
						totalElements: response.totalElements,
						totalPages: response.totalPages,
						hasMore: response.hasMore
					};
				}),
				tap((notificationsPage) => addNotificationsToStore(notificationsPage.content))
			);
		},
		[addNotificationsToStore]
	);
	const toggleNotificationReadStatusCallback = useCallback(
		(notificationId: string): Observable<Notification> => {
			return from(
				toggleNotificationReadStatus({
					path: {
						notificationId: notificationId
					}
				})
			).pipe(
				handleApiError(),
				mapApiResult<ToggleNotificationReadStatusResponse, Notification>(mapNotification),
				tap((notification) => replaceNotificationInStore(notification.id, notification))
			);
		},
		[replaceNotificationInStore]
	);
	const deleteNotificationCallback = useCallback(
		(notificationId: string): Observable<void> => {
			return from(
				deleteNotification({
					path: {
						notificationId: notificationId
					}
				})
			).pipe(
				handleApiError(),
				mapApiResult<DeleteNotificationResponse, void>((response) => response),
				tap(() => deleteNotificationFromStore(notificationId))
			);
		},
		[deleteNotificationFromStore]
	);
	const changeAllNotificationReadStatusCallback = useCallback(
		(request: ChangeReadNotificationStatus): Observable<void> => {
			return from(
				changeAllNotificationReadStatus({
					body: request
				})
			).pipe(
				handleApiError(),
				mapApiResult<ChangeAllNotificationReadStatusResponse, void>((response) => response),
				tap(() =>
					setNotificationState((notifications) =>
						notifications.map((notification) => ({
							...notification,
							read: request.read
						}))
					)
				)
			);
		},
		[setNotificationState]
	);
	const countNotificationsCallback = useCallback(
		(readStatus?: Maybe<boolean>): Observable<number> => {
			return from(
				countNotifications({
					query: {
						read: readStatus ?? undefined
					}
				})
			).pipe(
				handleApiError(),
				mapApiResult<CountNotificationsResponse2, number>((response) => response.count)
			);
		},
		[]
	);
	return {
		listNotifications: listNotificationsCallback,
		toggleNotificationReadStatus: toggleNotificationReadStatusCallback,
		deleteNotification: deleteNotificationCallback,
		changeAllNotificationReadStatus: changeAllNotificationReadStatusCallback,
		countNotifications: countNotificationsCallback
	};
};
