import { FallbackProps } from '@/config/hoc/with-fallback';
import { useContext } from 'react';
import { trainerContext } from '@/config';
import { isNil, MeTrainer } from '@/shared';
import { useLoaderHandler } from '@/shared/utils/loader/use-loader-handler';

export const useTrainer = (fallback?: FallbackProps<MeTrainer>) => {
	const context = useContext(trainerContext);

	if (isNil(context)) {
		throw new Error('useTrainer must be used within TrainerProvider');
	}

	const loading = useLoaderHandler(
		[context.loader],
		[context.me],
		() => fallback?.onError(),
		(data) => fallback?.onSuccess(data[0]!)
	);

	return {
		me: context?.me,
		loading
	};
};
