import { useState } from 'react';
import { debounce } from '@mui/material/utils';
import { Maybe } from '@/shared';

export const useDebouncedState = <T>(
	delay: number,
	initialValue?: T
): [Maybe<T>, (value: Maybe<T>) => void] => {
	const [state, setState] = useState<Maybe<T>>(initialValue);

	const debouncedSetState = debounce((newValue: Maybe<T>) => {
		setState(newValue);
	}, delay);

	return [state, debouncedSetState];
};
