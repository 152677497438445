import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSkiingFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M18.842 5.782a1.872 1.872 0 0 1-1.871-1.872c0-1.052.819-1.91 1.871-1.91 1.014 0 1.872.858 1.872 1.91 0 1.014-.858 1.872-1.872 1.872Zm3.08 14.542c0 .662-1.209 1.637-2.69 1.676a3.31 3.31 0 0 1-1.482-.35L2.468 13.773c-.312-.156-.468-.507-.468-.819 0-.507.35-.935.897-.935.156 0 .312 0 .429.078l7.719 4.015 1.793-2.69-2.924-2.924a2.579 2.579 0 0 1-.702-1.754c0-.156 0-.273.04-.39l4.132 2.066 1.56 1.599c.35.35.545.818.545 1.325 0 .35-.117.702-.312 1.014l-1.91 2.885 5.302 2.729c.195.117.39.156.624.156 1.014 0 1.053-.741 1.793-.741.468 0 .936.39.936.936ZM6.639 5.587 5.626 4.69c-.04-.039-.078-.078-.078-.156 0-.117.117-.195.234-.195h.039l1.325.273.468-.858c.507.273.78.82.78 1.365v.039l2.456 1.208 3.158-1.286c.35-.117.663-.195.975-.195 1.208 0 2.066.975 2.339 1.715l.662 1.989 2.028 1.013c.428.234.701.663.701 1.13 0 .625-.545 1.248-1.247 1.248a1.1 1.1 0 0 1-.585-.155l-2.261-1.092c-.429-.234-.78-.585-.936-1.092l-.234-.74-1.286.506-4.445-2.183v-.039L7.887 6.29c-.273.233-.624.35-.975.35-.234 0-.468-.078-.701-.156l.428-.896Z"
		/>
	</svg>
);
export default SvgSkiingFilled;
