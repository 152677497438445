import { FC, MouseEvent, useState } from 'react';
import { Button, Container, Divider, Menu, MenuItem, Tooltip } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import {
	menu,
	Path,
	useClient,
	useLayout,
	useNavigate,
	useTrainer,
	useTranslation
} from '@/config';
import { isNil, Maybe } from '@/shared';
import { useAppType } from '@/config/app-type/appTypeContext';
import { Flex } from '@/ui/flex/Flex';
import WorkoutlyLogoBlack from '@/assets/icons/generated/WorkoutlyLogoBlack';
import { ProBadge } from '@/ui/app-switcher/ProBadge';
import { primary } from '@/ui/theme/components-overrides/colors';
import { IconButton } from '@/ui/icon-button/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar/Avatar';
import { AppTypeSwitcher } from '@/ui/app-switcher/AppTypeSwitcher';
import Box from '@mui/material/Box';
import SvgLogoutStroke from '@/assets/icons/generated/stroke/LogoutStroke';
import { SearchBar } from '@/config/layout/navigation/helpers/SearchBar';
import WorkoutlyLogoWhite from '@/assets/icons/generated/WorkoutlyLogoWhite';
import { NotificationIcon } from '@/config/layout/navigation/helpers/NotificationIcon';
import Badge from '@mui/material/Badge';
import { ChevronDownIcon } from '@/ui/custom-icon/chevron-down/ChevronDownIcon';

//TODO
// 	1. make navbar look for partial trainer name too
//	2. make navbar listen to query params and adjust autocomplete values to query params
export const NavbarContent: FC = () => {
	const { logout, isAuthenticated, loginWithRedirect } = useAuth0();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [menuAnchorElement, setMenuAnchorElement] = useState<Maybe<HTMLElement>>();
	const { appType } = useAppType();
	const layout = useLayout();
	const { me: meClient } = useClient();
	const { me: meTrainer } = useTrainer();

	const handleMenuItemClick = (pathToNavigate: Path): void => {
		setMenuAnchorElement(undefined);
		navigate(pathToNavigate);
	};

	const handleMenuItemLogout = (): void => {
		setMenuAnchorElement(undefined);
		logout({ returnTo: window.location.origin });
	};

	const handleLogoClick = () => {
		if (!isAuthenticated) {
			navigate('');
		} else if (appType === 'pro') {
			navigate('me/trainer/dashboard');
		} else {
			navigate('');
		}
	};

	const logoWithBadge = (
		<Flex onClick={handleLogoClick} alignItems={'center'} gap={1} sx={{ cursor: 'pointer' }}>
			{layout === 'landing' ? (
				<WorkoutlyLogoWhite width={'140px'} height={'40px'} />
			) : (
				<WorkoutlyLogoBlack width={'140px'} height={'40px'} />
			)}
			{(appType === 'pro' || layout === 'landing-pro') && <ProBadge mode={'dark'} />}
		</Flex>
	);

	const avatarMenu = (
		<Menu
			anchorEl={menuAnchorElement}
			open={!isNil(menuAnchorElement)}
			PaperProps={{
				sx: {
					mt: 1
				}
			}}
			transformOrigin={{ horizontal: 'right', vertical: 'top' }}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			onClose={() => setMenuAnchorElement(undefined)}>
			{menu.items.map((item, index) => {
				return (
					item.appType === appType && (
						<MenuItem key={index} onClick={() => handleMenuItemClick(item.route.path)}>
							<Flex gap={1} alignItems={'center'}>
								<IconButton variant={'secondary'}>
									{!isNil(item.icon) &&
										item.icon({
											width: '16px',
											height: '16px',
											color: primary.black
										})}
								</IconButton>
								<Typography variant={'p3'}>{t(item.name)}</Typography>
							</Flex>
						</MenuItem>
					)
				);
			})}
			<Box px={2} py={1}>
				<Divider />
			</Box>
			<MenuItem onClick={handleMenuItemLogout}>
				<Flex gap={1} alignItems={'center'}>
					<IconButton variant={'secondary'}>
						<SvgLogoutStroke width={'16px'} height={'16px'} />
					</IconButton>
					<Typography variant={'p3'}>{t('shared.menu.logout')}</Typography>
				</Flex>
			</MenuItem>
		</Menu>
	);

	const avatar = (
		<Tooltip title={t('shared.component.NavbarContent.click-to-see-menu')} placement={'left'}>
			<Badge
				onClick={(event: MouseEvent<HTMLElement>) =>
					setMenuAnchorElement(event.currentTarget)
				}
				overlap="circular"
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				badgeContent={
					<ChevronDownIcon
						size={'12px'}
						sx={{
							bottom: '-2px',
							position: 'relative',
							left: '2px',
							cursor: 'pointer'
						}}
					/>
				}>
				<Avatar
					imgProps={{ referrerPolicy: 'no-referrer' }}
					src={meClient?.avatarUrl || ''}
					sx={{
						borderRadius: '4px',
						width: '50px',
						height: '50px',
						cursor: 'pointer'
					}}
				/>
			</Badge>
		</Tooltip>
	);

	const landingNotLoggedIn = (
		<>
			<Flex gap={2} flexGrow={1} justifyContent={'flex-end'}>
				<Typography
					onClick={() => navigate('faq')}
					variant={'p3'}
					color={layout === 'landing' ? primary.contrast : primary.main}
					sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
					{t('shared.menu.frequency-asked-questions')}
				</Typography>
			</Flex>

			<Flex gap={2}>
				<Flex gap={2}>
					{layout === 'landing' && (
						<Button variant={'tertiary'} size={'large'} onClick={() => navigate('pro')}>
							<Typography variant={'h6'} color={primary.main}>
								{t('shared.component.NavbarContent.for-professionals')}
							</Typography>
						</Button>
					)}
					{layout === 'landing-pro' && (
						<Button variant={'tertiary'} size={'large'} onClick={() => navigate('')}>
							<Typography variant={'h6'} color={primary.main}>
								{t('shared.component.NavbarContent.for-athletes')}
							</Typography>
						</Button>
					)}
					<Button
						variant={layout === 'landing' ? 'secondary-outline' : 'secondary'}
						sx={{
							border: layout === 'landing' ? `1px solid ${primary.contrast}` : 'none',
							'&:hover': {
								background: primary['800']
							}
						}}
						size={'large'}
						onClick={() => {
							if (layout === 'landing-pro') {
								loginWithRedirect({
									appState: { type: 'pro' }
								});
							} else {
								loginWithRedirect();
							}
						}}>
						<Typography variant={'h6'} color={primary.contrast}>
							{t('shared.menu.login')}
						</Typography>
					</Button>
				</Flex>
			</Flex>
		</>
	);
	const landingLoggedIn = (
		<>
			<Flex gap={2} flexGrow={1} justifyContent={'flex-end'}>
				<Typography
					onClick={() => navigate('faq')}
					variant={'p3'}
					color={layout === 'landing' ? primary.contrast : primary.main}
					sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
					{t('shared.menu.frequency-asked-questions')}
				</Typography>
			</Flex>

			<Flex gap={2}>
				<Flex gap={2}>
					{isNil(meTrainer) && !isNil(meClient) && (
						<Button
							variant={'tertiary'}
							size={'large'}
							onClick={() => navigate(`trainer/${meClient.id}/sign-up`)}>
							<Typography variant={'h6'} color={primary.main}>
								{t('shared.component.NavbarContent.for-professionals')}
							</Typography>
						</Button>
					)}
					{layout !== 'landing-pro' && !isNil(meTrainer) && (
						<AppTypeSwitcher logoColor={layout === 'landing' ? 'white' : 'black'} />
					)}
					<NotificationIcon />
					{avatar}
					{avatarMenu}
				</Flex>
			</Flex>
		</>
	);

	const nonLandingNotLoggedIn = (
		<>
			<Flex flexGrow={1} justifyContent={'center'}>
				<SearchBar />
			</Flex>
			<Flex gap={2}>
				<Flex gap={2}>
					<Button variant={'tertiary'} size={'large'} onClick={() => navigate('pro')}>
						<Typography variant={'h6'} color={primary.main}>
							{t('shared.component.NavbarContent.for-professionals')}
						</Typography>
					</Button>
					<Button
						variant={layout === 'landing' ? 'secondary-outline' : undefined}
						sx={{
							border: `1px solid ${primary.contrast}`
						}}
						size={'large'}
						onClick={loginWithRedirect}>
						<Typography
							variant={'h6'}
							color={layout === 'landing' ? primary.contrast : primary.main}>
							{t('shared.menu.login')}
						</Typography>
					</Button>
				</Flex>
			</Flex>
		</>
	);
	const nonLandingLoggedIn = (
		<>
			<Flex flexGrow={1} justifyContent={'center'}>
				<SearchBar />
			</Flex>
			<Flex gap={2}>
				{!isNil(meTrainer) && (
					<AppTypeSwitcher logoColor={layout === 'landing' ? 'white' : 'black'} />
				)}
				<NotificationIcon />
				{isNil(meTrainer) && !isNil(meClient) && (
					<Button
						size={'large'}
						onClick={() => navigate(`trainer/${meClient.id}/sign-up`)}>
						<Typography variant={'h6'}>{t('shared.menu.become-trainer')}</Typography>
					</Button>
				)}
				{avatar}
				{avatarMenu}
			</Flex>
		</>
	);

	return (
		<Container maxWidth="lg">
			<Flex
				flexWrap={'wrap'}
				gap={2}
				paddingY={2}
				alignItems={'center'}
				width={'100%'}
				justifyContent={'space-between'}>
				<Flex>{logoWithBadge}</Flex>
				{layout === 'application' && (
					<>{isAuthenticated ? nonLandingLoggedIn : nonLandingNotLoggedIn}</>
				)}
				{(layout === 'landing' || layout === 'landing-pro') && (
					<>{isAuthenticated ? landingLoggedIn : landingNotLoggedIn}</>
				)}
			</Flex>
		</Container>
	);
};
