import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles';
import { Theme } from '@mui/material';

type MuiDateCalendarOverrides = {
	// @ts-ignore
	defaultProps?: ComponentsProps['MuiDateCalendar'];
	// @ts-ignore
	styleOverrides?: ComponentsOverrides<Theme>['MuiDateCalendar'];
};

export const muiDateCalendar: MuiDateCalendarOverrides = {
	styleOverrides: {
		root: {
			'&.MuiDateCalendar-root': {
				height: 'auto',
				padding: '16px 0'
			}
		}
	}
};
