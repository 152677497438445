import * as React from 'react';
import type { SVGProps } from 'react';
const SvgProBadge = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 23 9"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path d="M.943 8.558V.737h3.33c1.643 0 2.704.983 2.704 2.502 0 1.52-1.061 2.503-2.704 2.503h-1.33v2.816h-2Zm2-4.514H4.05c.592 0 .938-.302.938-.805 0-.502-.346-.804-.938-.804H2.943v1.609ZM14.488 8.558h-2.346L10.577 5.63H9.83v2.928h-2L7.806.737h3.207c1.564 0 2.581.96 2.581 2.447 0 .815-.38 1.553-1.039 2l1.933 3.374Zm-4.66-6.123v1.497h.828c.614 0 .95-.28.95-.748 0-.47-.336-.75-.95-.75h-.827ZM18.51 8.647c-2.346 0-4.077-1.698-4.077-4 0-2.301 1.732-4 4.078-4 2.324 0 4.045 1.699 4.045 4 0 2.302-1.721 4-4.045 4ZM18.5 6.95c1.206 0 2.067-.983 2.067-2.302 0-1.318-.86-2.301-2.067-2.301-1.218 0-2.078.983-2.078 2.301 0 1.319.86 2.302 2.078 2.302Z" />
	</svg>
);
export default SvgProBadge;
