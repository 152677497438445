import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { Theme } from '@mui/material';
import { primary, secondary } from '@/ui/theme/components-overrides/colors';
import { CSSObject } from '@mui/system';

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		accent: true;
	}

	interface ButtonPropsVariantOverrides {
		secondary: true;
		'secondary-outline': true;
		tertiary: true;
	}
}

type MuiButtonOverrides = {
	defaultProps?: ComponentsProps['MuiButton'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
	variants?: ComponentsVariants['MuiButton'];
};

type ButtonSize = 'small' | 'medium' | 'large';

const buttonSizes: Record<ButtonSize, CSSObject> = {
	small: {
		display: 'inline-flex',
		alignItems: 'center',
		gap: '4px',
		padding: '8px'
	},
	medium: {
		display: 'inline-flex',
		alignItems: 'center',
		gap: '8px',
		padding: '8px 12px'
	},
	large: {
		display: 'inline-flex',
		alignItems: 'center',
		gap: '8px',
		padding: '12px 16px'
	}
};

export const createButtonStyles = (
	textColor: string,
	defaultColor: string,
	hoverColor: string,
	activeColor: string,
	disabledColor: string,
	disabledTextColor: string
): CSSObject => {
	return {
		width: 'fit-content',
		background: defaultColor,
		color: textColor,
		borderRadius: '4px',
		'&:hover': {
			background: hoverColor
		},
		'&:active': {
			background: activeColor
		},
		'&:disabled': {
			background: disabledColor,
			color: disabledTextColor
		}
	};
};

const defaultStyles: CSSObject = {
	...createButtonStyles(
		primary.main,
		secondary.main,
		secondary['700'],
		secondary['500'],
		primary['50'],
		primary['400']
	),
	...buttonSizes.medium,
	width: 'auto',
	minWidth: 0,
	fontFamily: 'Matter',
	fontSize: '16px',
	fontStyle: 'normal',
	fontWeight: 500,
	lineHeight: '24px',
	textTransform: 'none',
	'.MuiButton-startIcon': {
		margin: 0
	},
	'.MuiButton-endIcon': {
		margin: 0
	}
};

export const muiButton: MuiButtonOverrides = {
	variants: [
		{
			props: { variant: 'secondary' },
			style: createButtonStyles(
				primary.contrast,
				primary.main,
				primary['800'],
				primary['700'],
				primary['50'],
				primary['400']
			)
		},
		{
			props: { variant: 'secondary-outline' },
			style: {
				...createButtonStyles(
					primary['900'],
					'transparent',
					primary['50'],
					primary['100'],
					'transparent',
					primary['400']
				),
				border: `1px solid ${primary.main}`,
				'&:disabled': {
					background: 'transparent',
					color: primary['400'],
					border: `1px solid ${primary['100']}`
				}
			}
		},
		{
			props: { variant: 'tertiary' },
			style: {
				...createButtonStyles(
					primary['900'],
					primary['50'],
					primary['100'],
					primary['200'],
					primary['25'],
					primary['400']
				)
			}
		}
	],
	styleOverrides: {
		root: defaultStyles,
		sizeSmall: { ...buttonSizes.small },
		sizeMedium: { ...buttonSizes.medium },
		sizeLarge: { ...buttonSizes.large }
	},
	defaultProps: { disableRipple: true }
};
