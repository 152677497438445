import { FC, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { useSuspenseLoader } from '@/config';

export const FallbackComponent: FC = () => {
	const { updateLoader } = useSuspenseLoader();

	useEffect(() => {
		updateLoader(true);
		return () => {
			updateLoader(false);
		};
	}, [updateLoader]);

	return <Skeleton />;
};
