import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWorkoutlyLogoBlack = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 602 136"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="m264.791 71.345 22.49 30.874h17.699L275.038 61.63l27.813-26.482h-18.764l-27.68 25.284V8h-14.639v94.219h14.639V79.196l8.384-7.851Z"
		/>
		<path
			fill="#000"
			fillRule="evenodd"
			d="M114.382 68.683c0 20.095 14.372 34.6 34.6 34.6 20.095 0 34.6-14.505 34.6-34.6 0-20.094-14.505-34.6-34.6-34.6-20.228 0-34.6 14.506-34.6 34.6Zm54.562 0c0 12.909-8.118 21.958-19.962 21.958-11.977 0-19.961-9.05-19.961-21.958 0-12.908 7.984-21.958 19.961-21.958 11.844 0 19.962 9.05 19.962 21.958Z"
			clipRule="evenodd"
		/>
		<path
			fill="#000"
			d="M8 35.148v67.071h13.308l36.685-53.364 1.197 53.364h13.441l45.574-67.071h-14.771L70.28 83.337l-1.064-48.19H51.339L21.308 79.774V35.148H8ZM234.651 49.254c-2.396-1.065-5.59-1.597-8.65-1.597-9.848 0-18.498 8.783-18.498 29.543v25.019h-14.505V35.148h14.505v12.775c3.593-7.984 12.376-13.84 20.494-13.84 2.528 0 5.456.4 7.452 1.198l-.798 13.973Z"
		/>
		<path
			fill="#000"
			fillRule="evenodd"
			d="M338.181 103.283c-20.228 0-34.6-14.505-34.6-34.6 0-20.094 14.372-34.6 34.6-34.6 20.094 0 34.6 14.506 34.6 34.6 0 20.095-14.506 34.6-34.6 34.6Zm0-12.642c11.844 0 19.961-9.05 19.961-21.958 0-12.908-8.117-21.958-19.961-21.958-11.977 0-19.962 9.05-19.962 21.958 0 12.909 7.985 21.958 19.962 21.958Z"
			clipRule="evenodd"
		/>
		<path
			fill="#000"
			d="M428.507 102.219h14.505V35.148h-14.505v29.41c0 16.102-6.787 25.684-17.965 25.684-8.517 0-13.84-5.856-13.84-14.24V35.148h-14.506v44.181c0 14.506 9.981 23.954 24.353 23.954 9.715 0 17.566-5.057 21.958-12.243v11.179ZM494.239 101.686c-3.194 1.065-6.92 1.597-10.646 1.597-12.643 0-22.091-6.92-22.091-22.224V47.524h-11.711V35.148h11.711V15.186h14.505v19.962h17.3v12.376h-17.3v32.737c0 6.654 3.726 10.114 9.183 10.114 2.528 0 5.589-.4 8.117-1.597l.932 12.908ZM504.819 8v94.219h14.506V8h-14.506ZM593.607 34.918H578.41l-18.318 47.355-17.911-47.355H526.17l26.595 66.351L542.452 128h15.197l35.958-93.082Z"
		/>
	</svg>
);
export default SvgWorkoutlyLogoBlack;
