export const getMonthNames = (locale: string): string[] => {
	const format = new Intl.DateTimeFormat(locale, { month: 'long' }).format;
	return [...Array(12).keys()].map((m) =>
		format(new Date(Date.UTC(new Date().getFullYear(), m % 12)))
	);
};

export const getMonthName = (locale: string, monthIndex = 1): string => {
	const objDate = new Date();
	objDate.setDate(1);
	objDate.setMonth(monthIndex - 1);

	return objDate.toLocaleString(locale, { month: 'long' });
};
