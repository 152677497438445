import { EMPTY, from, map, Observable, of, switchMap, take, tap } from 'rxjs';
import {
	ClientTraining,
	ClientTrainingPackage,
	Discipline,
	isNil,
	Maybe,
	MeClient,
	TrainingCategory,
	TrainingQuery,
	useStore
} from '@/shared';
import { useCallback } from 'react';
import {
	create1,
	CreateClientRequest,
	downloadPackageInvoices,
	DownloadPackageInvoicesResponse,
	getMeClient,
	getMyPackages,
	GetMyPackagesResponse,
	listTrainings,
	type ListTrainingsResponse,
	MeClientResponse,
	toggleFavouriteTrainer,
	updateMe1
} from '@/api';
import { useAuth0 } from '@auth0/auth0-react';
import { mapMeClient, mapTrainingPackages } from '@/shared/repository/client-mapper';
import {
	castRequestResult,
	handleApiError,
	mapApiResult
} from '@/shared/utils/helpers/rxjs/handle-api-error';
import { catchError } from 'rxjs/operators';
import { mapClientTraining } from '@/shared/repository/training-mapper';

export type UpdateClientBasicInformationRequest = {
	name: string;
	surname: string;
	phoneNumber: string;
	email: string;
	termsAndConditionsAcceptance: boolean;
	policyPrivacyAcceptance: boolean;
	marketingEmailsAcceptance: boolean;
	alwaysUseCurrentLocation: boolean;
};

type SelfClientFunctions = {
	getMyPackages: () => Observable<ClientTrainingPackage[]>;
	toggleFavouriteTrainer: (trainerId: string) => Observable<MeClient>;
	updateMe: (
		avatarImage: Maybe<Blob>,
		data: UpdateClientBasicInformationRequest
	) => Observable<MeClient>;
	getOrCreateMe: (request: CreateClientRequest) => Observable<MeClient>;
	getMyTrainings: (query: TrainingQuery) => Observable<ClientTraining[]>;
	downloadPackageInvoices: (
		trainerId: string,
		discipline: Discipline,
		category: TrainingCategory
	) => Observable<File>;
};

export const useSelfClientRepository = (): SelfClientFunctions => {
	const { addElements: addPackagesToStore, getState: getPackagesFromStore } =
		useStore<ClientTrainingPackage>('client-packages');
	const {
		addElement: addClientToStore,
		getElement: getClientFromStore,
		replaceElement: replaceClientInStore
	} = useStore<MeClient>('me-client');
	const { user, logout } = useAuth0();

	const toggleFavouriteTrainerCallback = useCallback(
		(trainerId: string): Observable<MeClient> => {
			return from(toggleFavouriteTrainer({ body: { trainerId: trainerId } })).pipe(
				handleApiError(),
				mapApiResult<MeClientResponse, MeClient>(mapMeClient),
				tap((client) => replaceClientInStore(client.id, client))
			);
		},
		[replaceClientInStore]
	);

	const updateMeCallback = useCallback(
		(
			avatarImage: Maybe<Blob>,
			data: UpdateClientBasicInformationRequest
		): Observable<MeClient> => {
			return from(
				updateMe1({
					body: {
						data: new Blob([JSON.stringify(data)], {
							type: 'application/json'
						}),
						...(isNil(avatarImage) ? {} : { avatarImage })
					}
				})
			).pipe(
				handleApiError(),
				mapApiResult<MeClientResponse, MeClient>(mapMeClient),
				tap((client) => replaceClientInStore(client.id, client))
			);
		},
		[replaceClientInStore]
	);
	const getOrCreateMeCallback = useCallback(
		(request: CreateClientRequest) => {
			if (isNil(user) || isNil(user.sub)) {
				return EMPTY;
			}

			const createNewClient = () => {
				return from(getMeClient()).pipe(
					take(1),
					switchMap((response) => {
						if (response.response.ok) {
							return of(mapMeClient(response.data!));
						}
						return from(create1({ body: request })).pipe(
							handleApiError(),
							mapApiResult<MeClientResponse, MeClient>(mapMeClient),
							tap(addClientToStore)
						);
					}),
					tap(addClientToStore),
					catchError(() => {
						logout({ returnTo: window.location.origin });
						return EMPTY;
					})
				);
			};

			const fetchClient = (userId: string) => {
				return getClientFromStore(userId).pipe(
					switchMap((client) => {
						if (isNil(client)) {
							return createNewClient();
						}
						return of(client);
					})
				);
			};

			return fetchClient(user.sub);
		},
		[addClientToStore, getClientFromStore, logout, user]
	);

	const getMyPackagesCallback = useCallback(() => {
		return getPackagesFromStore().pipe(
			switchMap((packages) => {
				if (isNil(packages)) {
					return from(getMyPackages()).pipe(
						handleApiError(),
						mapApiResult<GetMyPackagesResponse, ClientTrainingPackage[]>(
							(packagesResponse) => packagesResponse.map(mapTrainingPackages)
						),
						tap(addPackagesToStore)
					);
				}
				return of(packages);
			})
		);
	}, [addPackagesToStore, getPackagesFromStore]);

	const getMyTrainingsCallback = useCallback(
		(query: TrainingQuery): Observable<ClientTraining[]> =>
			from(
				listTrainings({
					query: {
						startTime: query.startTime?.toISOString() || undefined,
						endTime: query.endTime?.toISOString() || undefined
					}
				})
			).pipe(
				handleApiError(),
				mapApiResult<ListTrainingsResponse, ClientTraining[]>((listTrainingsResponse) =>
					listTrainingsResponse.map(mapClientTraining)
				)
			),
		[]
	);

	const downloadPackageInvoicesCallback = useCallback(
		(trainerId: string, discipline: Discipline, category: TrainingCategory) => {
			return from(
				downloadPackageInvoices({
					parseAs: 'auto',
					query: {
						trainerId,
						discipline,
						category: category.name
					}
				})
			).pipe(
				handleApiError(),
				castRequestResult<DownloadPackageInvoicesResponse>(),
				map((result) => {
					const contentDisposition = result.response.headers.get('content-disposition');
					const fileName = (contentDisposition?.match(/filename="(.+?)"/) || [])[1] || '';
					return new File([result.data], fileName);
				})
			);
		},
		[]
	);

	return {
		toggleFavouriteTrainer: toggleFavouriteTrainerCallback,
		updateMe: updateMeCallback,
		getOrCreateMe: getOrCreateMeCallback,
		getMyPackages: getMyPackagesCallback,
		downloadPackageInvoices: downloadPackageInvoicesCallback,
		getMyTrainings: getMyTrainingsCallback
	};
};
