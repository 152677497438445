import { App } from '@/App';
import {
	Auth0ProviderWithConfig,
	ClientProvider,
	CultureProvider,
	GoogleMapProvider,
	TokenProvider,
	TrainerProvider
} from '@/config';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { DrawerProvider } from '@/ui/drawer/drawer-context';
import { ColorModeProvider } from '@/ui/theme/color-mode/color-mode-context';
import { DialogProvider } from '@/ui/dialog/dialog-context';
import { AppTypeProvider } from '@/config/app-type/appTypeContext';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { NotificationProvider } from '@/ui/notification/notification-context';
import { ContextMenuProvider } from '@/ui/context-menu-context/context-menu-context';
import '@/config/chart/chart-config';
import { HttpClientProvider } from '@/config/api/HttpClientProvider';
import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: 'https://dc4032fabe8912bfca47198aa0602d6a@o4508069306499072.ingest.de.sentry.io/4508092396142672',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [
		/^https?:\/\/workoutly\.pl/, // Match the main application URL
		/^https:\/\/api\.workoutly\.pl\/api/ // Match the API URL
	],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	enabled: process.env.NODE_ENV === 'production'
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<StrictMode>
		<BrowserRouter>
			<Auth0ProviderWithConfig>
				<TokenProvider>
					<ClientProvider>
						<TrainerProvider>
							<AppTypeProvider>
								<ColorModeProvider>
									<CssBaseline />
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<CultureProvider>
											<GoogleMapProvider>
												<NotificationProvider>
													<DrawerProvider>
														<DialogProvider>
															<ContextMenuProvider>
																<HttpClientProvider>
																	<App />
																</HttpClientProvider>
															</ContextMenuProvider>
														</DialogProvider>
													</DrawerProvider>
												</NotificationProvider>
											</GoogleMapProvider>
										</CultureProvider>
									</LocalizationProvider>
								</ColorModeProvider>
							</AppTypeProvider>
						</TrainerProvider>
					</ClientProvider>
				</TokenProvider>
			</Auth0ProviderWithConfig>
		</BrowserRouter>
	</StrictMode>
);
