import * as React from 'react';
import type { SVGProps } from 'react';
const SvgKitesurfingStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M21 4c.531 0 1 .469 1 1v8.219c0 .469-.344.875-.781.969 0 0-10.782 2.468-10.844 2.5l-2.75 2.75A1.959 1.959 0 0 1 6.219 20c-1.032 0-1.969-.844-1.969-2.125V14l-1.5.938C2.687 15 2.594 15 2.5 15c-.281 0-.5-.188-.5-.469V12.5c0-.281.219-.5.5-.5.094 0 .188.031.25.094l1.5.937V11c0-.688-.563-1.25-1.25-1.25h-.281C2.313 9.75 2 9.437 2 9c0-.406.313-.75.719-.75L3 8.281c1.5 0 2.75 1.219 2.75 2.75v2l1.469-.937a.415.415 0 0 1 .25-.094c.281 0 .531.219.531.5v2.031c0 .281-.25.5-.531.5a.415.415 0 0 1-.25-.094L5.75 14v4.031c0 .282.219.5.469.5.125 0 .25-.062.343-.156l2.782-2.75c0-.063 2.469-10.844 2.469-10.844.093-.437.5-.781.968-.781H21Zm-.5 8.813-3.656-3.657L20.5 5.5h-7.313l3.657 3.656L11 15l9.5-2.188Z"
		/>
	</svg>
);
export default SvgKitesurfingStroke;
