import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBoxFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M19.875 11.371c0 .633-.105 2.074-1.477 3.34L16.36 16.75v2.813c0 .949-.738 1.687-1.652 1.687h-7.84c-.914 0-1.652-.738-1.652-1.688v-3.656a3.445 3.445 0 0 1-1.09-2.531v-6.82c0-1.828 1.512-3.305 3.34-3.305h6.785c1.828 0 3.34 1.512 3.34 3.375v1.16c-.211 0-.457-.035-.738-.035-.88 0-1.864.14-2.637 1.16H9.082c-1.512 0-2.039-.879-2.32-.879-.07 0-.14.035-.211.106l-.387.386c-.07.07-.07.141-.07.211 0 .352 1.406 1.301 2.988 1.301h4.605c-.035.176-.035.352-.035.563 0 2.425 2.426 2.777 2.532 2.777.14 0 .28-.14.28-.281v-.563c0-.14-.14-.246-.245-.281a1.67 1.67 0 0 1-1.442-1.652c0-1.23 1.16-1.723 1.793-1.723h1.02c1.23 0 2.25 1.02 2.25 2.25 0 .07.035.14.035.246Zm-7.031 6.61-.985-.669.985-.632a.561.561 0 0 0 .281-.492c0-.282-.281-.528-.563-.528-.14 0-.246 0-.316.07l-1.371.915-1.406-.915c-.07-.07-.176-.07-.281-.07-.317 0-.563.246-.563.527 0 .211.07.387.246.493l.984.633-1.02.668c-.175.105-.245.28-.245.492 0 .28.246.527.562.527.141 0 .246 0 .282-.07l1.406-.914 1.37.914c.071.07.212.07.317.07a.608.608 0 0 0 .598-.527.561.561 0 0 0-.281-.492Z"
		/>
	</svg>
);
export default SvgBoxFilled;
