import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLogoutStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M9.507 19.578c0 .547-.43.939-.938.939H5.754C3.642 20.517 2 18.873 2 16.762V6.753A3.73 3.73 0 0 1 5.754 3h2.815c.508 0 .938.43.938.938 0 .548-.43.939-.938.939H5.754c-1.056 0-1.877.86-1.877 1.877v10.009c0 1.056.82 1.877 1.877 1.877h2.815c.508 0 .938.43.938.938Zm12.238-8.445L16.78 5.815c-.351-.39-.938-.39-1.329-.039-.39.352-.39.938-.039 1.33l3.48 3.714H9.155c-.508 0-.9.43-.9.938 0 .548.392.939.9.939h9.697l-3.52 3.753c-.351.391-.351.978.04 1.33.235.156.47.234.665.234.234 0 .469-.078.664-.274l4.966-5.317a.86.86 0 0 0 .078-1.29Z"
		/>
	</svg>
);
export default SvgLogoutStroke;
