import { useCallback } from 'react';
import { useCulture } from '@/config';
import { getMonthName } from '@/shared';
import { format, getDate, isSameMonth, isSameYear } from 'date-fns';

type FormatDateFunctions = {
	formatDate: (date: Date, options?: Intl.DateTimeFormatOptions & { comma?: boolean }) => string;
	formatDateRange: (range: [Date, Date]) => string;
	formatHourRange: (range: [Date, Date]) => string;
	getMonthAndYearLabel: (date: Date) => string;
	getYearLabel: (date: Date) => string;
	getShortWeekDayLabel: (date: Date) => string;
	getShortMonthLabel: (date: Date) => string;
};

export const useDateFunctions = (): FormatDateFunctions => {
	const { culture } = useCulture();

	const formatDateCallback = useCallback(
		(
			date: Date,
			options: Intl.DateTimeFormatOptions & { comma?: boolean } = {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				comma: true
			}
		): string => {
			const locale = culture.replace('_', '-');
			const formattedDate = new Intl.DateTimeFormat(locale, options).format(date);
			return options.comma
				? formattedDate.replace(/(.*?)\s([^ ]*)$/, '$1, $2')
				: formattedDate;
		},
		[culture]
	);

	const formatHourRangeCallback = useCallback((range: [Date, Date]) => {
		const startTime = format(range[0], 'HH:mm');
		const endTime = format(range[1], 'HH:mm');
		return `${startTime} - ${endTime}`;
	}, []);

	const formatDateRangeCallback = useCallback(
		(range: [Date, Date]) => {
			const [startDate, endDate] = range;

			const startDateFormatterOptions: Intl.DateTimeFormatOptions = {
				day: 'numeric',
				month: 'long',
				year: isSameYear(startDate, endDate) ? undefined : 'numeric'
			};
			const endDateFormatterOptions: Intl.DateTimeFormatOptions = {
				day: 'numeric',
				month: 'long',
				year: 'numeric'
			};
			const locale = culture.replace('_', '-');
			const formattedStartDate = isSameMonth(startDate, endDate)
				? getDate(startDate)
				: new Intl.DateTimeFormat(locale, startDateFormatterOptions).format(startDate);
			const formattedEndDate = new Intl.DateTimeFormat(locale, endDateFormatterOptions)
				.format(endDate)
				.replace(/(.*?)\s([^ ]*)$/, '$1, $2');

			return `${formattedStartDate} - ${formattedEndDate}`;
		},
		[culture]
	);

	const getMonthAndYearLabelCallback = useCallback(
		(date: Date): string => {
			const locale = culture.replace('_', '-');
			return `${getMonthName(locale, date?.getMonth() + 1)} ${date?.getFullYear()}`;
		},
		[culture]
	);

	const getYearLabelCallback = (date: Date) => date?.getFullYear().toString();

	const getShortWeekDayCallback = useCallback(
		(date: Date): string => {
			const locale = culture.replace('_', '-');
			return date.toLocaleString(locale, { weekday: 'short' });
		},
		[culture]
	);

	const getShortMonthCallback = useCallback(
		(date: Date): string => {
			const locale = culture.replace('_', '-');
			return date.toLocaleString(locale, { month: 'short' });
		},
		[culture]
	);

	return {
		formatDate: formatDateCallback,
		formatDateRange: formatDateRangeCallback,
		getMonthAndYearLabel: getMonthAndYearLabelCallback,
		getYearLabel: getYearLabelCallback,
		getShortWeekDayLabel: getShortWeekDayCallback,
		getShortMonthLabel: getShortMonthCallback,
		formatHourRange: formatHourRangeCallback
	};
};
