import { primary, secondary } from '@/ui/theme/components-overrides/colors';
import { IconButton } from '@/ui/icon-button/IconButton';
import SvgBellStroke from '@/assets/icons/generated/stroke/BellStroke';
import { Badge } from '@mui/material';
import { useNotificationRepository } from '@/shared/repository/notification/use-notification-repository';
import { useMemo } from 'react';
import { from, NEVER } from 'rxjs';
import { useObservable } from '@/shared';
import { useNavigate, useToken } from '@/config';
import { useAuth0 } from '@auth0/auth0-react';

export const NotificationIcon = () => {
	const { tokenReady } = useToken();
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();

	const { countNotifications } = useNotificationRepository();
	const countNonReadNotifications$ = useMemo(() => {
		if (isAuthenticated && tokenReady) {
			return from(countNotifications(false));
		}
		return NEVER;
	}, [countNotifications, isAuthenticated, tokenReady]);
	const { value: count } = useObservable(countNonReadNotifications$);

	return (
		<Badge
			overlap={'circular'}
			badgeContent={count}
			color={'primary'}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			sx={{
				position: 'relative',
				display: 'inline-block',
				'& .MuiBadge-badge': {
					border: `2px solid ${primary.contrast}`,
					top: '3px',
					right: '3px'
				}
			}}>
			<IconButton
				onClick={() => navigate('notifications')}
				size={'large'}
				variant={'secondary'}
				sx={{
					padding: '12px',
					borderRadius: '4px',
					height: '100%',
					'&:hover': {
						background: secondary['800']
					}
				}}>
				<SvgBellStroke width={'24px'} height={'24px'} color={primary.black} />
			</IconButton>
		</Badge>
	);
};
