import { FC, ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import {
	areDisciplineOrTrainerAutocompleteItemsEqual,
	DisciplineOrTrainerAutocompleteItem,
	isItemTrainer,
	isNil,
	Maybe
} from '@/shared';
import { useDisciplineOrTrainersAutocompleteOptions } from '@/ui/autocomplete/discipline-or-trainer/helpers/use-discipline-or-trainers-autocomplete-options';
import { Divider } from '@mui/material';
import { DisciplineOrTrainerAutocompleteOption } from '@/ui/autocomplete/discipline-or-trainer/helpers/DisciplineOrTrainerAutocompleteOption';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { useDisciplineOrTrainerAutocompleteItemDisplayValue } from '@/ui/autocomplete/discipline-or-trainer/helpers/use-discipline-or-trainer-autocomplete-item-display-value';
import { Input } from '@/ui/input/Input';
import { SearchStroke } from '@/assets/icons/generated/stroke';
import { useTranslation } from '@/config';
import { OverridableStringUnion } from '@mui/types';
import { TextFieldPropsSizeOverrides } from '@mui/material/TextField/TextField';
import { PaperProps } from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { DisciplineOrTrainerAutocompleteGroup } from '@/ui/autocomplete/discipline-or-trainer/helpers/DisciplineOrTrainerAutocompleteGroup';
import Autocomplete from '@mui/material/Autocomplete';
import { useDebouncedState } from '@/shared/utils/state/use-debounced-state';

type DisciplineOrTrainerAutocompleteProps = {
	size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
	onChange?: (item: DisciplineOrTrainerAutocompleteItem) => void;
	value?: Maybe<DisciplineOrTrainerAutocompleteItem>;
	paperProps?: PaperProps;
	id?: string;
	promptOpen?: boolean;
	onPromptStateChange?: (value: boolean) => void;
};

export const DisciplineOrTrainerAutocomplete: FC<DisciplineOrTrainerAutocompleteProps> = ({
	onChange = () => {},
	size = 'medium',
	paperProps = {},
	id,
	promptOpen = false,
	onPromptStateChange = () => {},
	value
}) => {
	const { t } = useTranslation();
	const [item, setItem] = useState<Maybe<DisciplineOrTrainerAutocompleteItem>>(value);
	const [query, setQuery] = useDebouncedState<string>(500, '');

	const { options } = useDisciplineOrTrainersAutocompleteOptions(query);
	const { translateItem } = useDisciplineOrTrainerAutocompleteItemDisplayValue();

	const isLastDisciplineOptionBeforeTrainerOptions = useCallback(
		(option: DisciplineOrTrainerAutocompleteItem): boolean => {
			if (isNil(options) || options.length === 0 || isItemTrainer(option)) {
				return false;
			}
			const disciplineIndex = options.findIndex(
				(val: DisciplineOrTrainerAutocompleteItem) => val === option
			);
			return isItemTrainer(options[disciplineIndex + 1]);
		},
		[options]
	);

	useEffect(() => {
		if (!isNil(item) && item) {
			onChange(item);
		} else if (!isNil(query) && item) {
			onChange(query);
		}
	}, [item, onChange, query]);

	return (
		<Autocomplete
			id={id || 'DisciplineOrTrainerAutocompleteId'}
			filterOptions={(x) => x}
			options={options || []}
			autoComplete
			freeSolo
			includeInputInList
			filterSelectedOptions
			getOptionLabel={(option) => translateItem(option)}
			onInputChange={(event, newQuery) => {
				setQuery(newQuery);
			}}
			noOptionsText={t('shared.component.DisciplineOrTrainerAutocomplete.no-results')}
			isOptionEqualToValue={(receivedOption, receivedValue) =>
				areDisciplineOrTrainerAutocompleteItemsEqual(receivedOption, receivedValue)
			}
			onChange={(
				event: SyntheticEvent,
				newValue: Maybe<DisciplineOrTrainerAutocompleteItem>
			) => {
				setItem(newValue || '');
			}}
			groupBy={(option) =>
				isItemTrainer(option) ? t('shared.users') : t('shared.disciplines')
			}
			renderGroup={({ group, key, children }) => (
				<DisciplineOrTrainerAutocompleteGroup group={group} key={key}>
					{children}
				</DisciplineOrTrainerAutocompleteGroup>
			)}
			renderOption={(
				props,
				option: DisciplineOrTrainerAutocompleteItem,
				state: AutocompleteRenderOptionState
			) => (
				<Box key={state.index} marginY={1}>
					<DisciplineOrTrainerAutocompleteOption
						item={option}
						htmlProps={props}
						state={state}
					/>
					{isLastDisciplineOptionBeforeTrainerOptions(option) && (
						<Box paddingX={2} marginY={1}>
							<Divider />
						</Box>
					)}
				</Box>
			)}
			popupIcon={<></>}
			slotProps={{
				paper: {
					...paperProps,
					sx: {
						marginY: 1,
						...paperProps.sx
					}
				}
			}}
			open={promptOpen}
			onOpen={() => onPromptStateChange(true)}
			onClose={() => onPromptStateChange(false)}
			renderInput={(params) => {
				return (
					<Input
						{...params}
						size={size}
						leftIcon={SearchStroke}
						placeholder={t(
							'shared.component.DisciplineOrTrainerAutocomplete.trainer-or-discipline'
						)}
						InputLabelProps={{
							children: params.InputLabelProps.children as ReactNode
						}}
					/>
				);
			}}
		/>
	);
};
