import {
	ClientResponse,
	ClientTrainingPackagesResponse,
	ContactDetailsResponse,
	DocumentsApprovalResponse,
	MeClientResponse
} from '@/api';
import {
	Client,
	ClientTrainingPackage,
	ContactDetails,
	DocumentsApproval,
	MeClient
} from '@/shared';
import { mapTrainer } from '@/shared/repository/trainer-mapper';

export const mapContactDetails = (response: ContactDetailsResponse): ContactDetails => {
	return {
		name: response.name,
		surname: response.surname,
		email: response.email,
		phone: response.phoneNumber
	};
};

const mapDocumentsApproval = (response: DocumentsApprovalResponse): DocumentsApproval => {
	return {
		termsAndConditionsAcceptance: response.termsAndConditionsAcceptance,
		alwaysUseCurrentLocation: response.alwaysUseCurrentLocation,
		policyPrivacyAcceptance: response.policyPrivacyAcceptance,
		marketingEmailsAcceptance: response.marketingEmailsAcceptance
	};
};

export const mapClient = (response: ClientResponse): Client => {
	return {
		id: response.id,
		avatarUrl: response.avatarUrl,
		sex: response.sex,
		contactDetails: mapContactDetails(response.contactDetails)
	};
};

export const mapTrainingPackages = (
	response: ClientTrainingPackagesResponse
): ClientTrainingPackage => ({
	id: response.trainer.id,
	trainer: mapTrainer(response.trainer),
	discipline: response.discipline,
	trainingCategory: { name: response.trainingCategory },
	totalTrainingSessions: response.totalTrainingSessions,
	remainingTrainingSessions: response.remainingTrainingSessions
});

export const mapMeClient = (response: MeClientResponse): MeClient => {
	return {
		id: response.id,
		avatarUrl: response.avatarUrl,
		sex: response.sex,
		contactDetails: mapContactDetails(response.contactDetails),
		consents: mapDocumentsApproval(response.consents),
		favouriteTrainers: response.favouriteTrainers.map((trainer) => mapTrainer(trainer))
	};
};
