import { FC } from 'react';
import { primary } from '@/ui/theme/components-overrides/colors';
import ArrowRightLine from '@/assets/icons/generated/stroke/ArrowRightLine';
import { Flex } from '@/ui/flex/Flex';
import SvgChevronLeftStroke from '@/assets/icons/generated/stroke/ChevronLeftStroke';
import SvgCrossStroke from '@/assets/icons/generated/stroke/CrossStroke';
import { IconButton } from '@/ui/icon-button/IconButton';
import { MobileWorkoutlyIcon } from '@/config/layout/navigation/helpers/mobile/MobileWorkoutlyIcon';

type DrawerHeaderProps = {
	onIconClick: () => void;
	activeDrawerIndex: number;
	closeXButton: boolean;
};

export const DrawerHeader: FC<DrawerHeaderProps> = ({
	onIconClick,
	activeDrawerIndex,
	closeXButton
}) => {
	return (
		<Flex
			borderBottom={`1px solid ${primary[100]}`}
			padding={2}
			justifyContent={'space-between'}
			alignItems={'center'}
			sx={{ cursor: 'pointer' }}>
			{closeXButton ? (
				<>
					<MobileWorkoutlyIcon iconColor={'black'} />
					<IconButton onClick={onIconClick} variant="secondary" sx={{ borderRadius: 1 }}>
						<SvgCrossStroke color={primary.main} width="24px" height="24px" />
					</IconButton>
				</>
			) : (
				<>
					{activeDrawerIndex === 0 ? (
						<ArrowRightLine onClick={onIconClick} width="40px" height="40px" />
					) : (
						<SvgChevronLeftStroke onClick={onIconClick} width="40px" height="40px" />
					)}
				</>
			)}
		</Flex>
	);
};
