import { OfferResponse } from '@/api';
import { Offer } from '@/shared';
import { toLocation } from '@/shared/repository/common-mappers';

export const mapOffer = (response: OfferResponse): Offer => {
	return {
		id: response.id,
		price: {
			value: parseFloat(response.price.value),
			currencyCode: response.price.currencyCode
		},
		discipline: response.discipline,
		startDate: new Date(response.dateRange.start),
		endDate: new Date(response.dateRange.end),
		location: toLocation(response.location),
		status: response.status,
		trainingCategory: {
			name: response.trainingCategory
		},
		trainingType: response.trainingType,
		trainerId: response.trainerId
	};
};
