import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHorseRidingStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 60 58"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			fillRule="evenodd"
			d="M57.653.012H38.845a1.843 1.843 0 0 0-1.843 1.844v7.98c0 1.018.825 1.843 1.843 1.843h1.546c2.167 5.85 3.446 12.687 3.446 16.218 0 7.629-6.207 13.837-13.837 13.837s-13.837-6.207-13.837-13.837c0-3.532 1.279-10.369 3.445-16.218h1.546a1.843 1.843 0 0 0 1.844-1.843v-7.98A1.843 1.843 0 0 0 21.154.012H2.346A1.843 1.843 0 0 0 .503 1.856v7.98c0 1.018.825 1.843 1.843 1.843h1.935c-1.85 6.082-3.005 12.75-3.005 17.585 0 15.839 12.885 28.724 28.724 28.724 15.838 0 28.723-12.885 28.723-28.724 0-4.834-1.154-11.506-3.005-17.585h1.935a1.843 1.843 0 0 0 1.843-1.843v-7.98A1.843 1.843 0 0 0 57.653.012Zm-4.47 7.98a1.842 1.842 0 0 0-1.743 2.446c2.15 6.222 3.596 13.788 3.596 18.826 0 13.805-11.232 25.037-25.036 25.037-13.805 0-25.037-11.23-25.037-25.037 0-5.038 1.445-12.604 3.596-18.826a1.844 1.844 0 0 0-1.742-2.446H4.19V3.7h15.12v4.293h-.967c-.754 0-1.431.459-1.711 1.158-2.7 6.737-4.156 14.687-4.156 18.747 0 9.662 7.861 17.524 17.524 17.524 9.662 0 17.524-7.862 17.524-17.524 0-4.06-1.457-12.01-4.156-18.747a1.843 1.843 0 0 0-1.712-1.158h-.967V3.7h15.12v4.293h-2.627ZM9.975 19.87a1.68 1.68 0 1 0 0-3.36 1.68 1.68 0 0 0 0 3.36ZM51.7 18.19a1.68 1.68 0 1 1-3.36 0 1.68 1.68 0 0 1 3.36 0ZM14.302 44.19a1.68 1.68 0 1 0 0-3.36 1.68 1.68 0 0 0 0 3.36Zm33.081-1.68a1.68 1.68 0 1 1-3.36 0 1.68 1.68 0 0 1 3.36 0Zm-23.374 8.485a1.68 1.68 0 1 0 0-3.36 1.68 1.68 0 0 0 0 3.36Zm13.667-1.68a1.68 1.68 0 1 1-3.36 0 1.68 1.68 0 0 1 3.36 0ZM9.087 32.89a1.68 1.68 0 1 0 0-3.36 1.68 1.68 0 0 0 0 3.36Zm43.517-1.68a1.68 1.68 0 1 1-3.36 0 1.68 1.68 0 0 1 3.36 0Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgHorseRidingStroke;
