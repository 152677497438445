import { Client } from '@hey-api/client-fetch';
import { map, Observable, pipe } from 'rxjs';
import { ProblemDetail } from '@/api';

type RequestResult = Awaited<ReturnType<Client['request']>>;
type ParametrizedRequestResult<T> = Omit<RequestResult, 'data'> & { data: T };
type SuccessRequestResult = Omit<RequestResult, 'error'>;

export const handleApiError = <T extends RequestResult>() =>
	pipe<Observable<T>, Observable<SuccessRequestResult>>(
		map((requestResult) => {
			if (requestResult.error) {
				if (requestResult.error satisfies ProblemDetail) {
					throw new Error((requestResult.error as ProblemDetail).detail);
				}
				throw new Error('API error occurred.');
			}
			return {
				request: requestResult.request,
				response: requestResult.response,
				data: requestResult.data
			};
		})
	);

export const castRequestResult = <T>() =>
	pipe<Observable<SuccessRequestResult>, Observable<ParametrizedRequestResult<T>>>(
		map((result) => {
			return {
				...result,
				data: result.data as T
			};
		})
	);

export const mapApiResult = <T, R>(mapperFn: (data: T) => R) =>
	pipe<Observable<SuccessRequestResult>, Observable<R>>(map(({ data }) => mapperFn(data as T)));
