import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReformerPilatesFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M23.435 16.27h-7.013l4.923-5.729.01-.014a1.177 1.177 0 0 0-.815-2.024h-4.745c-.908 0-1.646.743-1.646 1.655v3.115h-1.423v2.995h-.503v-2.995H8.427v-5.5c0-.65-.525-1.178-1.172-1.178-.647 0-1.172.528-1.172 1.178v7.155c0 .551.27 1.039.683 1.34H5.285c.338-.344.548-.817.548-1.34 0-1.053-.85-1.907-1.898-1.907a1.902 1.902 0 0 0-1.897 1.907c0 .522.209.996.548 1.34H.566a.566.566 0 0 0-.566.569c0 .314.253.568.565.568h22.87a.566.566 0 0 0 .565-.568.566.566 0 0 0-.565-.569v.002Zm-3.858-5.408-3.083 3.587v-3.587h3.083Z"
		/>
	</svg>
);
export default SvgReformerPilatesFilled;
