import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFitnessFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 80 44"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M57.924 21.636h-8.021c-2.426 0-4.46 1.687-4.963 3.919h-.012v4.341c0 .19-.046.368-.13.526l-.01.02c-.197.358-.582.6-1.026.6-.647 0-1.166-.51-1.166-1.146v-4.341H18.059c-.505-2.245-2.538-3.919-4.964-3.919h-8.02C2.268 21.636 0 23.881 0 26.622v11.567c0 2.757 2.284 4.987 5.074 4.987h8.021c2.426 0 4.46-1.689 4.963-3.919h.006v-3.08c0-.635.52-1.146 1.165-1.146.646 0 1.166.51 1.166 1.145v3.081h24.53c.504 2.245 2.535 3.919 4.963 3.919h8.02c2.805 0 5.072-2.245 5.072-4.987V26.622c.017-2.741-2.269-4.986-5.057-4.986Z"
		/>
		<path
			fill="#000"
			d="M74.941.824h-8.02c-2.427 0-4.46 1.689-4.964 3.917h-.002v1.55c0 .636-.521 1.146-1.166 1.146-.645 0-1.167-.51-1.167-1.145v-1.55H35.076C34.572 2.495 32.54.823 30.113.823h-8.021c-2.805 0-5.074 2.245-5.074 4.987v11.567c0 2.757 2.284 4.986 5.074 4.986h8.021c2.426 0 4.459-1.687 4.963-3.918h24.546v-2.123c0-.635.52-1.146 1.167-1.146.45 0 .838.248 1.033.614l.01.02c.078.154.124.328.124.512v2.138h-.009c.51 2.237 2.539 3.903 4.96 3.903h8.02c2.804 0 5.074-2.245 5.074-4.986V5.811c.015-2.74-2.254-4.987-5.058-4.987h-.002ZM61.821 7.05h.01-.01Z"
		/>
	</svg>
);
export default SvgFitnessFilled;
