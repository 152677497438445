import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMartialArtsStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M17 14c.25 0 .5.25.5.5 0 .281-.25.5-.5.5h-3.594l1.469 2.219a.528.528 0 0 1 .094.312c0 .375-.344.469-.469.469-.188 0-.344-.063-.438-.219L12.5 15.406l-1.594 2.375A.455.455 0 0 1 10.5 18c-.156 0-.5-.094-.5-.469 0-.125.031-.218.094-.312L11.563 15H8a.494.494 0 0 1-.5-.5c0-.25.219-.5.5-.5h9Zm-1 4.25V16h1.5v2.25c0 .969-.813 1.75-1.75 1.75h-6.5c-.969 0-1.75-.781-1.75-1.75V16H9v2.25c0 .156.094.25.25.25h6.5c.125 0 .25-.094.25-.25Zm5.938-8.563c.343.657.562 1.376.562 2.094v4.469c0 .969-.813 1.75-1.75 1.75h-1c-.969 0-1.75-.781-1.75-1.75v-3.875l-.5-.781V13H16V9c0-.25.188-.75.719-.719.25 0 .5.125.656.344l2 3.125c.063.125.125.25.125.406v4.094c0 .156.094.25.25.25h1c.125 0 .25-.094.25-.25v-4.469c0-.469-.125-.937-.375-1.344L18.406 6.47a1.83 1.83 0 0 0-1.625-.969h-1.343L11.594 13H9.906l1.875-3.688L9.562 5.5H8.188c-.688 0-1.313.375-1.626.969l-2.218 3.968c-.25.407-.344.876-.344 1.344v4.469c0 .156.094.25.25.25h1c.125 0 .25-.094.25-.25v-4.094c0-.125.031-.281.094-.406l2-3.125c.156-.219.375-.375.656-.375.531 0 .75.531.75.75v4H7.5v-1.406l-.5.781v3.875C7 17.219 6.187 18 5.25 18h-1c-.969 0-1.75-.781-1.75-1.75v-4.469c0-.719.156-1.437.531-2.094L5.25 5.75C5.844 4.687 6.969 4 8.188 4H10c.25 0 .5.156.625.375l1.969 3.375 1.719-3.313A.806.806 0 0 1 15 4h1.781c1.219 0 2.344.688 2.938 1.75l2.218 3.938Z"
		/>
	</svg>
);
export default SvgMartialArtsStroke;
