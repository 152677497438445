import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUserFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 12 12"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M5.875 6a2.492 2.492 0 0 1-2.5-2.5c0-1.367 1.113-2.5 2.5-2.5 1.367 0 2.5 1.133 2.5 2.5 0 1.387-1.133 2.5-2.5 2.5Zm.977.938a3.4 3.4 0 0 1 3.398 3.398.675.675 0 0 1-.684.664H2.164a.659.659 0 0 1-.664-.664 3.384 3.384 0 0 1 3.379-3.399h1.973Z"
		/>
	</svg>
);
export default SvgUserFilled;
