import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWLogoBlack = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 263 161"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M0 .823v160h31.753l87.532-127.301 2.857 127.301h32.071l108.741-160h-35.246L148.603 115.78 146.063.823h-42.655L31.753 107.278V.823H0Z"
		/>
	</svg>
);
export default SvgWLogoBlack;
