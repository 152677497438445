import {
	createContext,
	FC,
	PropsWithChildren,
	ReactNode,
	useCallback,
	useContext,
	useMemo,
	useState
} from 'react';
import { Slide, Snackbar, SnackbarProps } from '@mui/material';
import { ButtonsConfig, Notification } from '@/ui/notification/Notification';
import { useScreenType } from '@/shared';
import { StatusIconType } from '@/ui/status-icon/StatusIcon';

type NotificationOptions = {
	type: StatusIconType;
	title: string | ReactNode;
	content?: string | ReactNode;
	buttonsConfig?: ButtonsConfig;
	snackbarProps?: Omit<SnackbarProps, 'open'>;
};

type NotificationProperties = {
	open: (opts: NotificationOptions) => void;
	isOpen: boolean;
	close: () => void;
};

const notificationContext = createContext<NotificationProperties>({
	open: () => {},
	isOpen: false,
	close: () => {}
});

export const useNotification = (): NotificationProperties => useContext(notificationContext);

export const NotificationProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const screen = useScreenType();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [notificationOpts, setNotificationOpts] = useState<NotificationOptions>({
		content: null,
		title: null,
		type: 'info'
	});

	const closeNotification = useCallback(() => {
		setIsOpen(false);
	}, []);

	const openNotification = useCallback((opts: NotificationOptions) => {
		setIsOpen(true);
		setNotificationOpts(opts);
	}, []);

	const contextValue = useMemo<NotificationProperties>(
		() => ({
			open: openNotification,
			close: closeNotification,
			isOpen
		}),
		[closeNotification, isOpen, openNotification]
	);

	return (
		<notificationContext.Provider value={contextValue}>
			<Snackbar
				anchorOrigin={{
					vertical: screen === 'desktop' ? 'bottom' : 'top',
					horizontal: screen === 'desktop' ? 'left' : 'center'
				}}
				open={isOpen}
				onClose={() => setIsOpen(false)}
				key={Math.random()}
				message={
					<Notification
						variant={screen}
						type={notificationOpts.type}
						title={notificationOpts.title}
						body={notificationOpts.content}
						buttonsConfig={notificationOpts.buttonsConfig}
						onCloseClick={() => setIsOpen(false)}
					/>
				}
				TransitionComponent={Slide}
				autoHideDuration={3000}
				{...notificationOpts.snackbarProps}
			/>
			{children}
		</notificationContext.Provider>
	);
};
