import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHamburgerStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 20 20"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M1.666 4.226c0-.483.372-.893.893-.893h14.88c.485 0 .894.41.894.893 0 .521-.41.893-.893.893H2.559c-.521 0-.893-.372-.893-.893Zm0 5.953c0-.484.372-.893.893-.893h14.88c.485 0 .894.41.894.893 0 .52-.41.893-.893.893H2.559c-.521 0-.893-.372-.893-.893Zm15.774 6.845H2.559c-.521 0-.893-.372-.893-.893 0-.483.372-.893.893-.893h14.88c.485 0 .894.41.894.893 0 .52-.41.893-.893.893Z"
		/>
	</svg>
);
export default SvgHamburgerStroke;
