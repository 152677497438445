import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChevronLeftStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="m14.811 21.646-8.486-8.858C6.093 12.51 6 12.232 6 11.954c0-.232.093-.51.278-.742l8.487-8.858a1.107 1.107 0 0 1 1.577-.047c.463.418.463 1.113.046 1.577l-7.745 8.07 7.791 8.162c.418.417.418 1.16-.046 1.576a1.107 1.107 0 0 1-1.577-.046Z"
		/>
	</svg>
);
export default SvgChevronLeftStroke;
