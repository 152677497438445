import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCyclingStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M13.344 5c.344 0 .687.219.875.531l3.062 5.657c.375-.094.782-.188 1.219-.188 2.188 0 4 1.813 4 4 0 2.219-1.813 4-4 4-2.219 0-4-1.781-4-4 0-1.25.563-2.344 1.469-3.094l-.688-1.25-2.656 4.5A.72.72 0 0 1 12 15.5h-1.531c-.25 2-1.938 3.5-3.969 3.5-2.219 0-4-1.781-4-4 0-2.188 1.781-4 4-4 .313 0 .656.063.969.125l.812-1.656-.687-.969H6.25a.722.722 0 0 1-.75-.75.74.74 0 0 1 .75-.75H8c.219 0 .438.125.594.313L9.438 8.5h4.687l-1.094-2h-.812a.74.74 0 0 1-.75-.75.76.76 0 0 1 .75-.75h1.125ZM16 15c0 1.406 1.094 2.5 2.5 2.5 1.375 0 2.5-1.094 2.5-2.5 0-1.375-1.125-2.5-2.5-2.5-.188 0-.344.031-.5.063l1.156 2.093a.772.772 0 0 1-.312 1.031.773.773 0 0 1-1.032-.312l-1.125-2.094A2.435 2.435 0 0 0 16 15Zm-9.25-2.469c-.094 0-.156-.031-.281-.031a2.507 2.507 0 0 0-2.5 2.5c0 1.406 1.125 2.5 2.5 2.5a2.514 2.514 0 0 0 2.468-2h-2.28c-.657 0-1.063-.656-.782-1.219l.875-1.75ZM11.563 14 9.28 10.844 7.687 14h3.875Zm-1.032-4 1.875 2.594 1.5-2.594h-3.375Z"
		/>
	</svg>
);
export default SvgCyclingStroke;
