import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTableTennisStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M18.71 14.5c2.071 0 3.75 1.68 3.75 3.75 0 2.07-1.718 3.75-3.75 3.75-2.07 0-3.75-1.719-3.75-3.75 0-2.07 1.68-3.75 3.75-3.75Zm.04 5.625c1.016 0 1.875-.82 1.836-1.836 0-1.016-.82-1.875-1.836-1.875-1.055 0-1.875.86-1.875 1.875 0 1.016.82 1.836 1.875 1.836ZM6.133 8.875l-.196.195c-.43.508-.625 1.055-.625 1.563 0 1.015.625 1.914 1.133 2.422l1.992 2.03-4.023 3.517c-.039.078-.078.117-.078.156 0 .039.039.117.078.117l1.211 1.21c.04.04.078.08.117.08.04 0 .117-.08.117-.08l3.516-4.062 1.992 1.993a3.369 3.369 0 0 0 2.383.976h.04c.077.547.273 1.055.507 1.524l.039.312c-.195.04-.43.04-.625.04a5.206 5.206 0 0 1-3.672-1.524l-.547-.547-2.226 2.539A2.125 2.125 0 0 1 5.78 22c-.234 0-.898 0-1.484-.586l-1.211-1.21a2.006 2.006 0 0 1-.586-1.446c0-.86.508-1.406.664-1.563l2.54-2.187-.587-.625c-.976-.977-1.68-2.383-1.68-3.79 0-.937.313-1.952 1.094-2.812l2.774-3.008C9.14 2.938 11.523 2 13.906 2c5.117 0 8.555 4.219 8.555 8.672 0 1.21-.234 2.422-.781 3.594a4.818 4.818 0 0 0-1.68-.82c.39-.9.586-1.837.586-2.774 0-3.477-2.695-6.797-6.719-6.797-1.836 0-3.75.742-5.195 2.188l-1.29 1.406 7.579 7.578c-.43.469-.742 1.055-.977 1.68L6.133 8.875Z"
		/>
	</svg>
);
export default SvgTableTennisStroke;
