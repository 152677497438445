import { Coordinates, isNil, Location, Maybe } from '@/shared';

export type PlaceId = string;

export type Place = {
	externalId: PlaceId;
	cityExternalId: string;
	details?: PlaceDetails;
	coordinates: Coordinates;
};

export type PlaceDetails = {
	name?: string;
	city?: string;
	country?: string;
	formattedAddress?: string;
};

export const arePlacesEqual = (p1: Maybe<Place>, p2: Maybe<Place>): boolean => {
	if (isNil(p1) || isNil(p2) || (isNil(p1) && isNil(p2))) {
		return false;
	}

	return p1.externalId === p2.externalId;
};

export const areLocationsEqual = (l1: Maybe<Location>, l2: Maybe<Location>): boolean => {
	if (isNil(l1) || isNil(l2) || (isNil(l1) && isNil(l2))) {
		return false;
	}

	return l1.name === l2.name && arePlacesEqual(l1.place, l2.place);
};

export const formatPlaceAddress = (location: Location): string => {
	return location.place.details?.formattedAddress || location.name;
};
