import { PaletteMode, Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
// Do not delete this import. It serves as augmentation of mui create theme: https://mui.com/x/react-date-pickers/base-concepts/
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { muiButton } from '@/ui/theme/components-overrides/mui-button';
import { muiAutocomplete } from '@/ui/theme/components-overrides/mui-autocomplete';
import { muiToolbar } from '@/ui/theme/components-overrides/mui-toolbar';
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';
import { muiAppBar } from '@/ui/theme/components-overrides/mui-app-bar';
import {
	h1,
	h1Landing,
	h2,
	h2Landing,
	h3,
	h4,
	h5,
	h6,
	h7,
	h8,
	h9,
	muiTypography,
	p1,
	p2,
	p3,
	p4,
	span
} from '@/ui/theme/components-overrides/mui-typography';
import { muiIconButton } from '@/ui/theme/components-overrides/mui-icon-button';
import {
	accent,
	bgDark,
	bgLight,
	primary,
	secondary,
	success
} from '@/ui/theme/components-overrides/colors';
import { muiTabs } from '@/ui/theme/components-overrides/mui-tabs';
import { muiTab } from '@/ui/theme/components-overrides/mui-tab';
import { muiCard } from '@/ui/theme/components-overrides/mui-card';
import { muiDrawer } from '@/ui/theme/components-overrides/mui-drawer';
import { muiTextField } from '@/ui/theme/components-overrides/mui-textfield';
import { muiSelect } from '@/ui/theme/components-overrides/mui-select';
import { muiInputBase } from '@/ui/theme/components-overrides/mui-input-base';
import { muiRadio } from '@/ui/theme/components-overrides/mui-radio';
import { muiFormControlLabel } from '@/ui/theme/components-overrides/mui-form-control-label';
import { muiSlider } from '@/ui/theme/components-overrides/mui-slider';
import { muiCheckbox } from '@/ui/theme/components-overrides/mui-checkbox';
import { muiSkeleton } from '@/ui/theme/components-overrides/mui-skeleton';
import { muiLink } from '@/ui/theme/components-overrides/mui-link';
import { muiTooltip } from '@/ui/theme/components-overrides/mui-tooltip';
import { muiPickersDay } from '@/ui/theme/components-overrides/mui-pickers-day';
import { muiFormLabel } from '@/ui/theme/components-overrides/mui-form-label';
import { muiPickersYear } from '@/ui/theme/components-overrides/mui-pickers-year';
import { muiDateCalendar } from '@/ui/theme/components-overrides/mui-date-calendar';
import { muiSwitch } from '@/ui/theme/components-overrides/mui-switch';
import { muiTableContainer } from '@/ui/theme/components-overrides/mui-table-container';
import { muiTable } from '@/ui/theme/components-overrides/mui-table';
import { muiTableHead } from '@/ui/theme/components-overrides/mui-table-head';
import { muiSnackbar } from '@/ui/theme/components-overrides/mui-snackbar';
import { muiAccordion } from '@/ui/theme/components-overrides/mui-accordion';

declare module '@mui/material/styles' {
	interface Palette {
		accent: SimplePaletteColorOptions;
	}

	interface PaletteOptions {
		accent: SimplePaletteColorOptions;
	}
}

const withTypography = (theme: Theme) =>
	createTheme(theme, {
		typography: {
			p1: p1(theme),
			p2: p2(theme),
			p3: p3(theme),
			p4: p4(theme),
			h1: h1(theme),
			h2: h2(theme),
			h3: h3(theme),
			h4: h4(theme),
			h5: h5(theme),
			h6: h6(theme),
			h7: h7(theme),
			h8: h8(theme),
			h9: h9(theme),
			h1Landing: h1Landing(theme),
			h2Landing: h2Landing(theme),
			span: span
		}
	});

const withComponents = (theme: Theme) =>
	createTheme(theme, {
		components: {
			MuiButton: muiButton,
			MuiAutocomplete: muiAutocomplete,
			MuiToolbar: muiToolbar,
			MuiAppBar: muiAppBar,
			MuiTypography: muiTypography,
			MuiIconButton: muiIconButton,
			MuiTabs: muiTabs,
			MuiTab: muiTab,
			MuiCard: muiCard,
			MuiSnackbar: muiSnackbar,
			MuiDrawer: muiDrawer,
			MuiTextField: muiTextField,
			MuiSelect: muiSelect,
			MuiRadio: muiRadio,
			MuiFormControlLabel: muiFormControlLabel,
			MuiSlider: muiSlider,
			MuiCheckbox: muiCheckbox,
			MuiSkeleton: muiSkeleton,
			MuiInputBase: muiInputBase,
			MuiLink: muiLink,
			MuiTooltip: muiTooltip,
			MuiFormLabel: muiFormLabel(theme),
			MuiPickersDay: muiPickersDay,
			MuiPickersYear: muiPickersYear,
			MuiDateCalendar: muiDateCalendar,
			MuiSwitch: muiSwitch,
			MuiTableContainer: muiTableContainer,
			MuiTable: muiTable,
			MuiTableHead: muiTableHead,
			MuiAccordion: muiAccordion
		}
	});

const withPalette = (theme: Theme, mode: PaletteMode) =>
	createTheme(theme, {
		palette: {
			mode,
			primary: primary,
			secondary: secondary,
			accent: {
				main: accent
			},
			success: success,
			background: {
				default: mode === 'light' ? bgLight : bgDark
			}
		}
	});

export const buildTheme = (mode: PaletteMode) => {
	const theme = createTheme();
	return withPalette(withComponents(withTypography(theme)), mode);
};
