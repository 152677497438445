import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { Theme } from '@mui/material';
import { primary } from '@/ui/theme/components-overrides/colors';

type MuiCardOverrides = {
	defaultProps?: ComponentsProps['MuiCard'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiCard'];
	variants?: ComponentsVariants['MuiCard'];
};

export const muiCard: MuiCardOverrides = {
	defaultProps: {
		variant: 'outlined'
	},
	styleOverrides: {
		root: {
			borderRadius: '8px',
			border: `1px solid ${primary[100]}`,
			backgroundColor: primary.contrast
		}
	}
};
