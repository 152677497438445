import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGolfStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M12.46 8.745c0-.157 0-.314-.078-.431.51.157.902.627.902 1.176 0 .745-.588 1.294-1.333 1.333-.549 0-1.02-.392-1.177-.901.118.078.275.078.393.078.706 0 1.294-.549 1.294-1.255Zm.628 3.137c0-.156 0-.313-.078-.431.51.157.902.627.902 1.176 0 .745-.589 1.295-1.334 1.334a1.27 1.27 0 0 1-1.215-.902c.157.078.274.078.431.078.706 0 1.294-.549 1.294-1.255Zm2.51-2.51c0-.156 0-.313-.078-.43.51.156.902.627.902 1.176 0 .745-.589 1.294-1.334 1.333a1.27 1.27 0 0 1-1.215-.902c.156.079.274.079.43.079.707 0 1.295-.55 1.295-1.255Zm-7.333 8.314v-.627h7.53v.627c0 .628-.59 1.216-1.256 1.216-.706 0-1.255.51-1.255 1.216V22h-2.51v-1.882c0-.667-.588-1.216-1.254-1.216-.706 0-1.255-.55-1.255-1.216ZM12.029 2c4.118 0 7.53 3.412 7.53 7.53 0 3.568-2.706 6.352-3.765 6.352-.47 0-.941-.313-.941-.94 0-.275.118-.59.392-.746 1.49-1.059 2.432-2.784 2.432-4.667 0-3.098-2.55-5.647-5.648-5.647A5.648 5.648 0 0 0 6.382 9.53c0 1.883.902 3.608 2.393 4.667.274.157.392.47.392.745 0 .628-.47.941-.942.941-.98 0-3.725-2.745-3.725-6.353C4.5 5.412 7.873 2 12.03 2Z"
		/>
	</svg>
);
export default SvgGolfStroke;
