import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { Theme } from '@mui/material';
import { primary } from '@/ui/theme/components-overrides/colors';

type MuiAppBarOverrides = {
	defaultProps?: ComponentsProps['MuiAppBar'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiAppBar'];
	variants?: ComponentsVariants['MuiAppBar'];
};

export const muiAppBar: MuiAppBarOverrides = {
	styleOverrides: {
		root: {
			backgroundColor: primary.contrast
		}
	}
};
