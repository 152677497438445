import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTableTennisFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M18.75 13.25c-1.992 0-3.672 1.172-4.492 2.813L5.273 7.039c.04-.078.079-.117.157-.234l2.11-2.11C9.296 2.898 11.68 2 14.022 2c5.352 0 8.438 4.492 8.438 8.516 0 1.25-.273 2.5-.82 3.671a5.006 5.006 0 0 0-2.891-.937Zm-4.922 4.102c-.078.312-.078.625-.078.898 0 .898.234 1.758.664 2.46-.195.04-.39.04-.547.04a4.703 4.703 0 0 1-3.32-1.367L9.14 17.977l-3.164 3.632a1.27 1.27 0 0 1-.938.391c-.312 0-.625-.117-.86-.352L2.853 20.32a1.212 1.212 0 0 1-.352-.86c0-.35.117-.702.39-.937l3.633-3.164-1.445-1.445c-.86-.898-1.328-2.031-1.328-3.242 0-.86.234-1.758.742-2.656l9.336 9.336Zm4.883-2.852c2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.719 3.75-3.75 3.75-2.07 0-3.75-1.719-3.75-3.75 0-2.07 1.68-3.75 3.75-3.75Z"
		/>
	</svg>
);
export default SvgTableTennisFilled;
