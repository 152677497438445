import { FC, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { routes } from '@/config/routes/use-route-config';
import ProtectedRoute from '@/config/routes/ProtectedRoute';
import { isLoading, Loader } from '@/shared';
import { FallbackProvider, useGoogleMapScripts } from '@/config';
import { Skeleton } from '@mui/material';
import { useScrollToTop } from '@/config/routes/ScrollToTop';

const LandingPage = lazy(() => import('@/pages/landing'));
const ListingPage = lazy(() => import('@/pages/listing'));
const TrainerPage = lazy(() => import('@/pages/trainer'));
const TrainerSignUpPage = lazy(() => import('@/pages/trainer/sign-up'));
const MyServicesPage = lazy(() => import('@/pages/me/client/my-services'));
const MySavedTrainersPage = lazy(() => import('@/pages/me/client/my-saved-trainers'));
const MySettingsPage = lazy(() => import('@/pages/me/client/my-settings'));
const MyTrainerProfilePage = lazy(() => import('@/pages/me/trainer/my-trainer-profile'));
const MyTrainerDashboardPage = lazy(() => import('@/pages/me/trainer/my-trainer-dashboard'));
const MyTrainerSettlementsPage = lazy(() => import('@/pages/me/trainer/my-trainer-settlements'));
const MyTrainerCalendarPage = lazy(() => import('@/pages/me/trainer/my-trainer-calendar'));
const MyTrainerSettingsPage = lazy(() => import('@/pages/me/trainer/my-trainer-settings'));
const NotificationsPage = lazy(() => import('@/pages/notification'));
const OfferCheckoutPage = lazy(() => import('@/pages/checkout/offer'));
const PackageOfferCheckoutPage = lazy(() => import('@/pages/checkout/package'));
const CashoutPage = lazy(() => import('@/pages/cashout'));
const NotFoundPage = lazy(() => import('@/pages/not-found'));
const TrainerPaymentResultPage = lazy(() => import('@/pages/trainer/payment-result'));
const PrivacyPolicyPage = lazy(() => import('@/pages/other/privacy-policy'));
const FAQPage = lazy(() => import('@/pages/other/faq'));
const TermsAndConditionsPage = lazy(() => import('@/pages/other/terms-and-conditions'));

type RouterProps = {
	onRouteLoaderChange: (loader: Loader) => void;
};

export const Router: FC<RouterProps> = ({ onRouteLoaderChange }) => {
	useScrollToTop();
	const { loader: googleMapLoader } = useGoogleMapScripts();

	return isLoading([googleMapLoader]) ? (
		<Skeleton />
	) : (
		<>
			<FallbackProvider onLoaderChange={onRouteLoaderChange}>
				<Routes>
					<Route path={routes.landingRoute.path} element={<LandingPage />} />
					<Route path={routes.landingProRoute.path} element={<LandingPage />} />
					<Route path={routes.listingRoute.path} element={<ListingPage />} />
					<Route path={`${routes.trainerRoute.path}`} element={<TrainerPage />} />
					<Route
						path={`${routes.notificationsRoute.path}`}
						element={
							<ProtectedRoute>
								<NotificationsPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={`${routes.trainerSignUpRoute.path}`}
						element={
							<ProtectedRoute>
								<TrainerSignUpPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={`${routes.trainerPaymentResultRoute.path}`}
						element={
							<ProtectedRoute>
								<TrainerPaymentResultPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={`${routes.mySavedTrainersRoute.path}`}
						element={
							<ProtectedRoute>
								<MySavedTrainersPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={`${routes.myServicesRoute.path}`}
						element={
							<ProtectedRoute>
								<MyServicesPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={`${routes.mySettingsRoute.path}`}
						element={
							<ProtectedRoute>
								<MySettingsPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={`${routes.myTrainerProfileRoute.path}`}
						element={
							<ProtectedRoute requiredRoles={['ROLE_TRAINER']}>
								<MyTrainerProfilePage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={`${routes.myTrainerDashboardRoute.path}`}
						element={
							<ProtectedRoute requiredRoles={['ROLE_TRAINER']}>
								<MyTrainerDashboardPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={`${routes.myTrainerCalendarRoute.path}`}
						element={
							<ProtectedRoute requiredRoles={['ROLE_TRAINER']}>
								<MyTrainerCalendarPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={`${routes.myTrainerSettlementsRoute.path}`}
						element={
							<ProtectedRoute requiredRoles={['ROLE_TRAINER']}>
								<MyTrainerSettlementsPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={`${routes.myTrainerSettingsRoute.path}`}
						element={
							<ProtectedRoute requiredRoles={['ROLE_TRAINER']}>
								<MyTrainerSettingsPage />
							</ProtectedRoute>
						}
					/>
					<Route path={routes.notFoundRoute.path} element={<NotFoundPage />} />
					<Route
						path={`${routes.packageCheckoutRoute.path}`}
						element={
							<ProtectedRoute>
								<PackageOfferCheckoutPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={`${routes.offerCheckoutRoute.path}`}
						element={
							<ProtectedRoute>
								<OfferCheckoutPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={`${routes.cashoutRoute.path}`}
						element={
							<ProtectedRoute>
								<CashoutPage />
							</ProtectedRoute>
						}
					/>
					<Route path={routes.privacyPolicyRoute.path} element={<PrivacyPolicyPage />} />
					<Route path={routes.faqRoute.path} element={<FAQPage />} />
					<Route
						path={routes.termsAndConditionsRoute.path}
						element={<TermsAndConditionsPage />}
					/>
					<Route
						path={routes.wildcardRoute.path}
						element={<Navigate to={routes.notFoundRoute.path} replace />}
					/>
				</Routes>
			</FallbackProvider>
		</>
	);
};
