import { Flex } from '@/ui/flex/Flex';
import { MenuList } from '@/config/layout/navigation/helpers/mobile/MenuList';
import { useAuth0 } from '@auth0/auth0-react';
import UserDetails from '@/config/layout/navigation/helpers/mobile/UserDetails';

export const MenuDrawerContent = () => {
	const { isAuthenticated } = useAuth0();

	return (
		<Flex gap={2} flexDirection={'column'} height={'100%'}>
			{isAuthenticated && <UserDetails />}
			<MenuList />
		</Flex>
	);
};
