import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGolfFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M8.265 17.686v-.627h7.53v.627c0 .628-.59 1.216-1.256 1.216-.706 0-1.255.51-1.255 1.216V22h-2.51v-1.882c0-.667-.588-1.216-1.254-1.216-.706 0-1.255-.55-1.255-1.216ZM12.029 2c4.157 0 7.53 3.412 7.53 7.608a7.41 7.41 0 0 1-3.294 6.196h-8.55C5.756 14.47 4.5 12.196 4.5 9.608 4.5 5.412 7.873 2 12.03 2Zm-.51 8.078c.197.432.589.706 1.099.706.705 0 1.255-.51 1.255-1.215 0-.51-.314-.942-.745-1.138.078.157.117.353.117.51 0 .667-.549 1.216-1.216 1.216-.196 0-.352-.04-.51-.079Zm1.726 3.844c.706 0 1.255-.55 1.255-1.255 0-.47-.314-.941-.745-1.138.078.157.117.353.117.51 0 .667-.549 1.255-1.254 1.255-.157 0-.353-.078-.51-.117.196.43.627.745 1.137.745Zm2.51-2.51c.667 0 1.216-.55 1.216-1.255 0-.51-.275-.941-.706-1.098.039.157.117.353.117.51 0 .666-.588 1.215-1.255 1.215-.196 0-.353-.039-.51-.078.197.431.628.706 1.138.706Z"
		/>
	</svg>
);
export default SvgGolfFilled;
