const compareByValue = (value1, value2): boolean => {
	return value1 === value2;
};

export const uniqueValues = <T>(arr: T[], comparingFunction?: (v1: T, v2: T) => boolean): T[] => {
	const result: T[] = [];
	for (const item of arr) {
		const found = result.some((value) =>
			comparingFunction ? comparingFunction(value, item) : compareByValue(value, item)
		);
		if (!found) {
			result.push(item);
		}
	}
	return result;
};
