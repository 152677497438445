import {
	createContext,
	FC,
	PropsWithChildren,
	Suspense,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import { Loader } from '@/shared';
import { FallbackComponent } from '@/config/routes/FallbackComponent';

const suspenseLoaderContext = createContext<{
	updateLoader: (loading: boolean) => void;
	loader: Loader;
}>({
	updateLoader: () => {},
	loader: { loading: false }
});

export const useSuspenseLoader = () => {
	return useContext(suspenseLoaderContext);
};

type FallbackProviderProps = {
	onLoaderChange: (loader: Loader) => void;
};

export const FallbackProvider: FC<PropsWithChildren<FallbackProviderProps>> = ({
	children,
	onLoaderChange
}) => {
	const [loader, setLoader] = useState<Loader>({ loading: false });

	useEffect(() => {
		onLoaderChange(loader);
	}, [loader, onLoaderChange]);

	const updateLoader = useCallback((loading: boolean) => {
		setLoader({ loading });
	}, []);

	return (
		<suspenseLoaderContext.Provider
			value={{
				updateLoader,
				loader: loader
			}}>
			<Suspense fallback={<FallbackComponent />}>{children}</Suspense>
		</suspenseLoaderContext.Provider>
	);
};
