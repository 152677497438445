import { buildTheme } from '@/ui/theme/theme-customization';
import { PaletteMode, ThemeProvider } from '@mui/material';
import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';

const colorModeContext = createContext<{
	toggleColorMode: () => void;
}>({
	toggleColorMode: () => {}
});

export const useColorMode = () => {
	return useContext(colorModeContext);
};

export const ColorModeProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const [mode, setMode] = useState<PaletteMode>('light');
	const colorMode = useMemo(
		() => ({
			toggleColorMode: () => {
				setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
			}
		}),
		[]
	);

	const theme = useMemo(() => buildTheme(mode), [mode]);

	return (
		<colorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</colorModeContext.Provider>
	);
};
