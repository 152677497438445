import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFitnessStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 84 50"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			stroke="#000"
			strokeWidth={1.014}
			d="M76.943.606h-8.02c-2.516 0-4.656 1.646-5.351 3.894h-26.1C36.776 2.237 34.636.606 32.12.606H24.1c-3.075 0-5.58 2.449-5.58 5.464v11.5c0 3.032 2.523 5.462 5.58 5.462h8.02c2.517 0 4.655-1.645 5.351-3.895h26.085c.696 2.263 2.835 3.895 5.352 3.895h8.019c3.074 0 5.58-2.448 5.58-5.463V6.072c.016-3.017-2.491-5.466-5.564-5.466Zm-42.59 16.962c0 1.187-.99 2.17-2.234 2.17h-8.02c-1.243 0-2.235-.984-2.235-2.17V6.07c0-1.187.99-2.171 2.235-2.171h8.02c1.243 0 2.234.984 2.234 2.171v11.498Zm44.807 0c0 1.187-.99 2.17-2.235 2.17h-8.019c-1.244 0-2.234-.984-2.234-2.17v-3.34c0-.923-.76-1.646-1.672-1.646-.913 0-1.674.724-1.674 1.645v1.619H37.714V7.809h25.612v1.124c0 .923.76 1.646 1.673 1.646.915 0 1.673-.724 1.673-1.646V6.07c0-1.187.99-2.171 2.234-2.171h8.02c1.243 0 2.234.984 2.234 2.171v11.498ZM19.002 35.8c.91 0 1.665-.715 1.673-1.63h25.612v4.402c0 .923.759 1.646 1.672 1.646.913 0 1.673-.723 1.673-1.646v-6.141c0-1.186.99-2.172 2.234-2.172h8.02c1.243 0 2.234.985 2.234 2.172l.015 11.499c0 1.187-.99 2.171-2.235 2.171h-8.019c-1.244 0-2.234-.984-2.234-2.171v-.092c0-.922-.759-1.647-1.673-1.647H20.69V39.39c0-.922-.758-1.646-1.673-1.646s-1.672.724-1.672 1.646v4.54c0 1.188-.99 2.172-2.235 2.172H7.075c-1.243 0-2.235-.984-2.235-2.171v-11.5c0-1.185.991-2.17 2.235-2.17h8.02c1.243 0 2.235.984 2.235 2.17v1.725c0 .922.757 1.646 1.672 1.646Zm40.913-8.832h-8.018c-2.517 0-4.656 1.645-5.352 3.895h-26.1c-.695-2.263-2.835-3.895-5.351-3.895h-8.02c-3.075 0-5.581 2.448-5.581 5.463V43.93c0 3.032 2.523 5.464 5.58 5.464h8.02c2.517 0 4.656-1.646 5.352-3.895H46.53c.695 2.262 2.834 3.895 5.351 3.895h8.02c3.074 0 5.579-2.449 5.579-5.464V32.432c.017-3.017-2.508-5.464-5.563-5.464h-.002Z"
		/>
	</svg>
);
export default SvgFitnessStroke;
