import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSquashStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M22.621 5.36c1.899 2.706.528 6.925-3.058 9.421-3.516 2.496-6.328 1.723-7.453 1.723a10.76 10.76 0 0 0-3.938.773c.07.352-.105.739-.422.985l-3.972 2.777a1.118 1.118 0 0 1-1.547-.281L1.176 19.28c-.316-.527-.211-1.195.281-1.547l3.973-2.742c.035-.07.562-.422 1.195-.07 1.09-1.3 1.934-2.848 2.32-4.535.422-1.934 1.723-3.867 3.727-5.309 3.55-2.496 8.016-2.426 9.95.281ZM3.145 20.124l3.937-2.777-1.02-1.477-3.972 2.777 1.055 1.477Zm4.57-3.832c.95-.387 1.898-.633 2.883-.773-.528-.422-1.195-1.02-1.582-2.18-.422.879-.985 1.687-1.653 2.46l.352.493Zm11.215-2.39c3.164-2.216 4.254-5.801 2.777-7.91-1.582-2.216-5.414-2.075-8.367 0-3.094 2.179-4.324 5.73-2.777 7.91 1.582 2.214 5.343 2.144 8.367 0Z"
		/>
		<path
			fill="#000"
			fillRule="evenodd"
			d="m22.865 5.185.002.002c1.015 1.448 1.143 3.282.567 5.055-.576 1.773-1.858 3.503-3.699 4.785-1.804 1.28-3.437 1.727-4.768 1.85-.664.062-1.248.042-1.734.008-.192-.014-.363-.029-.515-.042a7.428 7.428 0 0 0-.609-.04c-1.222 0-2.478.244-3.622.674-.015.398-.236.775-.553 1.021l-.006.005-3.984 2.786a1.418 1.418 0 0 1-1.958-.357L.925 19.446l-.006-.01c-.388-.647-.27-1.493.364-1.946l.004-.003 3.937-2.718a.891.891 0 0 1 .167-.127c.088-.05.204-.101.34-.135.224-.054.505-.063.808.047.994-1.232 1.756-2.674 2.114-4.233.44-2.016 1.79-4.009 3.844-5.486l.002-.002c1.823-1.282 3.89-1.911 5.761-1.865 1.872.045 3.571.77 4.605 2.217Zm-16.24 9.737c-.633-.352-1.16 0-1.195.07l-3.973 2.742c-.492.352-.598 1.02-.281 1.547l1.054 1.477c.352.492 1.02.633 1.547.281l3.973-2.777c.316-.246.492-.633.422-.985a10.76 10.76 0 0 1 3.937-.773c.178 0 .398.02.656.042 1.376.12 3.837.337 6.797-1.765 3.586-2.496 4.958-6.715 3.06-9.422-1.934-2.707-6.4-2.777-9.95-.28-2.004 1.44-3.305 3.374-3.727 5.308-.386 1.687-1.23 3.234-2.32 4.535Zm12.131-1.264.002-.001c1.534-1.075 2.555-2.474 3.016-3.845.462-1.373.357-2.69-.312-3.647-.717-1.003-1.962-1.498-3.42-1.481-1.46.017-3.098.548-4.53 1.554-1.501 1.058-2.54 2.441-3.018 3.808-.478 1.368-.39 2.693.313 3.682.72 1.007 1.95 1.518 3.397 1.51 1.447-.01 3.085-.54 4.552-1.58Zm-8.194.244c-1.546-2.18-.316-5.73 2.778-7.91 2.953-2.074 6.785-2.215 8.367 0 1.477 2.11.387 5.696-2.777 7.91-3.024 2.145-6.785 2.215-8.367 0Zm-3.896 3.372-.679-.984-3.477 2.43.705.988 3.451-2.434Zm-3.521 2.851 3.937-2.777-1.02-1.477-3.972 2.777 1.055 1.477Zm7.453-4.605a7.854 7.854 0 0 1-.307-.257 4.254 4.254 0 0 1-1.275-1.923 9.25 9.25 0 0 1-.186.366A11.59 11.59 0 0 1 7.363 15.8l.352.492a11.974 11.974 0 0 1 2.883-.773Zm-2.775.408a12.236 12.236 0 0 1 2.097-.6 4.49 4.49 0 0 1-.938-1.277 11.88 11.88 0 0 1-1.239 1.766l.08.11Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgSquashStroke;
