import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFootballStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M8.914 10.945 12 8.758l3.047 2.187-1.172 3.555h-3.79l-1.17-3.555ZM11.961 2c5.547 0 10 4.57 10 10.04A9.953 9.953 0 0 1 12 22 9.97 9.97 0 0 1 2 12C2 6.492 6.453 2 11.96 2Zm7.031 14.14a8.092 8.092 0 0 0 1.055-3.32l-3.32-2.343 1.328-3.829a7.752 7.752 0 0 0-2.813-2.07L12 7 8.719 4.578a8.096 8.096 0 0 0-2.813 2.07l1.328 3.829-3.32 2.343a8.091 8.091 0 0 0 1.055 3.32l4.062-.078 1.211 3.907c.547.117 1.133.156 1.758.156.586 0 1.172-.04 1.719-.156l1.21-3.907 4.063.079Z"
		/>
	</svg>
);
export default SvgFootballStroke;
