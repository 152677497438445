import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCross = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 10 10"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M9.262 8.336c.252.28.252.701 0 .954-.28.28-.701.28-.954 0L5 5.953 1.664 9.29c-.28.28-.701.28-.954 0-.28-.253-.28-.673 0-.954L4.047 5 .71 1.664C.43 1.384.43.963.71.71a.614.614 0 0 1 .926 0L5 4.075 8.336.738a.614.614 0 0 1 .926 0c.28.253.28.673 0 .954L5.925 5l3.337 3.336Z"
		/>
	</svg>
);
export default SvgCross;
