export type Coordinates = {
	longitude: string;
	latitude: string;
};

export const areCoordinatesEqual = (c1: Coordinates, c2: Coordinates): boolean => {
	return c1.longitude === c2.longitude && c1.latitude === c2.latitude;
};

export const formatCoordinates = (coordinates: Coordinates): string => {
	const toDMS = (decimalValue: number, direction: 'latitude' | 'longitude') => {
		const absoluteValue = Math.abs(decimalValue);
		const degrees = Math.floor(absoluteValue);
		const minutesDecimal = (absoluteValue - degrees) * 60;
		const minutes = Math.floor(minutesDecimal);
		const seconds = (minutesDecimal - minutes) * 60;

		const calculatedDirection =
			direction === 'latitude'
				? decimalValue >= 0
					? 'N'
					: 'S'
				: decimalValue >= 0
				? 'E'
				: 'W';

		return `${degrees}°${minutes}'${seconds.toFixed(3)}"${calculatedDirection}`;
	};

	const latitudeDMS = toDMS(parseFloat(coordinates.latitude), 'latitude');
	const longitudeDMS = toDMS(parseFloat(coordinates.longitude), 'longitude');

	return `${latitudeDMS} ${longitudeDMS}`;
};
