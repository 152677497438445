import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGymStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M22.4 10.6c.584 0 1.1.516 1.1 1.1 0 .619-.516 1.1-1.1 1.1v2.475c0 1.066-.894 1.925-1.925 1.925H19.1v.275c0 1.066-.894 1.925-1.925 1.925h-1.1a1.922 1.922 0 0 1-1.925-1.925V12.8h-3.3v4.675c0 1.066-.894 1.925-1.925 1.925h-1.1A1.922 1.922 0 0 1 5.9 17.475V17.2H4.525A1.922 1.922 0 0 1 2.6 15.275L2.566 12.8c-.585 0-1.066-.481-1.066-1.1 0-.584.481-1.1 1.1-1.1V8.125c0-1.031.86-1.925 1.925-1.925h1.1c.069 0 .172.034.275.034v-.309C5.9 4.894 6.76 4 7.825 4h1.1c1.031 0 1.925.894 1.925 1.925V10.6h3.3V5.925c0-1.031.86-1.925 1.925-1.925h1.1c1.031 0 1.925.894 1.925 1.925v.31c.069 0 .172-.035.275-.035h1.1c1.031 0 1.925.894 1.925 1.925V10.6ZM5.625 15.55a.271.271 0 0 0 .275-.275v-7.15a.296.296 0 0 0-.275-.275h-1.1a.271.271 0 0 0-.275.275v7.15c0 .172.103.275.275.275h1.1ZM9.2 17.475V5.925a.296.296 0 0 0-.275-.275h-1.1a.271.271 0 0 0-.275.275v11.55c0 .172.103.275.275.275h1.1a.271.271 0 0 0 .275-.275Zm8.25 0V5.925a.296.296 0 0 0-.275-.275h-1.1a.271.271 0 0 0-.275.275v11.55c0 .172.103.275.275.275h1.1a.271.271 0 0 0 .275-.275Zm3.3-2.2v-7.15a.296.296 0 0 0-.275-.275h-1.1a.271.271 0 0 0-.275.275v7.15c0 .172.103.275.275.275h1.1a.271.271 0 0 0 .275-.275Z"
		/>
	</svg>
);
export default SvgGymStroke;
