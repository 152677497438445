import { useAuth0, User, withAuthenticationRequired } from '@auth0/auth0-react';
import { FC, PropsWithChildren, useEffect } from 'react';
import { RouterLoading } from '@/config/routes/RouterLoading';
import { isNil, Role, userMetaRolesField } from '@/shared';
import { useNavigate } from '@/config';

type ProtectedRouteProps = {
	requiredRoles?: Role[];
};

const userHasRoles = (user: User, roles: Role[]) => {
	if (isNil(user?.[userMetaRolesField])) {
		return false;
	}
	const userRoles = user[userMetaRolesField] as Role[];
	return roles.every((role) => userRoles.includes(role));
};

const ProtectedRoute: FC<PropsWithChildren<ProtectedRouteProps>> = ({
	children,
	requiredRoles = ['ROLE_CLIENT']
}) => {
	const { isAuthenticated, user } = useAuth0();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isAuthenticated || isNil(user) || !userHasRoles(user, requiredRoles)) {
			navigate('not-found');
		}
	}, [isAuthenticated, navigate, requiredRoles, user]);

	return <>{children}</>;
};

export default withAuthenticationRequired(ProtectedRoute, {
	onRedirecting: () => <RouterLoading />
});
