import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRunningFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M19.5 10.75c.664 0 1.25.586 1.25 1.25 0 .703-.586 1.25-1.25 1.25h-2.148c-.704 0-1.368-.39-1.68-1.016l-.781-1.64-1.211 3.086 2.383 1.406c.585.351.937.937.937 1.601 0 .157-.04.352-.078.508l-1.25 3.946c-.156.547-.664.859-1.172.859-.156 0-.273 0-.39-.04-.547-.155-.86-.663-.86-1.21 0-.117 0-.234.04-.352l1.093-3.437-3.32-1.953a2.443 2.443 0 0 1-1.25-2.149c0-.351.078-.664.195-.976l1.484-3.438-.586-.156c-.078 0-.156-.039-.234-.039-.235 0-.508.117-.742.273L8.367 9.734a1.38 1.38 0 0 1-.742.235c-.742 0-1.25-.586-1.25-1.25 0-.39.156-.742.469-.977l1.562-1.21a3.705 3.705 0 0 1 2.266-.782c.273 0 .586.04.898.117l2.774.82a3.779 3.779 0 0 1 2.383 1.993l1.054 2.07H19.5Zm-4.375-5a1.851 1.851 0 0 1-1.875-1.875c0-1.016.82-1.875 1.875-1.875C16.141 2 17 2.86 17 3.875c0 1.055-.86 1.875-1.875 1.875Zm-6.21 8.672c.312.664.82 1.25 1.483 1.68l.43.234-.351.781c-.274.703-.977 1.133-1.72 1.133H5.75c-.703 0-1.25-.547-1.25-1.25 0-.664.547-1.25 1.25-1.25h2.578l.586-1.328Z"
		/>
	</svg>
);
export default SvgRunningFilled;
