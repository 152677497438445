import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { Theme } from '@mui/material';
import { primary } from '@/ui/theme/components-overrides/colors';

type MuiTabOverrides = {
	defaultProps?: ComponentsProps['MuiTab'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiTab'];
	variants?: ComponentsVariants['MuiTab'];
};

export const muiTab: MuiTabOverrides = {
	defaultProps: {
		disableRipple: true
	},
	styleOverrides: {
		root: {
			borderRadius: '6px',
			backgroundColor: primary[50],
			padding: '10px 16px',
			gap: 1,
			display: 'flex',
			textTransform: 'none',
			minHeight: 0,
			minWidth: 0,

			'&.Mui-selected': {
				backgroundColor: primary.main,
				borderRadius: '6px',

				'& .MuiTypography-root': {
					color: primary.contrast
				}
			},

			'&.Mui-disabled': {
				backgroundColor: primary['100']
			}
		}
	}
};
