import * as React from 'react';
import type { SVGProps } from 'react';
const SvgClimbingFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 24 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="#000"
			d="M17.973 5.293a6.214 6.214 0 0 0-5.21-2.793c-3.428 0-6.217 2.759-6.217 6.15v.446h-.874a.651.651 0 0 0-.654.647v4.583c0 .358.293.648.654.648h.874v2.166c0 2.404 1.977 4.36 4.408 4.36a4.41 4.41 0 0 0 4.03-2.598l3.467-7.767a6.07 6.07 0 0 0-.48-5.84l.002-.002Zm-8.821 5.096v.97H6.329v-.97h2.823Zm-2.823 2.267h2.823v1.023H6.329v-1.023Zm2.597 4.49H6.554v-.97h2.372v.97Zm7.35-6.963L12.81 17.95a2.03 2.03 0 0 1-1.856 1.196 2.017 2.017 0 0 1-2.028-2.006v-2.166h.88c.362 0 .655-.29.655-.648V9.743a.651.651 0 0 0-.655-.647h-.88V8.65c0-2.096 1.718-3.796 3.838-3.796 2.774 0 4.631 2.82 3.512 5.331v-.002Z"
		/>
	</svg>
);
export default SvgClimbingFilled;
