import { FC } from 'react';
import { primary } from '@/ui/theme/components-overrides/colors';
import { Flex } from '@/ui/flex/Flex';
import Avatar from '@mui/material/Avatar/Avatar';
import Typography from '@mui/material/Typography';
import { MeClient } from '@/shared';

type ClientDetailsProps = {
	client: MeClient;
};

export const ClientDetails: FC<ClientDetailsProps> = ({ client }) => {
	return (
		<Flex gap={2} alignItems={'center'}>
			<Avatar
				alt={client.contactDetails.name}
				src={client.avatarUrl}
				sx={{ borderRadius: '4px', width: '72px', height: '72px' }}
			/>
			<Flex flexDirection={'column'} justifyContent={'center'} flex={'1 0 0'}>
				<Typography variant={'h4'}>
					{client.contactDetails.name} {client.contactDetails.surname}
				</Typography>
				<Typography variant={'p3'} color={primary['600']}>
					{client.contactDetails.email}
				</Typography>
			</Flex>
		</Flex>
	);
};
