import { createContext, FC, PropsWithChildren, useMemo } from 'react';
import { Loader, Maybe, TrainerBalance, useObservable } from '@/shared';
import { useSelfTrainerRepository } from '@/shared/repository/trainer/use-self-trainer-repository';

type TrainerBalanceContextProperties = {
	trainerBalance: Maybe<TrainerBalance>;
	loader: Loader;
};

export const trainerBalanceContext = createContext<TrainerBalanceContextProperties>({
	trainerBalance: {
		available: [],
		pending: []
	},
	loader: {
		loading: false
	}
});

export const TrainerBalanceContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const { getBalance } = useSelfTrainerRepository();
	const balance$ = useMemo(() => getBalance(), [getBalance]);
	const { value: balance, loader } = useObservable(balance$);

	const contextValue = useMemo<TrainerBalanceContextProperties>(
		() => ({
			trainerBalance: balance,
			loader
		}),
		[balance, loader]
	);

	return (
		<trainerBalanceContext.Provider value={contextValue}>
			{children}
		</trainerBalanceContext.Provider>
	);
};
