import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { accent, primary, secondary } from '@/ui/theme/components-overrides/colors';

type MuiSwitchOverrides = {
	defaultProps?: ComponentsProps['MuiSwitch'];
	styleOverrides?: ComponentsOverrides<Theme>['MuiSwitch'];
	variants?: ComponentsVariants['MuiSwitch'];
};

export const muiSwitch: MuiSwitchOverrides = {
	styleOverrides: {
		thumb: {
			border: `2px solid ${primary['200']}`
		},
		root: {
			'& .Mui-checked': {
				'& .MuiSwitch-thumb': {
					color: accent,
					border: `2px solid ${secondary.main}`
				}
			}
		}
	}
};
