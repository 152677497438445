import { FC, PropsWithChildren } from 'react';
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { primary, secondary } from '@/ui/theme/components-overrides/colors';

type IconButtonProps = MuiIconButtonProps & {
	variant?: 'primary' | 'secondary' | 'outlined' | 'tertiary-bg' | 'tertiary' | 'primary-bg';
	readonly?: boolean;
};

export const IconButton: FC<PropsWithChildren<IconButtonProps>> = ({
	children,
	variant = 'secondary',
	readonly = false,
	...iconButtonProps
}) => {
	const bgColor = () => {
		if (iconButtonProps.disabled) {
			return primary[400];
		}
		switch (variant) {
			case 'primary':
				return secondary.main;
			case 'secondary':
				return primary[50];
			case 'primary-bg':
				return primary.main;
			case 'tertiary-bg':
				return primary.contrast;
			case 'tertiary':
			default:
				return 'inherit';
		}
	};

	return (
		<MuiIconButton
			disableTouchRipple={readonly}
			disableFocusRipple={variant === 'primary'}
			disableRipple={variant === 'primary' || variant === 'outlined'}
			{...iconButtonProps}
			sx={{
				borderRadius: '100px',
				backgroundColor: bgColor(),
				border: variant === 'outlined' ? `1px solid ${primary.black}` : 'inherit',
				'&.MuiIconButton-root:hover': {
					backgroundColor: readonly ? bgColor() : undefined
				},
				cursor: readonly ? 'default' : 'pointer',
				...iconButtonProps.sx
			}}>
			{children}
		</MuiIconButton>
	);
};
